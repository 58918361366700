import { PriceConfig } from './priceConfig';
import { SteepSlope } from './steepSlope';

import { toFixed } from 'utils';

export class FixedPrice {
  constructor({ priceConfig, steepSlope }: { priceConfig: PriceConfig; steepSlope: SteepSlope }) {
    this.priceConfig = priceConfig;
    this.steepSlopeInstance = steepSlope;
  }

  private readonly priceConfig: PriceConfig;

  private readonly steepSlopeInstance: SteepSlope;

  calculateFixedPriceWithInstantQuoteCosts(): number {
    const { fixedPrice, includedStructures } = this.priceConfig;

    const steepSlopeByStructures = this.steepSlopeInstance.steepSlopeByStructures;

    const steepSlopeValue = includedStructures.reduce((acc, structure) => {
      const geoJsonId = structure.id;
      const steepSlopeSqFt = (geoJsonId && steepSlopeByStructures?.[geoJsonId].steepSlopeSqFt) || 0;
      const steepSlopeFixed =
        (geoJsonId && steepSlopeByStructures?.[geoJsonId].steepSlopeFixed) || 0;

      //we should use or steepSlopeSqFt or steepSlopeFixed, but in this formula one of two numbers is 0 (0 doesn't affect on result)
      //it depend on steepSlopeChargeType. both functions check this condition
      return acc + steepSlopeSqFt * structure.squareFeet + steepSlopeFixed;
    }, 0);

    return +toFixed(fixedPrice + steepSlopeValue, 2);
  }
}
