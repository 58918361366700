import { intl } from 'intl';

import { I18nEnum, ThunkResult } from 'types';
import { services } from '.';
import { actions as spinnerActions } from 'modules/spinner';
import { selectors as userSelectors } from 'modules/auth';
import { WebhooksType } from './types';
import { ModalTypes, actions as modalActions } from 'modules/modal';
import { SupportRequestedType } from 'modules/email/types';

export const getCompanyWebhooks =
  (): ThunkResult<Promise<WebhooksType | undefined>> => async (dispatch, getState) => {
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(getState());

      const { data } = await services.getCompanyWebhooksAPI(user.id);

      dispatch(spinnerActions.hide());

      return data as WebhooksType;
    } catch (err) {
      dispatch(spinnerActions.hide());
    }
  };

export const updateCompanyWebhooks =
  (webhooks: Partial<WebhooksType>): ThunkResult<Promise<WebhooksType | undefined>> =>
  async (dispatch, getState) => {
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(getState());

      const { data } = await services.updateCompanyWebhooksAPI(user.id, webhooks);

      dispatch(spinnerActions.hide());
      dispatch(
        modalActions.openModal(ModalTypes.successModal, {
          subtitle: I18nEnum.YourWebhookURLHaveBeenSaved,
          description: intl.formatMessage({
            id: I18nEnum.DataWillNowBeSent,
          }),
        }),
      );

      return data as WebhooksType;
    } catch (err) {
      dispatch(spinnerActions.hide());
      dispatch(
        modalActions.openModal(ModalTypes.error, {
          action: updateCompanyWebhooks,
          error: err.message,
          requestBody: webhooks,
          type: SupportRequestedType.updateWebhooks,
          title: I18nEnum.UnfortunatelyYourChangesHaveNotBeen,
          args: [webhooks],
        }),
      );
      return Promise.reject(err);
    }
  };
