import base64js from 'base64-js';

const importKey = (): Promise<CryptoKey> =>
  new Promise((resolve, reject) => {
    window.crypto.subtle
      .importKey(
        'raw',
        stringToUint8(window.__RUNTIME_CONFIG__.REACT_APP_QQ_RESPONSE_SECRET_KEY),
        {
          name: 'AES-CBC',
        },
        false,
        ['encrypt', 'decrypt'],
      )
      .then(
        key => {
          resolve(key);
        },
        err => {
          reject(err);
        },
      );
  });

const encrypt = async (data: string): Promise<string> => {
  const key = await importKey();

  return new Promise((resolve, reject) => {
    window.crypto.subtle
      .encrypt(
        {
          name: 'AES-CBC',
          iv: stringToUint8(window.__RUNTIME_CONFIG__.REACT_APP_QQ_RESPONSE_IV),
        },
        key,
        stringToArrayBuffer(data),
      )
      .then(
        encrypted => {
          resolve(base64js.fromByteArray(new Uint8Array(encrypted)));
        },
        err => {
          reject(err);
        },
      );
  });
};

const decrypt = async (data: string): Promise<string> => {
  const key = await importKey();

  return new Promise((resolve, reject) => {
    window.crypto.subtle
      .decrypt(
        {
          name: 'AES-CBC',
          iv: stringToUint8(window.__RUNTIME_CONFIG__.REACT_APP_QQ_RESPONSE_IV),
        },
        key,
        base64js.toByteArray(data),
      )
      .then(
        decrypted => {
          resolve(arrayBufferToString(decrypted));
        },
        err => {
          reject(err);
        },
      );
  });
};

const stringToUint8 = (initStr = '') => {
  let str = '';
  for (let i = 0; i < initStr.length; i += 2) {
    str += String.fromCharCode(parseInt(initStr.substr(i, 2), 16));
  }
  const bytes = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
};

const arrayBufferToString = (buf: ArrayBuffer) => {
  let jsonKey = '';
  new Uint16Array(buf).forEach((byte: number) => {
    jsonKey += String.fromCharCode(byte);
  });
  return jsonKey;
};

const stringToArrayBuffer = (str: string) => {
  const buf = new ArrayBuffer(str.length * 2);
  const bufView = new Uint16Array(buf);
  for (let i = 0; i < str.length; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

export { encrypt, decrypt };
