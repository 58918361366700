import { I18nEnum } from 'types';
import { intl } from 'intl';
import { services, constants, DeliveryTimeType } from '.';
import { selectors as userSelectors } from 'modules/auth';
import { actions as spinnerActions } from 'modules/spinner';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { queryClient, TEN_MINUTES } from 'modules/reactQuery';
import { ReportTypeToTitleMap } from 'modules/orders/constants';
import { generateMeasurementInitialValues } from './utils';

export const startOrderMeasurementsProcess =
  (
    statisticsId,
    _initialValues?: {
      reportType: string;
      structuresType: string;
      deliveryTime?: DeliveryTimeType;
      productCode?: string;
      instructions?: string;
    },
    orderId?: number,
  ) =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);

    dispatch(spinnerActions.show());

    const { data: availableReports } = await queryClient.fetchQuery({
      queryKey: [constants.MEASUREMENTS_AVAILABLE_SERVICES_KEY, statisticsId],
      queryFn: ({ queryKey }) =>
        services.measurementsGetAvailableServices(user.id, queryKey[1] as number),
      staleTime: TEN_MINUTES,
    });

    dispatch(spinnerActions.hide());

    if (_initialValues?.reportType && !availableReports.includes(_initialValues?.reportType)) {
      dispatch(
        modalActions.openModal(ModalTypes.infoModal, {
          title: I18nEnum.ReportIsNotAvailableForThisProject,
          titleValues: {
            reportName: intl.formatMessage({ id: ReportTypeToTitleMap[_initialValues.reportType] }),
          },
          description: I18nEnum.UnfortunatelyThisAddressIsNotAvailableForMeasurementProviders,
          descriptionValues: {
            isPlural: false,
          },
        }),
      );
      return;
    }

    if (!availableReports.length) {
      dispatch(
        modalActions.openModal(ModalTypes.infoModal, {
          title: I18nEnum.MeasurementReportsAreNotAvailableForThisRroject,
          description: I18nEnum.UnfortunatelyThisAddressIsNotAvailableForMeasurementProviders,
          descriptionValues: {
            isPlural: true,
          },
        }),
      );
      return;
    }

    const initialValues = _initialValues || {};

    if (!_initialValues) {
      const { data: lastOrder } = await queryClient.fetchQuery({
        queryKey: [constants.MEASUREMENTS_LAST_ORDER_FOR_STATISTICS_KEY, statisticsId],
        queryFn: ({ queryKey }) =>
          services.getMeasurementLastOrderByStatisticsId(user.id, queryKey[1] as number),
      });

      if (lastOrder) {
        Object.assign(initialValues, generateMeasurementInitialValues(lastOrder, availableReports));
      }
    }

    dispatch(
      modalActions.openModal(ModalTypes.orderMeasurements, {
        availableReports,
        statisticsId,
        initialValues,
        orderId,
      }),
    );
  };
