import { MouseEvent as ReactMouseEvent, KeyboardEvent as ReactKeyboardEvent } from 'react';
import styled from 'styled-components';
import { colors } from 'global-constants';

export interface Props {
  small?: boolean;
  className?: string;
  id?: string;
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  onKeyUp?: (e: ReactKeyboardEvent<HTMLDivElement>) => void;
  gray?: boolean;
  tabIndex?: number;
  title?: string;
  size?: {
    width: number;
    height: number;
  };
}

export const Icon = styled.span<Props>`
  display: flex;

  svg {
    ${props =>
      props.small
        ? `
          width: 14px;
          height: 14px;
          `
        : `
          width: 20px;
          height: 20px;
    `}

    ${props =>
      props.size &&
      `
       width: ${props.size.width}px;
       height: ${props.size.width}px;
    `}
  }
  &[data-whatinput='keyboard']:focus {
    border-radius: 3px;
    box-shadow: 0 0 0px 2px ${colors.darkCyan};
    border: 2px solid white;
    box-sizing: border-box;
  }
`;
