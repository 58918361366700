import cloneDeep from 'lodash/cloneDeep';

import {
  BrandingConfig,
  OverridesBrandingConfig,
  WidgetSettings,
  WidgetSettingsConfigState,
} from '../types';
import { MeasurementsReportType } from 'modules/dashboard/types';
import { DEFAULT_OC_BRANDING_CONFIG, toolCallout, widgetBranding } from '../constants';

export * from './toolScriptTransformSettings';

const brandingMap = {
  titles: {
    fontColor: 'titlesFontColor',
    font: 'titlesFont',
    mainTitleText: 'titlesMainTitleText',
  },
  subtitles: {
    fontColor: 'subtitlesFontColor',
    font: 'subtitlesFont',
  },
  mainText: {
    fontColor: 'mainTextFontColor',
    font: 'mainTextFont',
    exampleText: 'mainTextExampleText',
  },
  secondaryText: {
    fontColor: 'secondaryTextFontColor',
    font: 'secondaryTextFont',
  },
  icons: {
    fillColor: 'iconsFillColor',
  },
  roof: {
    fillColor: 'roofFillColor',
    strokeColor: 'roofStrokeColor',
  },
  tooltipAndMapControls: {
    fillColor: 'tooltipAndMapControlsFillColor',
    strokeColor: 'tooltipAndMapControlsStrokeColor',
    cornerRadius: 'tooltipAndMapControlsCornerRadius',
    fontColor: 'tooltipAndMapControlsFontColor',
  },
  illustration: {
    activeColor: 'illustrationSelectedColor',
    disabledColor: 'illustrationUnselectedColor',
  },
  background: {
    fillColor: 'backgroundFillColor',
  },
  mainButton: {
    fillColor: 'mainButtonFillColor',
    strokeColor: 'mainButtonStrokeColor',
    fontColor: 'mainButtonFontColor',
    cornerRadius: 'mainButtonCornerRadius',
    font: 'mainButtonFont',
    fontWeight: 'mainButtonFontWeight',
    fontSize: 'mainButtonFontSize',
    seeProductDetailsButtonText: 'seeProductDetailsButtonText',
    customFinancingButtonText: 'customFinancingButtonText',
  },
  secondaryButton: {
    fillColor: 'secondaryButtonFillColor',
    strokeColor: 'secondaryButtonStrokeColor',
    cornerRadius: 'secondaryButtonCornerRadius',
    fontColor: 'secondaryButtonFontColor',
    font: 'secondaryButtonFont',
    fontWeight: 'secondaryButtonFontWeight',
    fontSize: 'secondaryButtonFontSize',
  },
  checkbox: {
    fillColor: 'checkboxFillColor',
    strokeColor: 'checkboxStrokeColor',
    cornerRadius: 'checkboxCornerRadius',
  },
  roofQuoteProButton: {
    fillColor: 'rqpButtonFillColor',
    fontColor: 'rqpButtonFontColor',
    font: 'rqpButtonFont',
    fontSize: 'rqpButtonFontSize',
    fontWeight: 'rqpButtonFontWeight',
    strokeColor: 'rqpButtonStrokeColor',
    horisontalPosition: 'rqpButtonHorisontalPosition',
    text: 'rqpButtonText',
  },
  callout: {
    fillColor: 'calloutFillColor',
    fontColor: 'calloutFontColor',
    strokeColor: 'calloutStrokeColor',
    titleText: 'calloutTitleText',
    titleFont: 'calloutTitleFont',
    titleFontColor: 'calloutTitleFontColor',
    descriptionText: 'calloutDescriptionText',
    descriptionTextFont: 'calloutDescriptionTextFont',
    descriptionTextFontColor: 'calloutDescriptionTextFontColor',
    showOnTabletsAndDesktops: 'calloutShowOnTabletsAndDesktops',
    showOnPhones: 'calloutShowOnPhones',
  },
  otherContacts: {
    legalTextEnabled: 'otherContactsLegalTextEnabled',
    legalText: 'otherContactsLegalText',
    legalTextFontSize: 'otherContactsLegalTextFontSize',
    legalTextPosition: 'otherContactsLegalTextPosition',
    buttonText: 'otherContactsButtonText',
    contactOptIn: 'otherContactsContactOptIn',
    contactOptInText: 'otherContactsContactOptInText',
    smsOptIn: 'otherContactsSMSOptIn',
    smsOptInText: 'otherContactsSMSOptInText',
    subTitleText: 'otherContactsSubTitleText',
    titleText: 'otherContactsTitleText',
  },
  rqpLeads: {
    legalTextEnabled: 'rqpLeadsLegalTextEnabled',
    legalText: 'rqpLeadsLegalText',
    legalTextFontSize: 'rqpLeadsLegalTextFontSize',
    legalTextPosition: 'rqpLeadsLegalTextPosition',
    buttonText: 'rqpLeadsButtonText',
    contactOptIn: 'rqpLeadsContactOptIn',
    contactOptInText: 'rqpLeadsContactOptInText',
    smsOptIn: 'rqpLeadsSMSOptIn',
    smsOptInText: 'rqpLeadsSMSOptInText',
    subTitleText: 'rqpLeadsSubTitleText',
    titleText: 'rqpLeadsTitleText',
  },
  thankYou: {
    thankYouTitleText: 'thankYouTitleText',
    thankYouMedia: 'thankYouMedia',
    thankYouMediaType: 'thankYouMediaType',
    thankYouSubtitle: 'thankYouSubtitle',
    thankYouButtonText: 'thankYouButtonText',
    thankYouExternalUrl: 'thankYouExternalUrl',
    thankYouShowOtherProducts: 'thankYouShowOtherProducts',
  },
  measurementsOrder: {
    isMeasurementsOrderEnabled: 'isMeasurementsOrderEnabled',
    mainMeasurementsReportType: 'mainMeasurementsReportType',
    mainMeasurementsReportDeliveryType: 'mainMeasurementsReportDeliveryType',
    backupMeasurementsReportType: 'backupMeasurementsReportType',
    backupMeasurementsReportDeliveryType: 'backupMeasurementsReportDeliveryType',
  },
  mapSettings: {
    centerpointType: 'mapCenterpointType',
    placeholderMedia: 'mapPlaceholderMedia',
  },
  productDetails: {
    sendProductButtonText: 'sendProductButtonText',
    getFinalProposalButtonText: 'getFinalProposalButtonText',
  },
  overrides: {
    useOwensCorningWidgetBranding: 'useOwensCorningWidgetBranding',
    useOwensCorningToolCalloutBranding: 'useOwensCorningToolCalloutBranding',
  },
};

const nullableWidgetSettingsThatShouldBeRemoved = ['thankYouMediaProcessingStatus'];

const getMockOCNotEditableFields = (
  overrides: OverridesBrandingConfig,
  defaultSettingsConfig: WidgetSettingsConfigState,
): (keyof WidgetSettings)[] => {
  const notEditableFields: (keyof WidgetSettings)[] = [];
  if (overrides.useOwensCorningWidgetBranding) {
    notEditableFields.push(...defaultSettingsConfig.mockOCWidgetBrandingNotEditableFields);
  }
  if (overrides.useOwensCorningToolCalloutBranding) {
    notEditableFields.push(...defaultSettingsConfig.mockOCToolCalloutBrandingNotEditableFields);
  }

  return notEditableFields;
};

export const transformToBackendSettings = (
  config: Partial<BrandingConfig>,
  defaultSettingsConfig: WidgetSettingsConfigState,
): Partial<WidgetSettings> => {
  const backendBrandingConfig: Partial<WidgetSettings> = {};
  const notEditableFields =
    config.overrides?.useOwensCorningWidgetBranding ||
    config.overrides?.useOwensCorningToolCalloutBranding
      ? getMockOCNotEditableFields(config.overrides, defaultSettingsConfig)
      : defaultSettingsConfig.notEditableFields;

  for (const key in config) {
    for (const property in config[key]) {
      const configValue = config[key][property];
      const backendField = brandingMap[key]?.[property];

      if (
        (configValue === null && nullableWidgetSettingsThatShouldBeRemoved.includes(property)) ||
        notEditableFields.includes(backendField)
      ) {
        continue;
      }

      if (backendField) {
        backendBrandingConfig[backendField] = configValue;
      }
    }
  }

  if (backendBrandingConfig?.backupMeasurementsReportType === MeasurementsReportType.NoReport) {
    backendBrandingConfig.backupMeasurementsReportType = null;
  }

  return backendBrandingConfig;
};

const toggleMockOCWidgetBrandingSettings = (
  _branding: BrandingConfig,
  sourceConfig: BrandingConfig,
): BrandingConfig => {
  _branding.background.fillColor = sourceConfig.background.fillColor;

  _branding.checkbox.fillColor = sourceConfig.checkbox.fillColor;
  _branding.checkbox.strokeColor = sourceConfig.checkbox.strokeColor;
  _branding.checkbox.cornerRadius = sourceConfig.checkbox.cornerRadius;

  _branding.radioButton.fillColor = sourceConfig.radioButton.fillColor;
  _branding.radioButton.strokeColor = sourceConfig.radioButton.strokeColor;

  _branding.icons.fillColor = sourceConfig.icons.fillColor;

  _branding.illustration.activeColor = sourceConfig.illustration.activeColor;
  _branding.illustration.disabledColor = sourceConfig.illustration.disabledColor;

  _branding.mainButton.fillColor = sourceConfig.mainButton.fillColor;
  _branding.mainButton.fontColor = sourceConfig.mainButton.fontColor;
  _branding.mainButton.cornerRadius = sourceConfig.mainButton.cornerRadius;
  _branding.mainButton.strokeColor = sourceConfig.mainButton.strokeColor;
  _branding.mainButton.font = sourceConfig.mainButton.font;
  _branding.mainButton.fontWeight = sourceConfig.mainButton.fontWeight;
  _branding.mainButton.fontSize = sourceConfig.mainButton.fontSize;

  _branding.mainText.fontColor = sourceConfig.mainText.fontColor;
  _branding.mainText.font = sourceConfig.mainText.font;

  _branding.roof.fillColor = sourceConfig.roof.fillColor;
  _branding.roof.strokeColor = sourceConfig.roof.strokeColor;

  _branding.secondaryButton.fillColor = sourceConfig.secondaryButton.fillColor;
  _branding.secondaryButton.fontColor = sourceConfig.secondaryButton.fontColor;
  _branding.secondaryButton.font = sourceConfig.secondaryButton.font;
  _branding.secondaryButton.strokeColor = sourceConfig.secondaryButton.strokeColor;
  _branding.secondaryButton.cornerRadius = sourceConfig.secondaryButton.cornerRadius;
  _branding.secondaryButton.fontWeight = sourceConfig.secondaryButton.fontWeight;
  _branding.secondaryButton.fontSize = sourceConfig.secondaryButton.fontSize;

  _branding.secondaryText.fontColor = sourceConfig.secondaryText.fontColor;
  _branding.secondaryText.font = sourceConfig.secondaryText.font;

  _branding.titles.fontColor = sourceConfig.titles.fontColor;
  _branding.titles.font = sourceConfig.titles.font;

  _branding.subtitles.fontColor = sourceConfig.subtitles.fontColor;
  _branding.subtitles.font = sourceConfig.subtitles.font;

  _branding.tooltipAndMapControls.fillColor = sourceConfig.tooltipAndMapControls.fillColor;
  _branding.tooltipAndMapControls.fontColor = sourceConfig.tooltipAndMapControls.fontColor;
  _branding.tooltipAndMapControls.strokeColor = sourceConfig.tooltipAndMapControls.strokeColor;
  _branding.tooltipAndMapControls.cornerRadius = sourceConfig.tooltipAndMapControls.cornerRadius;

  _branding.thankYou.emailIconName = sourceConfig.thankYou.emailIconName;
  _branding.widgetUnavailable.imgName = sourceConfig.widgetUnavailable.imgName;

  _branding.howInstantLoanWorks.fiveMonthPurchaseIcon =
    sourceConfig.howInstantLoanWorks.fiveMonthPurchaseIcon;
  _branding.howInstantLoanWorks.getApprovedAndFundedIconName =
    sourceConfig.howInstantLoanWorks.getApprovedAndFundedIconName;
  _branding.howInstantLoanWorks.getPreQualifiedIconName =
    sourceConfig.howInstantLoanWorks.getPreQualifiedIconName;
  _branding.howInstantLoanWorks.submitApplicationIconName =
    sourceConfig.howInstantLoanWorks.submitApplicationIconName;

  _branding.howToReplyYourLoan.monthlyStatementsIconName =
    sourceConfig.howToReplyYourLoan.monthlyStatementsIconName;
  _branding.howToReplyYourLoan.noEarlyPaymentsIconName =
    sourceConfig.howToReplyYourLoan.noEarlyPaymentsIconName;
  _branding.howToReplyYourLoan.payOnlineIconName =
    sourceConfig.howToReplyYourLoan.payOnlineIconName;
  _branding.howToReplyYourLoan.secureLoginIconName =
    sourceConfig.howToReplyYourLoan.secureLoginIconName;
  _branding.howToReplyYourLoan.setupAutoPaymentsIconName =
    sourceConfig.howToReplyYourLoan.setupAutoPaymentsIconName;
  _branding.howToReplyYourLoan.simpleOneTimePaymentsIconName =
    sourceConfig.howToReplyYourLoan.simpleOneTimePaymentsIconName;

  _branding.invitationSent.invitationSentIconName =
    sourceConfig.invitationSent.invitationSentIconName;

  _branding.caption.font = sourceConfig.caption.font;
  _branding.caption.fontColor = sourceConfig.caption.fontColor;

  _branding.warningModal.imgName = sourceConfig.warningModal.imgName;

  return _branding;
};

const toggleMockOCToolCalloutBrandingSettings = (
  _branding: BrandingConfig,
  sourceConfig: BrandingConfig,
): BrandingConfig => {
  _branding.roofQuoteProButton.fillColor = sourceConfig.roofQuoteProButton.fillColor;
  _branding.roofQuoteProButton.fontColor = sourceConfig.roofQuoteProButton.fontColor;
  _branding.roofQuoteProButton.font = sourceConfig.roofQuoteProButton.font;
  _branding.roofQuoteProButton.fontSize = sourceConfig.roofQuoteProButton.fontSize;
  _branding.roofQuoteProButton.fontWeight = sourceConfig.roofQuoteProButton.fontWeight;
  _branding.roofQuoteProButton.strokeColor = sourceConfig.roofQuoteProButton.strokeColor;
  _branding.roofQuoteProButton.textClass = sourceConfig.roofQuoteProButton.textClass;
  _branding.roofQuoteProButton.customButtonClasses =
    sourceConfig.roofQuoteProButton.customButtonClasses;

  _branding.callout.fillColor = sourceConfig.callout.fillColor;
  _branding.callout.titleFont = sourceConfig.callout.titleFont;
  _branding.callout.titleFontColor = sourceConfig.callout.titleFontColor;
  _branding.callout.descriptionTextFont = sourceConfig.callout.descriptionTextFont;
  _branding.callout.descriptionTextFontColor = sourceConfig.callout.descriptionTextFontColor;
  _branding.callout.strokeColor = sourceConfig.callout.strokeColor;
  _branding.callout.customCalloutClasses = sourceConfig.callout.customCalloutClasses;
  _branding.callout.closingStrategy = sourceConfig.callout.closingStrategy;
  _branding.callout.img = sourceConfig.callout.img;
  _branding.callout.sideBar = sourceConfig.callout.sideBar;
  _branding.callout.parent = sourceConfig.callout.parent;
  _branding.callout.thirdPartyLegalText.enabled = sourceConfig.callout.thirdPartyLegalText.enabled;
  _branding.callout.thirdPartyLegalText.id = sourceConfig.callout.thirdPartyLegalText.id;

  return _branding;
};

export const toggleMockOCSettings = (
  frontendBranding: BrandingConfig,
  demoViewType: string,
  initialFrontendSettings?: BrandingConfig,
): BrandingConfig => {
  let _branding = cloneDeep(frontendBranding);
  const sourceConfig = initialFrontendSettings || DEFAULT_OC_BRANDING_CONFIG;

  switch (demoViewType) {
    case widgetBranding:
      _branding = {
        ..._branding,
        ...toggleMockOCWidgetBrandingSettings(_branding, sourceConfig),
      };
      break;
    case toolCallout:
      _branding = {
        ..._branding,
        ...toggleMockOCToolCalloutBrandingSettings(_branding, sourceConfig),
      };
      break;
    default:
      break;
  }

  return _branding;
};
