import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'modules/App';
import reportWebVitals from './reportWebVitals';

const workerUrl = new URL('/service-worker.js', location.origin);

const useMocks =
  !!window.__RUNTIME_CONFIG__.REACT_APP_MOCKS_ENABLED &&
  window.__RUNTIME_CONFIG__.REACT_APP_MOCKS_ENABLED === 'true';

const initServiceWorker = async (): Promise<unknown> => {
  try {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    if (useMocks) {
      workerUrl.searchParams.set('enableApiMocking', 'true');
    }

    await navigator.serviceWorker.register(workerUrl.href);
  } catch (e) {}
};

const enableMocking = async (): Promise<unknown> => {
  try {
    if (!useMocks || !('serviceWorker' in navigator)) {
      return;
    }

    const { worker } = await import('./mocks/browser');
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    await worker.start({
      onUnhandledRequest: 'bypass',
      serviceWorker: {
        url: workerUrl.href,
      },
    });
  } catch (e) {}
};

initServiceWorker().then(() =>
  enableMocking().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(<App />);

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  }),
);
