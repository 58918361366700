import pick from 'lodash/pick';
import forEach from 'lodash/forEach';
import defaults from 'lodash/defaults';

import {
  AdditionalCostsFormKey,
  CompanyPublicQuoteSettingsFormValues,
  ExtraCostFormKeys,
  InstantQuotesFormKey,
  MarketQuoteSettingsDto,
  MarketQuoteSettingsModel,
  PublicQuoteSettingsDto,
  PublicQuoteSettingsModel,
  QuoteSettingsDto,
  QuoteSettingsFormModel,
  QuoteSettingsModel,
  StaticQuoteSettingsFormValues,
} from './types';
import {
  DEFAULT_PUBLIC_QUOTE_SETTINGS_MODEL,
  DEFAULT_QUOTE_SETTINGS,
  DEFAULT_QUOTE_SETTINGS_MODEL,
  MAX_PRICE_RANGE_VALUE,
  MIN_PRICE_RANGE_VALUE,
} from './constants';
import { formatDecimal, toFixed } from 'utils';
import { PricePerUnitOfMeasurement } from 'modules/global/types';
import { StructureSlopeEnum } from 'modules/quickQuote/types';
import { areAdditionalCostsEnabledInForm } from './components/utils';

export const getWasteFactorValue = (value: number, defaultValue: string): string => {
  if (!value || value === 0) {
    return `${defaultValue}`;
  }

  const mappedValue = +toFixed((value - 1) * 100, 0);
  return `${mappedValue}`;
};

export const mapWasteFactor = (value: string): number => {
  return +toFixed(1 + +value / 100, 2);
};

export const mapFromFullWasteFactorToCoefficient = (value: string | number): number => {
  return +value / 100;
};

export const mapPriceRangeValue = (value: number): number => {
  if (value < MIN_PRICE_RANGE_VALUE) {
    return MIN_PRICE_RANGE_VALUE;
  }

  return value > MAX_PRICE_RANGE_VALUE ? MAX_PRICE_RANGE_VALUE : value;
};

export const mapPriceToDto = (value: string): number => {
  if (!value || +value === 0) {
    return 0;
  }

  return +toFixed(+value / 100, 4);
};

export const mapPriceFromDto = (value: number): string => {
  if (!value || value === 0) {
    return '0';
  }

  return formatDecimal(value * 100);
};

export const applyDefaultsToQuoteSettings = (input?: QuoteSettingsDto): QuoteSettingsModel => {
  if (!input) {
    return { ...DEFAULT_QUOTE_SETTINGS_MODEL };
  }

  const handledPropsWithoutOtherCosts = pick(input, Object.keys(DEFAULT_QUOTE_SETTINGS_MODEL));
  const notNullableHandledPropsWithoutOtherCosts: Partial<QuoteSettingsModel> = {};

  forEach(handledPropsWithoutOtherCosts, (value, key) => {
    notNullableHandledPropsWithoutOtherCosts[key] = value ?? DEFAULT_QUOTE_SETTINGS_MODEL[key];
  });

  const allField: QuoteSettingsModel = defaults(
    notNullableHandledPropsWithoutOtherCosts,
    DEFAULT_QUOTE_SETTINGS_MODEL,
  );

  return {
    ...allField,
    extraCosts: input.extraCosts,
    id: input.id,
  };
};

const getMappedPublicQuoteSettings = <T extends PublicQuoteSettingsModel>(
  input: T,
): CompanyPublicQuoteSettingsFormValues => ({
  [InstantQuotesFormKey.SteepSlopeChargeEnabled]: input.steepSlopeChargeEnabled,
  [InstantQuotesFormKey.SteepSlopeChargeValue]: mapPriceFromDto(input.steepSlopeChargeValue),
  [InstantQuotesFormKey.SteepSlopeChargePriceType]: input.steepSlopeChargeType,
  [InstantQuotesFormKey.WasteFactorEnabled]: input.wasteFactorEnabled,
  [InstantQuotesFormKey.WasteFactorBasedOnFacetCountEnabled]:
    input.wasteFactorBasedOnFacetCountEnabled,
  [InstantQuotesFormKey.WasteFactorValue]: getWasteFactorValue(
    +input.wasteFactorValue,
    DEFAULT_QUOTE_SETTINGS.wasteFactorValue,
  ),
  [InstantQuotesFormKey.PriceRangeEnabled]: input.priceRangeEnabled,
  [InstantQuotesFormKey.PriceRangeValue]: input.priceRangeValue,
  [InstantQuotesFormKey.PriceRangeType]: input.priceRangeType,
  [InstantQuotesFormKey.SuggestedSlopeEnabled]: input.suggestedSlopeEnabled,
  [InstantQuotesFormKey.SquareFootageEnabled]: input.squareFootageEnabled,
});

const mapExtraCostsFromDto = (
  input: QuoteSettingsModel['extraCosts'],
): QuoteSettingsFormModel['formValues'][AdditionalCostsFormKey.OtherExtraCosts] => {
  if (!input?.length) {
    return undefined;
  }
  return input.map(extraCost => ({
    [ExtraCostFormKeys.Id]: extraCost.id,
    [ExtraCostFormKeys.Question]: extraCost.question,
    [ExtraCostFormKeys.MultiChoice]: extraCost.multiple,
    [ExtraCostFormKeys.Answers]: extraCost.answers.map(answer => ({
      [ExtraCostFormKeys.Id]: answer.id,
      [ExtraCostFormKeys.OptionName]: answer.optionName,
      [ExtraCostFormKeys.Cost]: mapPriceFromDto(answer.cost),
      [ExtraCostFormKeys.CostType]: answer.costType ?? undefined,
      [ExtraCostFormKeys.CustomUnitName]: answer.customUnitName,
      [ExtraCostFormKeys.UnitOfMeasurement]: answer.unitOfMeasurement,
    })),
  }));
};

export const mapQuoteSettingsFromDto = (input: QuoteSettingsModel): QuoteSettingsFormModel => {
  const formValues = {
    ...getMappedPublicQuoteSettings(input),
    [AdditionalCostsFormKey.StoryCostsEnabled]: input.storyCostsEnabled,
    [AdditionalCostsFormKey.TwoStoriesCost]: mapPriceFromDto(input.twoStoriesCost),
    [AdditionalCostsFormKey.TwoStoriesCostType]: input.twoStoriesCostType,
    [AdditionalCostsFormKey.ManyStoriesCost]: mapPriceFromDto(input.manyStoriesCost),
    [AdditionalCostsFormKey.ManyStoriesCostType]: input.manyStoriesCostType,
    [AdditionalCostsFormKey.LayerCostsEnabled]: input.layerCostsEnabled,
    [AdditionalCostsFormKey.TwoLayersCost]: mapPriceFromDto(input.twoLayersCost),
    [AdditionalCostsFormKey.TwoLayersCostType]: input.twoLayersCostType,
    [AdditionalCostsFormKey.ManyLayersCost]: mapPriceFromDto(input.manyLayersCost),
    [AdditionalCostsFormKey.ManyLayersCostType]: input.manyLayersCostType,
    [AdditionalCostsFormKey.ChimneyCostsEnabled]: input.chimneyCostsEnabled,
    [AdditionalCostsFormKey.ChimneyFlashingCost]: mapPriceFromDto(input.chimneyFlashingCost),
    [AdditionalCostsFormKey.TwoChimneysFlashingCost]: mapPriceFromDto(
      input.twoChimneysFlashingCost,
    ),
    [AdditionalCostsFormKey.ManyChimneysFlashingCost]: mapPriceFromDto(
      input.manyChimneysFlashingCost,
    ),
    [AdditionalCostsFormKey.SkylightCostsEnabled]: input.skylightCostsEnabled,
    [AdditionalCostsFormKey.SkylightFlashingCost]: mapPriceFromDto(input.skylightFlashingCost),
    [AdditionalCostsFormKey.TwoSkylightsFlashingCost]: mapPriceFromDto(
      input.twoSkylightsFlashingCost,
    ),
    [AdditionalCostsFormKey.ManySkylightsFlashingCost]: mapPriceFromDto(
      input.manySkylightsFlashingCost,
    ),
    [AdditionalCostsFormKey.RoofConditionCostsEnabled]: input.roofConditionCostsEnabled,
    [AdditionalCostsFormKey.AlgaeStainsCost]: mapPriceFromDto(input.algaeStainsCost),
    [AdditionalCostsFormKey.AlgaeStainsCostType]: input.algaeStainsCostType,
    [AdditionalCostsFormKey.HailDamageCost]: mapPriceFromDto(input.hailDamageCost),
    [AdditionalCostsFormKey.HailDamageCostType]: input.hailDamageCostType,
    [AdditionalCostsFormKey.WindDamageCost]: mapPriceFromDto(input.windDamageCost),
    [AdditionalCostsFormKey.WindDamageCostType]: input.windDamageCostType,
    [AdditionalCostsFormKey.MissingShinglesCost]: mapPriceFromDto(input.missingShinglesCost),
    [AdditionalCostsFormKey.MissingShinglesCostType]: input.missingShinglesCostType,
    [AdditionalCostsFormKey.RoofLeaksCost]: mapPriceFromDto(input.roofLeaksCost),
    [AdditionalCostsFormKey.RoofLeaksCostType]: input.roofLeaksCostType,
    [AdditionalCostsFormKey.SaggingCost]: mapPriceFromDto(input.saggingCost),
    [AdditionalCostsFormKey.SaggingCostType]: input.saggingCostType,
    [AdditionalCostsFormKey.StructuralDamageCost]: mapPriceFromDto(input.structuralDamageCost),
    [AdditionalCostsFormKey.StructuralDamageCostType]: input.structuralDamageCostType,
    [AdditionalCostsFormKey.RoofTypeCostsEnabled]: input.roofTypeCostsEnabled,
    [AdditionalCostsFormKey.AsphaltShingleRoofCost]: mapPriceFromDto(input.asphaltShingleRoofCost),
    [AdditionalCostsFormKey.AsphaltShingleRoofCostType]: input.asphaltShingleRoofCostType,
    [AdditionalCostsFormKey.MetalRoofCost]: mapPriceFromDto(input.metalRoofCost),
    [AdditionalCostsFormKey.MetalRoofCostType]: input.metalRoofCostType,
    [AdditionalCostsFormKey.SyntheticRoofCost]: mapPriceFromDto(input.syntheticRoofCost),
    [AdditionalCostsFormKey.SyntheticRoofCostType]: input.syntheticRoofCostType,
    [AdditionalCostsFormKey.TileRoofCost]: mapPriceFromDto(input.tileRoofCost),
    [AdditionalCostsFormKey.TileRoofCostType]: input.tileRoofCostType,
    [AdditionalCostsFormKey.WoodRoofCost]: mapPriceFromDto(input.woodRoofCost),
    [AdditionalCostsFormKey.WoodRoofCostType]: input.woodRoofCostType,
    [AdditionalCostsFormKey.NewDeckingCostsEnabled]: input.newDeckingCostsEnabled,
    [AdditionalCostsFormKey.PartialNewDeckingCost]: mapPriceFromDto(input.partialNewDeckingCost),
    [AdditionalCostsFormKey.FullDeckingReplacementCost]: mapPriceFromDto(
      input.fullDeckingReplacementCost,
    ),
    [AdditionalCostsFormKey.DifficultAccessCostsEnabled]: input.difficultAccessCostsEnabled,
    [AdditionalCostsFormKey.DifficultAccessCost]: mapPriceFromDto(input.difficultAccessCost),
    [AdditionalCostsFormKey.DifficultAccessCostType]: input.difficultAccessCostType,
    [AdditionalCostsFormKey.OtherExtraCostsEnabled]: input.extraCostsEnabled,
    [AdditionalCostsFormKey.OtherExtraCosts]: mapExtraCostsFromDto(input.extraCosts),
  };

  return {
    id: input.id,
    formValues,
    additionalCostsEnabled: areAdditionalCostsEnabledInForm(formValues),
  };
};

export const mapPublicQuoteSettingsFromDto = (
  input?: PublicQuoteSettingsDto,
): PublicQuoteSettingsModel => {
  if (!input) {
    return DEFAULT_PUBLIC_QUOTE_SETTINGS_MODEL;
  }
  return {
    steepSlopeChargeEnabled: input.steepSlopeChargeEnabled,
    steepSlopeChargeValue:
      input.steepSlopeChargeValue ?? DEFAULT_QUOTE_SETTINGS_MODEL.steepSlopeChargeValue,
    steepSlopeChargeType:
      input.steepSlopeChargeType ?? DEFAULT_QUOTE_SETTINGS_MODEL.steepSlopeChargeType,
    wasteFactorEnabled: input.wasteFactorEnabled,
    wasteFactorBasedOnFacetCountEnabled: input.wasteFactorBasedOnFacetCountEnabled,
    wasteFactorValue: input.wasteFactorValue ?? DEFAULT_QUOTE_SETTINGS_MODEL.wasteFactorValue,
    priceRangeEnabled: input.priceRangeEnabled,
    priceRangeValue: input.priceRangeValue ?? DEFAULT_QUOTE_SETTINGS_MODEL.priceRangeValue,
    priceRangeType: input.priceRangeType ?? DEFAULT_QUOTE_SETTINGS_MODEL.priceRangeType,
    suggestedSlopeEnabled: input.suggestedSlopeEnabled,
    squareFootageEnabled: input.squareFootageEnabled,
  };
};

export const mapMarketQuoteSettingsFromDto = (
  input: MarketQuoteSettingsDto,
): MarketQuoteSettingsModel => {
  return {
    ...input,
    slope: input.slope || StructureSlopeEnum.medium,
  };
};

const mapSteepSlopeChargeFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  'steepSlopeChargeEnabled' | 'steepSlopeChargeValue' | 'steepSlopeChargeType'
> => {
  if (!model[InstantQuotesFormKey.SteepSlopeChargeEnabled]) {
    return {
      steepSlopeChargeEnabled: false,
      steepSlopeChargeValue: null,
      steepSlopeChargeType: null,
    };
  }

  return {
    steepSlopeChargeEnabled: model[InstantQuotesFormKey.SteepSlopeChargeEnabled],
    steepSlopeChargeValue: mapPriceToDto(model[InstantQuotesFormKey.SteepSlopeChargeValue]),
    steepSlopeChargeType: model[InstantQuotesFormKey.SteepSlopeChargePriceType],
  };
};

const mapWasteFactorFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  'wasteFactorEnabled' | 'wasteFactorValue' | 'wasteFactorBasedOnFacetCountEnabled'
> => {
  if (!model[InstantQuotesFormKey.WasteFactorEnabled]) {
    return {
      wasteFactorEnabled: false,
      wasteFactorValue: null,
      wasteFactorBasedOnFacetCountEnabled: false,
    };
  }

  return {
    wasteFactorEnabled: model[InstantQuotesFormKey.WasteFactorEnabled],
    wasteFactorValue: mapWasteFactor(model[InstantQuotesFormKey.WasteFactorValue]),
    wasteFactorBasedOnFacetCountEnabled:
      model[InstantQuotesFormKey.WasteFactorBasedOnFacetCountEnabled],
  };
};

const mapPriceRangeFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<QuoteSettingsDto, 'priceRangeEnabled' | 'priceRangeValue' | 'priceRangeType'> => {
  if (!model[InstantQuotesFormKey.PriceRangeEnabled]) {
    return {
      priceRangeEnabled: false,
      priceRangeValue: null,
      priceRangeType: null,
    };
  }

  return {
    priceRangeEnabled: model[InstantQuotesFormKey.PriceRangeEnabled],
    priceRangeValue: mapPriceRangeValue(model[InstantQuotesFormKey.PriceRangeValue]),
    priceRangeType: model[InstantQuotesFormKey.PriceRangeType],
  };
};

const mapExtraCostsFormValuesToDto = (
  model: QuoteSettingsFormModel['formValues'][AdditionalCostsFormKey.OtherExtraCosts],
): QuoteSettingsDto['extraCosts'] => {
  if (!model) {
    return undefined;
  }
  const extraCosts = model
    .filter(extraCost => extraCost[ExtraCostFormKeys.Question])
    .map(extraCost => ({
      id: extraCost[ExtraCostFormKeys.Id],
      question: extraCost[ExtraCostFormKeys.Question],
      multiple: extraCost[ExtraCostFormKeys.MultiChoice],
      answers: extraCost[ExtraCostFormKeys.Answers]
        .filter(
          answer =>
            answer[ExtraCostFormKeys.OptionName] &&
            (answer[ExtraCostFormKeys.UnitOfMeasurement] !== PricePerUnitOfMeasurement.Custom ||
              answer[ExtraCostFormKeys.CustomUnitName]),
        )
        .map((answer, index) => {
          const unitOfMeasurement = answer[ExtraCostFormKeys.UnitOfMeasurement];
          const isCustom = unitOfMeasurement === PricePerUnitOfMeasurement.Custom;
          return {
            id: answer[ExtraCostFormKeys.Id],
            optionName: answer[ExtraCostFormKeys.OptionName],
            cost: mapPriceToDto(answer[ExtraCostFormKeys.Cost]),
            costType: isCustom ? answer[ExtraCostFormKeys.CostType] ?? null : null,
            customUnitName: isCustom ? answer[ExtraCostFormKeys.CustomUnitName] || '' : '',
            unitOfMeasurement,
            order: index + 1,
          };
        }),
    }));
  return extraCosts
    .filter(({ answers }) => answers.length)
    .map((extraCost, index) => ({ ...extraCost, order: index + 1 }));
};

const mapStoryCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  | 'storyCostsEnabled'
  | 'twoStoriesCost'
  | 'twoStoriesCostType'
  | 'manyStoriesCost'
  | 'manyStoriesCostType'
> => {
  if (!model[AdditionalCostsFormKey.StoryCostsEnabled]) {
    return {
      storyCostsEnabled: false,
      twoStoriesCost: null,
      twoStoriesCostType: null,
      manyStoriesCost: null,
      manyStoriesCostType: null,
    };
  }
  return {
    storyCostsEnabled: model[AdditionalCostsFormKey.StoryCostsEnabled],
    twoStoriesCost: mapPriceToDto(model[AdditionalCostsFormKey.TwoStoriesCost]),
    twoStoriesCostType: model[AdditionalCostsFormKey.TwoStoriesCostType],
    manyStoriesCost: mapPriceToDto(model[AdditionalCostsFormKey.ManyStoriesCost]),
    manyStoriesCostType: model[AdditionalCostsFormKey.ManyStoriesCostType],
  };
};

const mapLayerCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  | 'layerCostsEnabled'
  | 'twoLayersCost'
  | 'twoLayersCostType'
  | 'manyLayersCost'
  | 'manyLayersCostType'
> => {
  if (!model[AdditionalCostsFormKey.LayerCostsEnabled]) {
    return {
      layerCostsEnabled: false,
      twoLayersCost: null,
      twoLayersCostType: null,
      manyLayersCost: null,
      manyLayersCostType: null,
    };
  }
  return {
    layerCostsEnabled: model[AdditionalCostsFormKey.LayerCostsEnabled],
    twoLayersCost: mapPriceToDto(model[AdditionalCostsFormKey.TwoLayersCost]),
    twoLayersCostType: model[AdditionalCostsFormKey.TwoLayersCostType],
    manyLayersCost: mapPriceToDto(model[AdditionalCostsFormKey.ManyLayersCost]),
    manyLayersCostType: model[AdditionalCostsFormKey.ManyLayersCostType],
  };
};

const mapChimneyCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  | 'chimneyCostsEnabled'
  | 'chimneyFlashingCost'
  | 'twoChimneysFlashingCost'
  | 'manyChimneysFlashingCost'
> => {
  if (!model[AdditionalCostsFormKey.ChimneyCostsEnabled]) {
    return {
      chimneyCostsEnabled: false,
      chimneyFlashingCost: null,
      twoChimneysFlashingCost: null,
      manyChimneysFlashingCost: null,
    };
  }
  return {
    chimneyCostsEnabled: model[AdditionalCostsFormKey.ChimneyCostsEnabled],
    chimneyFlashingCost: mapPriceToDto(model[AdditionalCostsFormKey.ChimneyFlashingCost]),
    twoChimneysFlashingCost: mapPriceToDto(model[AdditionalCostsFormKey.TwoChimneysFlashingCost]),
    manyChimneysFlashingCost: mapPriceToDto(model[AdditionalCostsFormKey.ManyChimneysFlashingCost]),
  };
};

const mapSkylightCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  | 'skylightCostsEnabled'
  | 'skylightFlashingCost'
  | 'twoSkylightsFlashingCost'
  | 'manySkylightsFlashingCost'
> => {
  if (!model[AdditionalCostsFormKey.SkylightCostsEnabled]) {
    return {
      skylightCostsEnabled: false,
      skylightFlashingCost: null,
      twoSkylightsFlashingCost: null,
      manySkylightsFlashingCost: null,
    };
  }
  return {
    skylightCostsEnabled: model[AdditionalCostsFormKey.SkylightCostsEnabled],
    skylightFlashingCost: mapPriceToDto(model[AdditionalCostsFormKey.SkylightFlashingCost]),
    twoSkylightsFlashingCost: mapPriceToDto(model[AdditionalCostsFormKey.TwoSkylightsFlashingCost]),
    manySkylightsFlashingCost: mapPriceToDto(
      model[AdditionalCostsFormKey.ManySkylightsFlashingCost],
    ),
  };
};

const mapRoofConditionCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  | 'roofConditionCostsEnabled'
  | 'algaeStainsCost'
  | 'algaeStainsCostType'
  | 'hailDamageCost'
  | 'hailDamageCostType'
  | 'windDamageCost'
  | 'windDamageCostType'
  | 'missingShinglesCost'
  | 'missingShinglesCostType'
  | 'roofLeaksCost'
  | 'roofLeaksCostType'
  | 'saggingCost'
  | 'saggingCostType'
  | 'structuralDamageCost'
  | 'structuralDamageCostType'
> => {
  if (!model[AdditionalCostsFormKey.RoofConditionCostsEnabled]) {
    return {
      roofConditionCostsEnabled: false,
      algaeStainsCost: null,
      algaeStainsCostType: null,
      hailDamageCost: null,
      hailDamageCostType: null,
      windDamageCost: null,
      windDamageCostType: null,
      missingShinglesCost: null,
      missingShinglesCostType: null,
      roofLeaksCost: null,
      roofLeaksCostType: null,
      saggingCost: null,
      saggingCostType: null,
      structuralDamageCost: null,
      structuralDamageCostType: null,
    };
  }
  return {
    roofConditionCostsEnabled: model[AdditionalCostsFormKey.RoofConditionCostsEnabled],
    algaeStainsCost: mapPriceToDto(model[AdditionalCostsFormKey.AlgaeStainsCost]),
    algaeStainsCostType: model[AdditionalCostsFormKey.AlgaeStainsCostType],
    hailDamageCost: mapPriceToDto(model[AdditionalCostsFormKey.HailDamageCost]),
    hailDamageCostType: model[AdditionalCostsFormKey.HailDamageCostType],
    windDamageCost: mapPriceToDto(model[AdditionalCostsFormKey.WindDamageCost]),
    windDamageCostType: model[AdditionalCostsFormKey.WindDamageCostType],
    missingShinglesCost: mapPriceToDto(model[AdditionalCostsFormKey.MissingShinglesCost]),
    missingShinglesCostType: model[AdditionalCostsFormKey.MissingShinglesCostType],
    roofLeaksCost: mapPriceToDto(model[AdditionalCostsFormKey.RoofLeaksCost]),
    roofLeaksCostType: model[AdditionalCostsFormKey.RoofLeaksCostType],
    saggingCost: mapPriceToDto(model[AdditionalCostsFormKey.SaggingCost]),
    saggingCostType: model[AdditionalCostsFormKey.SaggingCostType],
    structuralDamageCost: mapPriceToDto(model[AdditionalCostsFormKey.StructuralDamageCost]),
    structuralDamageCostType: model[AdditionalCostsFormKey.StructuralDamageCostType],
  };
};

const mapRoofTypeCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  | 'roofTypeCostsEnabled'
  | 'asphaltShingleRoofCost'
  | 'asphaltShingleRoofCostType'
  | 'metalRoofCost'
  | 'metalRoofCostType'
  | 'syntheticRoofCost'
  | 'syntheticRoofCostType'
  | 'tileRoofCost'
  | 'tileRoofCostType'
  | 'woodRoofCost'
  | 'woodRoofCostType'
> => {
  if (!model[AdditionalCostsFormKey.RoofTypeCostsEnabled]) {
    return {
      roofTypeCostsEnabled: false,
      asphaltShingleRoofCost: null,
      asphaltShingleRoofCostType: null,
      metalRoofCost: null,
      metalRoofCostType: null,
      syntheticRoofCost: null,
      syntheticRoofCostType: null,
      tileRoofCost: null,
      tileRoofCostType: null,
      woodRoofCost: null,
      woodRoofCostType: null,
    };
  }

  return {
    roofTypeCostsEnabled: model[AdditionalCostsFormKey.RoofTypeCostsEnabled],
    asphaltShingleRoofCost: mapPriceToDto(model[AdditionalCostsFormKey.AsphaltShingleRoofCost]),
    asphaltShingleRoofCostType: model[AdditionalCostsFormKey.AsphaltShingleRoofCostType],
    metalRoofCost: mapPriceToDto(model[AdditionalCostsFormKey.MetalRoofCost]),
    metalRoofCostType: model[AdditionalCostsFormKey.MetalRoofCostType],
    syntheticRoofCost: mapPriceToDto(model[AdditionalCostsFormKey.SyntheticRoofCost]),
    syntheticRoofCostType: model[AdditionalCostsFormKey.SyntheticRoofCostType],
    tileRoofCost: mapPriceToDto(model[AdditionalCostsFormKey.TileRoofCost]),
    tileRoofCostType: model[AdditionalCostsFormKey.TileRoofCostType],
    woodRoofCost: mapPriceToDto(model[AdditionalCostsFormKey.WoodRoofCost]),
    woodRoofCostType: model[AdditionalCostsFormKey.WoodRoofCostType],
  };
};

const mapNewDeckingCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  'newDeckingCostsEnabled' | 'partialNewDeckingCost' | 'fullDeckingReplacementCost'
> => {
  if (!model[AdditionalCostsFormKey.NewDeckingCostsEnabled]) {
    return {
      newDeckingCostsEnabled: false,
      partialNewDeckingCost: null,
      fullDeckingReplacementCost: null,
    };
  }
  return {
    newDeckingCostsEnabled: model[AdditionalCostsFormKey.NewDeckingCostsEnabled],
    partialNewDeckingCost: mapPriceToDto(model[AdditionalCostsFormKey.PartialNewDeckingCost]),
    fullDeckingReplacementCost: mapPriceToDto(
      model[AdditionalCostsFormKey.FullDeckingReplacementCost],
    ),
  };
};

const mapDifficultAccessCostsFormValuesToDto = (
  model: StaticQuoteSettingsFormValues,
): Pick<
  QuoteSettingsDto,
  'difficultAccessCostsEnabled' | 'difficultAccessCost' | 'difficultAccessCostType'
> => {
  if (!model[AdditionalCostsFormKey.DifficultAccessCostsEnabled]) {
    return {
      difficultAccessCostsEnabled: false,
      difficultAccessCost: null,
      difficultAccessCostType: null,
    };
  }
  return {
    difficultAccessCostsEnabled: model[AdditionalCostsFormKey.DifficultAccessCostsEnabled],
    difficultAccessCost: mapPriceToDto(model[AdditionalCostsFormKey.DifficultAccessCost]),
    difficultAccessCostType: model[AdditionalCostsFormKey.DifficultAccessCostType],
  };
};

export const mapQuoteSettingsFormValuesToDto = <T extends StaticQuoteSettingsFormValues>(
  model: T,
): QuoteSettingsDto => {
  const extraCosts =
    mapExtraCostsFormValuesToDto(model[AdditionalCostsFormKey.OtherExtraCosts]) || [];
  const extraCostsEnabled =
    model[AdditionalCostsFormKey.OtherExtraCostsEnabled] && !!extraCosts?.length;
  return {
    ...mapSteepSlopeChargeFormValuesToDto(model),
    ...mapWasteFactorFormValuesToDto(model),
    ...mapPriceRangeFormValuesToDto(model),
    ...mapStoryCostsFormValuesToDto(model),
    ...mapLayerCostsFormValuesToDto(model),
    ...mapChimneyCostsFormValuesToDto(model),
    ...mapSkylightCostsFormValuesToDto(model),
    ...mapRoofConditionCostsFormValuesToDto(model),
    ...mapRoofTypeCostsFormValuesToDto(model),
    ...mapNewDeckingCostsFormValuesToDto(model),
    ...mapDifficultAccessCostsFormValuesToDto(model),
    suggestedSlopeEnabled: model[InstantQuotesFormKey.SuggestedSlopeEnabled],
    squareFootageEnabled: model[InstantQuotesFormKey.SquareFootageEnabled],
    extraCostsEnabled: extraCostsEnabled,
    extraCosts,
  };
};
