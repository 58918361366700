import { KeyboardEvent as ReactKeyboardEvent } from 'react';
import { KEY_CODES } from 'global-constants';

export const onKeyboardAction =
  (fn: any, keyCode: number | number[]) => (event: ReactKeyboardEvent<any> | KeyboardEvent) => {
    return (
      (event.keyCode === keyCode || (Array.isArray(keyCode) && keyCode.includes(event.keyCode))) &&
      fn(event)
    );
  };

export const onEnterFireAction = (fn: any) => (event: ReactKeyboardEvent<any>) =>
  onKeyboardAction(fn, KEY_CODES.ENTER)(event);

export const onEscapeFireAction = (fn: any) => (event: ReactKeyboardEvent<any> | KeyboardEvent) =>
  onKeyboardAction(fn, KEY_CODES.ESCAPE)(event);
