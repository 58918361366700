const COMMON_WEBHOOK_FIELDS = {
  address: '2155 Old Highway 8, New Brighton, Minnesota 55112, USA',
  fullAddress: '2155 Old Highway 8, New Brighton, Minnesota 55112, USA',
  street: '2155 Old Highway 8',
  city: 'New Brighton',
  state: 'Minnesota',
  zip: '55112',
  country: 'USA',
  firstName: 'Joe',
  lastName: 'Roofer',
  email: 'help@roofle.com',
  phone: '(612) 255-8200',
  market: 'Minneapolis-St. Paul, MN',
  externalUrl: 'https://app.roofle.com/dashboard',
  timestamp: new Date().toISOString(),
};

export const MAXIMUM_WEBHOOKS = 5;

export const STATISTICS_EXAMPLE = {
  ...COMMON_WEBHOOK_FIELDS,
  numberOfStructures: 1,
  numberOfIncludedStructures: 1,
  totalSquareFeet: 1369,
  repName: 'Neil Armstrong',
  repEmail: 'sales@roofle.com',
  leadId: 123,
  products: [
    {
      name: 'Certainteed Landmark',
      id: 1,
      priceInfo: {
        priceType: 'BasicFinancing',
        total: 15069.25,
        pricePerSquare: 401,
        monthly: 334,
        apr: 26.1,
        months: 180,
      },
      priceRange: {
        totalMin: 13562,
        totalMax: 16576,
        monthlyMin: 300,
        monthlyMax: 367,
      },
      wasteFactorMainRoof: 1.12,
    },
    {
      name: 'Owens Corning Duration',
      id: 2,
      priceInfo: {
        priceType: 'BasicFinancing',
        total: 15219,
        pricePerSquare: 405,
      },
      priceRange: {
        totalMin: 13697,
        totalMax: 16740,
      },
      wasteFactorMainRoof: 1.21,
    },
    {
      name: 'GAF Timberline Armorshield II',
      id: 3,
      priceInfo: {
        priceType: 'BasicFinancing',
        total: 17436.4,
        pricePerSquare: 464,
        monthly: 387,
        apr: 26.1,
        months: 180,
      },
      priceRange: {},
      wasteFactorMainRoof: 1.21,
    },
  ],
  structures: [
    {
      name: 'Main Roof',
      slope: 'medium',
      isIncluded: true,
      squareFeet: 1369,
      initialSquareFeet: 1369,
      roofComplexity: 'Simple',
    },
  ],
  mainRoofTotalSquareFeet: 1369,
  totalInitialSquareFeet: 1369,
  sessionId: 'nH9YvHwoBldl2ZkpQSWrX',
};

export const PRODUCT_REQUEST_EXAMPLE = {
  ...COMMON_WEBHOOK_FIELDS,
  numberOfStructures: 2,
  numberOfIncludedStructures: 2,
  totalSquareFeet: 1909,
  totalInitialSquareFeet: 1909,
  mainRoofTotalSquareFeet: 1139,
  sessionId: 'W5cnnBDgDNGvbWnqn1Cz8',
  productName: 'Certainteed Landmark',
  structures: [
    {
      name: 'Main Roof',
      slope: 'medium',
      isIncluded: true,
      squareFeet: 1909,
      initialSquareFeet: 1139,
      roofComplexity: 'Simple',
    },
  ],
  priceInfo: {
    priceType: 'BasicFinancing',
    total: 15069.25,
    pricePerSquare: 401,
    monthly: 334,
    apr: 26.1,
    months: 180,
  },
  priceRange: {
    totalMin: 13562,
    totalMax: 16576,
    monthlyMin: 300,
    monthlyMax: 367,
  },
  wasteFactorMainRoof: 1.12,
};

export const ADDRESS_ONLY = {
  address: '2155 Old Highway 8, New Brighton, Minnesota 55112, USA',
  externalUrl: 'RoofQuote PRO™',
  sessionId: 'W5cnnBDgDNGvbWnqn1Cz8',
};

const developerStorageKey = 'developer/';

export const INTEGRATION_KEY = `${developerStorageKey}INTEGRATION`;
export const EAGLE_VIEW_CONNECTION_KEY = `${developerStorageKey}EAGLEVIEW_CONNECTION`;
export const BEACON_CONNECTION_KEY = `${developerStorageKey}BEACON_CONNECTION`;
export const JOBNIMBUS_CONNECTION_KEY = `${developerStorageKey}JOBNIMBUS_CONNECTION`;
export const LEAP_CONNECTION_KEY = `${developerStorageKey}LEAP_CONNECTION`;
export const ONE_CLICK_CONTRACTOR_CONNECTION_KEY = `${developerStorageKey}ONE_CLICK_CONTRACTOR_CONNECTION`;

export const beaconHomeLink = 'https://www.becn.com';

export const jobNimbusFormFieldKeys = {
  apiKey: 'apiKey',
  recordTypeId: 'recordTypeId',
  statusId: 'statusId',
  sourceId: 'sourceId',
  jobRecordTypeId: 'jobRecordTypeId',
  jobStatusId: 'jobStatusId',
} as const;

export const leapFormFieldKeys = {
  apiKey: 'apiKey',
  workflowStageId: 'workflowStageId',
} as const;

export const oneClickContractorFormFieldKeys = {
  username: 'username',
  password: 'password',
  jobTypeId: 'jobTypeId',
} as const;

export const DEVELOPER_BLOCKER_KEY = 'developerBlocker';
