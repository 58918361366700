import { useMemo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { I18nEnum } from 'types';
import * as userSelectors from 'modules/auth/selectors';
import { useGetProfile } from 'modules/profile/queries';
import { ArrowIconImg } from 'components/Icons/ArrowIcon';
import GhostButton from 'components/Button/GhostButton';
import { ActionType } from 'components/PopupMenu';
import {
  MenuItemContainer,
  MenuItemDescription,
  MenuItemImg,
  NavigationPopupContainer,
  getActiveNavigationPopupIconCss,
} from './common-components';
import { useProfileMenu } from './hooks/useProfileMenu';
import { NavigationPopup } from './NavigationPopup';

interface Props {
  signOutCallback: () => void;
}

const SignOut: FC<Props> = ({ signOutCallback }) => (
  <SignOutButton
    onClick={signOutCallback}
    title={
      <>
        <LogoutIcon src="/images/svg/logout.svg" />
        <FormattedMessage id={I18nEnum.SignOut} />
      </>
    }
  />
);

const titleToDescriptionMap: { [key in I18nEnum]?: I18nEnum } = {
  [I18nEnum.MyProfile]: I18nEnum.ManageYourPersonalProfile,
  [I18nEnum.CompanyProfile]: I18nEnum.ManageYourCompanyAccount,
  [I18nEnum.Billing]: I18nEnum.ManageSubscriptionPaymentInfo,
};

export const ProfileMenu: FC<Props> = ({ signOutCallback }: Props) => {
  const navigate = useNavigate();
  const user = useSelector(userSelectors.selectUser);
  const { data: profile } = useGetProfile();
  const menu = useProfileMenu();

  const actions: ActionType[] = useMemo(
    () =>
      menu.map((item, idx) => ({
        img: <MenuItemImg src={item.iconSrc} />,
        label: item.title,
        onClick: () => navigate(item.link),
        isItemActive: item.isActive,
        Wrapper: props => {
          const Container = idx === 0 ? NarrowMenuItemContainer : MenuItemContainer;
          return (
            <Container
              {...props}
              description={<FormattedMessage id={titleToDescriptionMap[item.title]} />}
            />
          );
        },
      })),
    [navigate, menu],
  );

  const isSomeItemActive = useMemo(() => menu.some(item => item.isActive), [menu]);

  if (!user.id) {
    return null;
  }

  const photo = profile?.adminPhoto || profile?.photo;

  return (
    <NavigationPopupContainer>
      <NavigationPopup
        actions={actions}
        icon={
          <UserPhotoWrapper isActive={isSomeItemActive}>
            {photo ? (
              <ProfileImage src={photo} id="toggle-user-tab" />
            ) : (
              <ProfileImage
                src="/images/placeholderImages/user-photo.svg"
                height="37"
                width={`${PROFILE_IMAGE_WIDTH}`}
              />
            )}
            <ArrowIcon />
          </UserPhotoWrapper>
        }
        extraContent={<SignOut signOutCallback={signOutCallback} />}
      />
    </NavigationPopupContainer>
  );
};

const PROFILE_IMAGE_WIDTH = 36;

const ArrowIcon = styled(ArrowIconImg)`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

const ProfileImage = styled.img`
  padding: 2px;
  width: ${PROFILE_IMAGE_WIDTH}px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
`;

const UserPhotoWrapper = styled.div<{ isActive: boolean; isOpen?: boolean }>`
  display: inline-flex;
  cursor: pointer;

  &:hover {
    ${ArrowIcon} {
      opacity: 0.8;
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      ${getActiveNavigationPopupIconCss(theme, PROFILE_IMAGE_WIDTH)}

      ${ProfileImage} {
        border: 2px solid ${theme.colors.blue};
      }
    `}

  ${({ isOpen }) => `
    ${ArrowIcon} {
      transform: rotate(${isOpen ? '180deg' : '0deg'});
    }
  `}

  @media ${props => props.theme.mediaQueries.medium} {
    display: none;
  }
`;

const SignOutButton = styled(GhostButton)`
  position: absolute;
  top: 25px;
  right: 25px;
`;

const LogoutIcon = styled.img`
  width: 20px;
  height: 20px;
  filter: ${({ theme }) => theme.colorFilters.blue};
`;

const NarrowMenuItemContainer = styled(MenuItemContainer)`
  ${MenuItemDescription} {
    max-width: calc(100% - 180px);
  }
`;
