import cloneDeep from 'lodash/cloneDeep';

import { I18nEnum } from 'types';
import { colors, colorFilters } from 'global-constants';
import { ProductConfigRuleId, ProductConfigValidatorId, ProductSettingsConfig } from './types';
import { SubscriptionCountry } from 'modules/subscription/types';

const name = 'product/';
const widgetProductName = 'widgetProduct/';

export const SET_SELECTED_PRODUCTS = `${name}SET_SELECTED_PRODUCTS`;
export const SET_INITIAL_SELECTED_PRODUCTS = `${name}SET_INITIAL_SELECTED_PRODUCTS`;
export const SET_LABELS = `${name}SET_LABELS`;
export const SET_UPSERT_CARD_VIEW = `${name}SET_UPSERT_CARD_VIEW`;
export const SET_UPSERTED_PRODUCT = `${name}SET_UPSERTED_PRODUCT`;
export const SET_NEW_FINANCING_TYPE = `${name}SET_NEW_FINANCING_TYPE`;

export const MARKET_PRODUCTS_KEY = `${name}MARKET_PRODUCTS`;
export const CATALOG_PRODUCTS_KEY = `${name}CATALOG_PRODUCTS`;
export const CATALOG_PRODUCTS_COUNT_KEY = `${name}CATALOG_PRODUCTS_COUNT`;
export const CUSTOM_PRODUCTS_KEY = `${name}CUSTOM_PRODUCTS`;
export const CUSTOM_PRODUCTS_COUNT_KEY = `${name}CUSTOM_PRODUCTS_COUNT`;

export const SET_SELECTED_COLORED_LINE = `${widgetProductName}SET_SELECTED_COLORED_LINE`;
export const SET_IS_DISABLED_SUBMIT_BUTTON = `${widgetProductName}SET_IS_DISABLED_SUBMIT_BUTTON`;
export const SET_IS_LOADING_SUBMIT_BUTTON = `${widgetProductName}SET_IS_LOADING_SUBMIT_BUTTON`;
export const CLEAR_WIDGET_PRODUCT = `${widgetProductName}CLEAR`;

export const initialPagination = {
  show: 10,
  page: 1,
};

export const initialQuery = { filter: {}, pagination: initialPagination, search: '' };

export const SHOULD_SHOW_SMTP_SERVER_CONNECTION_WARNING_MODAL_STORAGE_KEY =
  'shouldShowSmtpServerConnectionWarningModal';

export const customProductsQueries = [CUSTOM_PRODUCTS_KEY, CUSTOM_PRODUCTS_COUNT_KEY];

export const productsQueries = [
  CATALOG_PRODUCTS_KEY,
  CATALOG_PRODUCTS_COUNT_KEY,
  ...customProductsQueries,
];

export const PRODUCT_SETTINGS_BLOCKER_KEY = 'productSettingsBlocker';

export const OWENS_CORNING_MANUFACTURER_CODE = 'oco';

export const DEFAULT_PRODUCT_SETTINGS_CONFIG: ProductSettingsConfig = {
  selectedProductsValidatorIds: [],
  infoMessage: {
    enabled: false,
  },
  selectedProductCard: {
    manufacturerLogo: {
      default: {
        enabled: false,
      },
    },
    displayOrder: {
      default: {
        strokeColor: colors.blue,
        filterColor: colorFilters.blue,
      },
    },
    styles: {
      default: {
        inactiveState: {
          strokeColor: 'rgba(0, 0, 0, 0.2)',
          dragHandlerPrimaryColor: '#f3f1ef',
          dragHandlerFilterColor: colorFilters.grey,
        },
        activeState: {
          strokeColor: colors.darkOrange,
          dragHandlerPrimaryColor: colors.orange,
          dragHandlerFilterColor: colorFilters.white,
        },
      },
    },
  },
  countryAvailableIn: SubscriptionCountry.USA,
  showFinancingTypeInfoTooltip: true,
};

export const RQP_PRODUCT_SETTINGS_CONFIG: ProductSettingsConfig = cloneDeep(
  DEFAULT_PRODUCT_SETTINGS_CONFIG,
);

export const OC_PRODUCT_SETTINGS_CONFIG: ProductSettingsConfig = {
  selectedProductsValidatorIds: [ProductConfigValidatorId.OwensCorningProductMustBeFirst],
  infoMessage: {
    enabled: true,
    translationKey: I18nEnum.YourFirstProductShouldBeOwensCorning,
  },
  selectedProductCard: {
    manufacturerLogo: {
      default: {
        enabled: false,
      },
      rules: [{ id: ProductConfigRuleId.OwensCorningProduct, config: { enabled: true } }],
    },
    displayOrder: {
      default: {
        ...DEFAULT_PRODUCT_SETTINGS_CONFIG.selectedProductCard.displayOrder.default,
      },
      rules: [
        {
          id: ProductConfigRuleId.OwensCorningProduct,
          config: {
            strokeColor: colors.vividCerise,
            filterColor: colorFilters.vividCerise,
          },
        },
      ],
    },
    styles: {
      default: { ...DEFAULT_PRODUCT_SETTINGS_CONFIG.selectedProductCard.styles.default },
      rules: [
        {
          id: ProductConfigRuleId.OwensCorningProduct,
          config: {
            inactiveState: {
              strokeColor: colors.vividCerise,
              dragHandlerPrimaryColor: `${colors.vividCerise}1a`,
              dragHandlerFilterColor: colorFilters.vividCerise,
            },
            activeState: {
              strokeColor: colors.vividCerise,
              dragHandlerPrimaryColor: colors.vividCerise,
              dragHandlerFilterColor: colorFilters.white,
            },
          },
        },
      ],
    },
  },
  countryAvailableIn: SubscriptionCountry.USA,
  showFinancingTypeInfoTooltip: true,
};

export const TABLET_LAYOUT_WIDTH = '885px';
