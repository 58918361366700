import { SyntheticEvent } from 'react';
import parseISO from 'date-fns/parseISO';
import * as parser from 'parse-address';
import { nanoid } from 'nanoid';
import isBoolean from 'lodash/isBoolean';

import store from 'store';
import { colors, paymentCardsImages, unknownCardImg } from 'global-constants';
import {
  STORAGE_ANALYTICS,
  STORAGE_SESSION_ID_ALIAS,
  STORAGE_SESSION_ID_EXPIRATION_DATE_ALIAS,
  STORAGE_SESSION_NO_EXPIRATION,
} from 'modules/global/constants';
import idb from 'services/idb';
import { ExportType, ParsedAddress } from 'types';
import { GEODATA_SESSION_STORAGE_LABEL } from 'modules/widget/constants';
import { setCenterpoint } from 'modules/quickQuote/actions';
import { CommunicationOptions, GAFQuickMeasure, ReportType } from 'modules/dashboard';

export * from './toolScript';

export const validateName = (name?: string) =>
  /^\s*(([a-zA-Z][a-zA-Z.'-\s]{0,38}[a-zA-Z])|([a-zA-Z]))\s*$/.test(name || '');

export const validateJobTitle = (name?: string) =>
  /^\s*(([a-zA-Z0-9][a-zA-Z0-9.'-\s]{0,38}[a-zA-Z0-9])|([a-zA-Z0-9]))\s*$/.test(name || '');

export const validatePhone = (phone: string) =>
  (/^\([1-9][0-9]{2}\)\s[0-9]{3}\-[0-9]{4}$/.test(phone) || /^[1-9][0-9]{9}$/.test(phone)) &&
  phone.replace(/[^0-9]+/g, '').length === 10;

export const validateEmail = (email: string) =>
  /^\s*([a-zA-Z0-9][a-zA-Z0-9-_.+]*)@(([a-zA-Z0-9-]*)\.){1,}([a-zA-Z]{2,})\s*$/.test(email);

export const validate5DigitZipcode = (zipcode: string) => /^\d{5}$/.test(zipcode);

export const validateStructureName = (structureName: string) =>
  /^[a-zA-Z0-9\s.',-]{1,30}$/g.test(structureName);

export const validatePassword = (password: string) => ({
  valid:
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~])^[a-zA-Z\d !"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~]{8,30}$/.test(
      password,
    ),
  hasBlankSpaces: validateBlankSpaces(password),
});

export const validateBlankSpaces = (str: string) => /(^[\s].*$)|(^.*[\s]$)/.test(str);

export const validateWildcard = (wildcard: string) =>
  /^(?!-)(?!.*--)[a-z0-9-]*[^-|\W]$/.test(wildcard);

export const validateTextField = (value: string) =>
  /([A-Za-z0-9]|[!@#%^&*()_+\-=[\]{}|;':",./<>?~`].*)/.test(value || '');

export const validateURL = (url: string) =>
  /^\s*((http(s?)?):\/\/)?([wW]{3}\.)?((?!-)[a-zA-Z0-9-]{1,62}[a-zA-Z0-9-]\.)+[A-Za-z]{2,63}\b(?:[?\/][-a-zA-Z0-9()@:%_\+.~#&\/?=]*)?\s*$/.test(
    url,
  );

export const validateNoBlankSpaceAtTheBeginning = (value: string) => /^\S[\s\S]*$/.test(value);

export const toFixed = (number: number, precision: number): string => {
  return (+(Math.round(+(number + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
};

export const formatNumber = (number: string | number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatDecimal = (number: string | number) =>
  toFixed(parseFloat(number.toString()), Number.isInteger(+number) ? 0 : 2);

export const formatNumberWithDecimal = (number: string | number) =>
  formatNumber(toFixed(parseFloat(number.toString()), Number.isInteger(+number) ? 0 : 2));

export const formatCurrency = (amount: string | number, fixedTo = 0) =>
  `$${formatNumber(toFixed(parseFloat(amount.toString()), fixedTo))}`;

export const formatPrice = (price: string | number) =>
  formatCurrency(price, Number.isInteger(+price) ? 0 : 2);

export const formatMonthlyPrice = (price: string | number) => formatCurrency(price, 0);

export const timeFormat = (time: number) => {
  const hours = ~~(time / 3600);
  const minutes = ~~((time % 3600) / 60);
  const seconds = ~~time % 60;

  let ret = '';

  if (hours > 0) {
    ret += '' + hours + ':' + (minutes < 10 ? '0' : '');
  }

  ret += '' + minutes + ':' + (seconds < 10 ? '0' : '');
  ret += '' + seconds;
  return ret;
};

export const isMobile = () =>
  typeof window !== 'undefined' &&
  typeof window.navigator !== 'undefined' &&
  (window.navigator.userAgent.match(/Android/i) ||
    window.navigator.userAgent.match(/webOS/i) ||
    window.navigator.userAgent.match(/iPhone/i) ||
    window.navigator.userAgent.match(/iPod/i) ||
    window.navigator.userAgent.match(/iPad/i));

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isImageExist = (url: string, callback: (exist: boolean) => void) => {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    callback(true);
  };
  img.onerror = () => {
    callback(false);
  };

  return img;
};

export const isImageExistPromise = async (path: string): Promise<boolean> => {
  return new Promise(resolve => {
    isImageExist(path, exist => {
      resolve(exist);
    });
  });
};

export const getImageMeta = async (
  url: string,
): Promise<
  | {
      width: number;
      height: number;
      naturalWidth: number;
      naturalHeight: number;
      ratio: number;
    }
  | undefined
> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
        ratio: img.height / img.width,
      });
    };
    img.onerror = () => {
      reject();
    };
  });
};

export const formatPhoneWithoutCountryCode = (phone: string) =>
  phone.replace(/[^0-9]+1/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const highlightItems = (
  items: {
    title: string;
    href: string;
    children: {
      title: string;
      href: string;
      description: string;
    }[];
  }[],
  searchValue: string,
) =>
  items.map(collapse => {
    const mask = new RegExp(
      '(' +
        searchValue.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') +
        ')(?![^<]*>|[<>]*</)',
      'igm',
    );
    const activeKeys: number[] = [];

    collapse.children.forEach((panel, key) => {
      if (
        ((panel.title && panel.title.search(mask) !== -1) ||
          (panel.description && panel.description.search(mask) !== -1)) &&
        searchValue
      ) {
        activeKeys.push(key);
      }
    });

    const children = collapse.children
      .filter((_, key) => !searchValue || activeKeys.some(k => k === key))
      .map(panel => {
        return {
          ...panel,
          title:
            panel.title &&
            panel.title.replace(mask, (originalString: string) =>
              !!originalString
                ? `<span style='background-color: ${colors.orange}; color: ${colors.white};'>${originalString}</span>`
                : originalString,
            ),
          description:
            panel.description &&
            panel.description.replace(mask, (originalString: string) =>
              !!originalString
                ? `<span style='background-color: ${colors.orange}; color: ${colors.white};'>${originalString}</span>`
                : originalString,
            ),
        };
      });

    return {
      ...collapse,
      children,
      activeKeys,
    };
  });

export const clearQuery = (str: string) =>
  str
    .replace(/\&{2,}/, '&')
    .replace(/\&$/, '')
    .replace(/^\&/, '');

export const convertBytesToMB = (bytes: number) => bytes / 1024 ** 2;

export const getInitialsFromName = (name: string) => {
  const letters = name.match(/\b\w/g) || [];
  const initials = ((letters.shift() || '') + (letters.pop() || '')).toUpperCase();
  return initials;
};

export const getCookies = (cookie: string) => {
  const cookies: { [key: string]: string } = {};
  const parsedCookies = (cookie || '').split(';');

  parsedCookies.forEach((_cookie: string) => {
    const parts = _cookie.match(/(.*?)=(.*)$/);

    if (parts) {
      cookies[parts[1].trim()] = (parts[2] || '').trim();
    }
  });

  return cookies;
};

export const setCookie = (name: string, payload: string, expiration: string, domain = '') => {
  document.cookie = `${name}=${payload}; Expires=${expiration}; path=/;${
    !!domain && `Domain=${domain}`
  }`;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Max-Age=0; path=/;`;
};

export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const replaceCountryName = (address: string) => address.replace(/United States/gi, 'USA');

export const safeParseISO = (str: string) => {
  try {
    return parseISO(str);
  } catch (e) {
    return null;
  }
};

export const calculateDistanceBetweenParentAndChild = (
  parentEl: HTMLElement,
  childEl: HTMLElement,
): number => {
  const child = childEl.offsetTop;
  const parent = parentEl.offsetTop;

  return child - parent;
};

export const scrollToElementById = (event: SyntheticEvent, id: string) => {
  event.stopPropagation();

  const layout = document.getElementsByClassName('ant-layout')[0];

  if (layout) {
    const anchorElement = document.getElementById(id);
    const paddingTopPX = window.getComputedStyle(layout).paddingTop;
    const paddingTop = parseInt(paddingTopPX, 10);

    if (!anchorElement) {
      return;
    }

    const bodyRect = document.body.getBoundingClientRect(),
      anchorRect = anchorElement.getBoundingClientRect(),
      offset = anchorRect.top - bodyRect.top;

    window.scroll({
      behavior: 'smooth',
      top: +offset - paddingTop,
    });
  }
};

export const toDataURL: (url: string) => Promise<string> = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = () => resolve(reader.result as string);

          reader.onerror = reject;

          reader.readAsDataURL(blob);
        }),
    );

export const RGBAToHex = (r: number, g: number, b: number, a = 1) => {
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ];

  outParts.forEach((part, i) => {
    if (part.length === 1) {
      outParts[i] = '0' + part;
    }
  });

  return '#' + outParts.join('');
};

export const hexPattern = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;

export const hexToRGBA = (hex: string) => {
  const result = hexPattern.exec(hex) as RegExpExecArray;

  if (!result) {
    return {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    };
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: Math.round((parseInt(result[4] || 'ff', 16) / 255) * 100) / 100,
  };
};

export const transformFormFilterToFilterQuery = (formFilter: {
  [k: string]: string | string[];
}) => {
  const queryFilter: { [k: string]: { [k: string]: boolean } } = {};

  Object.keys(formFilter).forEach(key => {
    if (formFilter[key]) {
      queryFilter[key] = {};
      if (Array.isArray(formFilter[key])) {
        (formFilter[key] as string[]).forEach(item => {
          queryFilter[key][item] = true;
        });
      } else {
        queryFilter[key][formFilter[key] as string] = true;
      }
    }
  });

  return queryFilter;
};

export const transformFilterQueryToFormFilter = (queryFilter: {
  [k: string]: { [k: string]: boolean };
}) => {
  const formFilter: { [k: string]: string } = {};
  Object.keys(queryFilter).forEach(queryKey => {
    Object.keys(queryFilter[queryKey]).forEach(key => {
      if (queryFilter[queryKey][key]) {
        formFilter[queryKey] = key;
      }
    });
  });
  return formFilter;
};

export const getSessionId = async (): Promise<{
  sessionId: string;
  previousSessionId?: string;
}> => {
  let sessionId: string = await idb.getItem(STORAGE_ANALYTICS, STORAGE_SESSION_ID_ALIAS);
  let expirationDate = await idb.getItem(
    STORAGE_ANALYTICS,
    STORAGE_SESSION_ID_EXPIRATION_DATE_ALIAS,
  );

  if (expirationDate === STORAGE_SESSION_NO_EXPIRATION) {
    return { sessionId };
  }

  let previousSessionId = '';
  if (!expirationDate || new Date(expirationDate).getTime() < Date.now()) {
    previousSessionId = sessionId;
    sessionId = nanoid();
  }

  expirationDate = new Date(Date.now() + 30 * 60 * 1000).toUTCString();

  idb.setItem(STORAGE_ANALYTICS, STORAGE_SESSION_ID_ALIAS, sessionId);
  idb.setItem(STORAGE_ANALYTICS, STORAGE_SESSION_ID_EXPIRATION_DATE_ALIAS, expirationDate);

  return { sessionId, previousSessionId };
};

export const getParamsFromUrl = (url?: string): { [k: string]: string } => {
  if (!url) {
    return {};
  }
  const _url = new URL(url);
  return _url.search
    .replace('?', '')
    .split('&')
    .reduce((acc, param) => {
      const [key, value] = param.split('=');
      return { ...acc, [key]: decodeURIComponent(value) };
    }, {});
};

export const filterObject = <T extends object>(object: T, cb): T =>
  Object.fromEntries(Object.entries(object).filter(([key, value]) => cb(value, key))) as T;

export const downloadBlobFile = (blob: Blob, title?: string, type?: ExportType) => {
  const fileName = type ? `${title}.${type}` : title;
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = fileName || '';
  anchor.title = fileName || '';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const extractNumbersFromString = (value: string) =>
  Array.from(value.match(/[+-]?\d+(\.\d+)?/g) || []);

export const parseAddress = (address = ''): ParsedAddress => {
  let parsedAddress = {
    number: '(Unknown)',
    prefix: '',
    type: '',

    street: '',
    city: '',
    state: '',
    zip: '',
  };

  try {
    parsedAddress = parser.parseLocation(address);
  } catch (error) {
    console.log(`parseAddress: failed for address=${address}`, error);
  }

  return parsedAddress;
};

export const getDateFilterAmount = (prevAmount: number, filterValue: unknown): number => {
  if (!filterValue) {
    return prevAmount;
  }

  if (typeof filterValue === 'object') {
    const atLeastOnePropNotEmpty = Object.values(filterValue).some(value => !!value);
    return atLeastOnePropNotEmpty ? prevAmount + 1 : prevAmount;
  }

  return prevAmount + 1;
};

export const getAppliedFiltersAmount = (
  filterConfig: {
    multiSelectFields?: string[];
    singleSelectFields?: string[];
    singleSelectWithAllLabelFields?: string[];
    dateRangeObjectFields?: string[];
  },
  values?: { [k: string]: any },
) => {
  if (!values) {
    return 0;
  }
  const {
    multiSelectFields,
    singleSelectFields,
    singleSelectWithAllLabelFields,
    dateRangeObjectFields,
  } = filterConfig;

  let amount = 0;

  Object.keys(values).forEach(key => {
    if (multiSelectFields && multiSelectFields.includes(key)) {
      if ('includes' in values[key] ? !values[key].includes('all') : !values[key].all) {
        amount++;
      }
      return;
    }

    if (singleSelectFields && singleSelectFields.includes(key)) {
      if (values[key] !== '') {
        amount++;
      }
      return;
    }

    if (singleSelectWithAllLabelFields && singleSelectWithAllLabelFields.includes(key)) {
      if (values[key] !== 'all') {
        amount++;
      }
      return;
    }

    if (dateRangeObjectFields && dateRangeObjectFields.includes(key)) {
      amount = getDateFilterAmount(amount, values[key]);
      return;
    }
  });

  return amount;
};

export const removeURLParams = () =>
  window.history.replaceState(
    {},
    document.title,
    `${window.location.origin}${window.location.pathname}`,
  );

const getCoordinatesFromGeodataString = (geodata: string) => {
  const [latitude, longitude] = geodata.split(';').map(pos => pos.split('=')[1]);
  const coordinates = [+longitude, +latitude] as [number, number];
  return coordinates;
};

export const getGeodataFromSessionStorage = () => {
  const geodata = sessionStorage.getItem(GEODATA_SESSION_STORAGE_LABEL);
  if (!geodata) return;

  const coordinates = getCoordinatesFromGeodataString(geodata);

  return coordinates;
};

export const setCenterpointInQQStore = (geodata: string) => {
  const coordinates = getCoordinatesFromGeodataString(geodata);

  if (!store.getState().search.address) {
    store.dispatch(setCenterpoint(coordinates));
  }
};

export const getCardImage = (cardType: string) => paymentCardsImages[cardType] || unknownCardImg;

export const isGAFQuickMeasure = (order: ReportType): order is GAFQuickMeasure => {
  return order.hasOwnProperty('productCode');
};

export const waitForElementToExist = (selector: string): Promise<Element | null> =>
  new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  });

export const decodeHTMLEntities = (htmlStr: string): string => {
  const txt = document.createElement('textarea');
  txt.innerHTML = htmlStr;
  return txt.value;
};

export const sortInSpecificOrder = (items: unknown[], order: unknown[]): unknown[] =>
  [...items].sort((a, b) => order.indexOf(a) - order.indexOf(b));

// todo: add spec
export const awaitTimeout = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const getLeadCommunicationOptions = (
  values?: CommunicationOptions,
): CommunicationOptions | null => {
  if (!values) {
    return null;
  }

  return !isBoolean(values.contactOptIn) && !isBoolean(values.smsOptIn) ? null : values;
};
