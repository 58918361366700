import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ListQuery } from 'types';
import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import {
  constants,
  services,
  Contractor,
  ContractorListItem,
  AdminDashboardScores,
  AdminDashboardFilters,
  ContractorsDropdownOption,
} from 'modules/admin';
import { selectors as userSelectors } from 'modules/auth';
import { Account } from 'modules/billing/types';
import { formatContractorsQuery } from './utils';
import { AdminDashboardContext } from './components/AdminDashboardContexts';

export const useGetContractors = () => {
  const user = useSelector(userSelectors.selectUser);
  const { query } = useContext(AdminDashboardContext);

  return useAppQuery<{ contractors: ContractorListItem[]; total: number }>(
    [constants.CONTRACTORS_KEY, query],
    ({ queryKey }) => {
      const [_, _query] = queryKey;
      return services.getContractorsDashboardAPI(
        user.id,
        formatContractorsQuery(_query as ListQuery),
      );
    },
    {
      enabled: !!user.id,
    },
  );
};

export const useGetContractorsFilter = () => {
  const user = useSelector(userSelectors.selectUser);
  const { query } = useContext(AdminDashboardContext);

  return useAppQuery<{
    data: AdminDashboardFilters;
    scores: AdminDashboardScores;
  }>(
    [constants.CONTRACTORS_FILTER_KEY, query],
    ({ queryKey }) => {
      const [_, _query] = queryKey;
      return services.getContractorsDashboardFilterAPI(
        user.id,
        formatContractorsQuery(_query as ListQuery),
      );
    },
    {
      enabled: !!user.id,
    },
  );
};

export const useGetRecurlyAccounts = () => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Account[]>(
    [constants.RECURLY_ACCOUNTS_KEY],
    () => {
      return services.getRecurlyAccountsAPI(user.id);
    },
    {
      enabled: !!user.id,
      staleTime: Infinity,
    },
  );
};

export const useGetCompanyRecurlyAccount = (contractorId?: number) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Account>(
    [constants.COMPANY_RECURLY_ACCOUNT_KEY, contractorId],
    ({ queryKey }) => {
      return services.getCompanyRecurlyAccountAPI(user.id, queryKey[1] as number);
    },
    {
      enabled: !!(user.id && contractorId),
      staleTime: 0,
    },
  );
};

export const useGetContractorById = (contractorId = 0) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Contractor>(
    [constants.CONTRACTOR_KEY, contractorId],
    ({ queryKey }) => {
      const [_, _contractorId] = queryKey;
      return services.getContractorAPI(user.id, _contractorId as number);
    },
    {
      enabled: !!(user.id && contractorId),
    },
  );
};

export const useGetContractorsForDropdown = (search = '') => {
  const user = useSelector(userSelectors.selectUser);
  return useAppQuery<ContractorsDropdownOption[]>(
    [constants.CONTRACTORS_FOR_DROPDOWN_KEY, search],
    ({ queryKey }) => {
      const [_, _search] = queryKey;
      return services.getContractorsForDropdownAPI(user.id, { search: _search as string });
    },
    {
      enabled: !!user.id,
      hideSpinner: true,
      keepPreviousData: true,
    },
  );
};
