import forEach from 'lodash/forEach';
import uniqBy from 'lodash/uniqBy';
import pick from 'lodash/pick';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';

import { I18nEnum } from 'types';
import { OptionType } from 'components/Inputs/Select/types';
import {
  PartialStatistics,
  QuickQuoteRequestBody,
  Statistics,
  StatisticsProduct,
  StatisticsStructure,
  StructureWasteFactor,
} from 'modules/dashboard/types';
import {
  GeoJsonPolygon,
  MLPredictionDataType,
  MLPredictionResponse,
  MLWasteFactor,
  ReportStructureInformation,
  RoofleStructure,
  StructureInformation,
  WasteFactorTypeEnum,
} from 'modules/quickQuote/types';
import { toRoofleStructure } from 'modules/quickQuote/utils';
import {
  AddAdditionalCostsFormKey,
  AddAdditionalCostsFormValues,
  FormattedQuoteSettings,
  QuoteSettingsModel,
} from 'modules/quoteSettings/types';
import {
  CHIMNEY_GROUP,
  DIFFICULT_ACCESS_GROUP,
  LAYERS_GROUP,
  NEW_DECKING_GROUP,
  ROOF_CONDITION_GROUP,
  ROOF_TYPE_GROUP,
  SKYLIGHT_GROUP,
  STATIC_DISCOUNT_KEYS,
  STORIES_GROUP,
  TOTAL_DISCOUNT_KEY,
} from 'modules/quoteSettings/constants';
import {
  combineAdditionalCostsByStructure,
  formatRoofleStructureToCommon,
  generateRoofTypeAdditionalCostKey,
  getOtherCostKeyNameMap,
  getRoofTypeFromRoofTypeAdditionalCostKey,
  isMeasurementReportSelectForTotalSqFt,
  isRoofTypeAdditionalCostKey,
} from 'modules/global/prices/utils';
import { Line } from 'modules/product/types';

import {
  AdditionalCostAddedValuesWithUpdates,
  AdditionalCostUpdate,
  AdditionalCostUpdateType,
  AdditionalInfoWasteFactorType,
  ChangesType,
  DiscountModel,
  LeadUnsavedChanges,
  SquareFeetEnum,
  SquareFeetType,
  StructureWasteFactorData,
} from './types';

import { addedValuesLabelMap, roofTypeLabelMap } from './constants';
import { formatPrice } from 'utils';

export const serializeStatisticsStructuresToRoofleStructures = (
  structures: StatisticsStructure[],
): RoofleStructure[] =>
  structures
    .filter(structure => structure.geoJsonPolygon)
    .map(structure => {
      const parsedStructure = toRoofleStructure({
        structure: structure.geoJsonPolygon as GeoJsonPolygon,
        name: structure.name,
        overrides: {
          isIncluded: structure.isIncluded,
          name: structure.name,
        },
      });

      parsedStructure.measurements.initialWholeSquareFeet = structure.initialSquareFeet;
      parsedStructure.wasteFactor = structure.wasteFactor;
      parsedStructure.roofComplexity = structure.roofComplexity;
      parsedStructure.customTotalSquareFeet = structure.customTotalSquareFeet;

      return parsedStructure;
    });

export const getOptionLabel = (key: string): string => {
  if (key in addedValuesLabelMap) {
    return addedValuesLabelMap[key];
  }

  if (isRoofTypeAdditionalCostKey(key)) {
    const roofTypeValue = getRoofTypeFromRoofTypeAdditionalCostKey(key);
    return roofTypeValue ? roofTypeLabelMap[roofTypeValue] : I18nEnum.RoofCondition;
  }

  return key;
};

export const generateAddedValuesWithUpdatesOptions = (
  addedValues: Record<number, Record<string, AdditionalCostUpdate>>,
  additionalCostSettings: Partial<FormattedQuoteSettings>,
): AdditionalCostAddedValuesWithUpdates => {
  const otherCostNamingMap = getOtherCostKeyNameMap(additionalCostSettings.otherExtraCosts);

  return Object.entries(addedValues).reduce((res, [structureKey, values]) => {
    const structureOptions = Object.entries(values).reduce(
      (acc: { option: OptionType; update: AdditionalCostUpdate }[], [key, update]) => {
        acc.push({
          update,
          option: {
            label: getOptionLabel(otherCostNamingMap[key] || key),
            value: update.currentValue || 0,
          },
        });
        return acc;
      },
      [],
    );

    return Object.assign(res, { [structureKey]: structureOptions });
  }, {});
};

export const getUniqueAdditionalCostsValues = (
  addedValues: {
    [key: number]: Record<string, number>;
  },
  additionalCostFormValues: { [key: number | string]: AddAdditionalCostsFormValues },
): string[] => {
  const sharedValues = Object.entries(addedValues).reduce(
    (res: Record<string, number>, [structureKey, values]) => {
      const structureOptions = Object.entries(values)
        .filter(([, value]) => !!value)
        .reduce((acc: Record<string, number>, [key, value]) => {
          if (key === AddAdditionalCostsFormKey.RoofTypeCost) {
            const roofTypeKey = additionalCostFormValues[structureKey][key];
            acc[generateRoofTypeAdditionalCostKey(roofTypeKey)] = value;
          } else {
            acc[key] = value;
          }

          return acc;
        }, {});
      return { ...res, ...structureOptions };
    },
    {},
  );

  return Object.keys(sharedValues);
};

export const getAdditionalCostUpdateType = (
  value1: number | null,
  value2: number | null,
): AdditionalCostUpdateType => {
  if (!value1 && !value2) {
    return AdditionalCostUpdateType.None;
  }

  if (value1 === null) {
    return AdditionalCostUpdateType.Added;
  }

  if (value2 === null) {
    return AdditionalCostUpdateType.Removed;
  }

  return value1 !== value2 ? AdditionalCostUpdateType.Changed : AdditionalCostUpdateType.None;
};

export const mergeAdditionalCostValues = (
  additionalCosts1: Record<number, Record<string, number>> | null,
  additionalCosts2: Record<number, Record<string, number>> | null,
): Record<number, Record<string, AdditionalCostUpdate>> => {
  const mergeResults: Record<number, Record<string, AdditionalCostUpdate>> = {};

  forEach(additionalCosts1 || {}, (structureValues, structureKey) => {
    const matchingStructure = additionalCosts2?.[structureKey] || {};
    const mergedStructure: Record<string, AdditionalCostUpdate> = {};

    forEach(structureValues, (additionalCostValue, additionalCostKey) => {
      const matchingCost = matchingStructure[additionalCostKey] || null;
      const updateType = getAdditionalCostUpdateType(additionalCostValue, matchingCost);

      mergedStructure[additionalCostKey] = {
        key: additionalCostKey,
        currentValue: additionalCostValue || 0,
        updatedValue: matchingCost || 0,
        updateType,
      };
    });

    mergeResults[structureKey] = mergedStructure;
  });

  forEach(additionalCosts2 || {}, (structureValues, structureKey) => {
    const matchingStructure = additionalCosts1?.[structureKey] || {};
    const mergedStructure = mergeResults[structureKey] || {};

    forEach(structureValues, (additionalCostValue, additionalCostKey) => {
      if (mergedStructure[additionalCostKey]) {
        return;
      }

      const matchingCost = matchingStructure[additionalCostKey] || null;
      const updateType = getAdditionalCostUpdateType(matchingCost, additionalCostValue);

      if (updateType === AdditionalCostUpdateType.Added) {
        return;
      }

      mergedStructure[additionalCostKey] = {
        key: additionalCostKey,
        currentValue: matchingCost || 0,
        updatedValue: additionalCostValue || 0,
        updateType,
      };
    });

    mergeResults[structureKey] = mergedStructure;
  });

  return mergeResults;
};

const mergeStoryCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.storyCostsEnabled ? currentSettings : companySettings;
  return {
    storyCostsEnabled: currentSettings.storyCostsEnabled || companySettings.storyCostsEnabled,
    ...pick(source, [STORIES_GROUP]),
  };
};

const mergeLayerCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.layerCostsEnabled ? currentSettings : companySettings;
  return {
    layerCostsEnabled: currentSettings.layerCostsEnabled || companySettings.layerCostsEnabled,
    ...pick(source, [LAYERS_GROUP]),
  };
};

const mergeChimneyCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.chimneyCostsEnabled ? currentSettings : companySettings;
  return {
    chimneyCostsEnabled: currentSettings.chimneyCostsEnabled || companySettings.chimneyCostsEnabled,
    ...pick(source, [CHIMNEY_GROUP]),
  };
};

const mergeSkylightCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.skylightCostsEnabled ? currentSettings : companySettings;
  return {
    skylightCostsEnabled:
      currentSettings.skylightCostsEnabled || companySettings.skylightCostsEnabled,
    ...pick(source, [SKYLIGHT_GROUP]),
  };
};

const mergeRoofConditionCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.roofConditionCostsEnabled ? currentSettings : companySettings;
  return {
    roofConditionCostsEnabled:
      currentSettings.roofConditionCostsEnabled || companySettings.roofConditionCostsEnabled,
    ...pick(source, [ROOF_CONDITION_GROUP]),
  };
};

const mergeRoofTypeCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.roofTypeCostsEnabled ? currentSettings : companySettings;
  return {
    roofTypeCostsEnabled:
      currentSettings.roofTypeCostsEnabled || companySettings.roofTypeCostsEnabled,
    ...pick(source, [ROOF_TYPE_GROUP]),
  };
};

const mergeNewDeckingCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.newDeckingCostsEnabled ? currentSettings : companySettings;
  return {
    newDeckingCostsEnabled:
      currentSettings.newDeckingCostsEnabled || companySettings.newDeckingCostsEnabled,
    ...pick(source, [NEW_DECKING_GROUP]),
  };
};

const mergeDifficultAccessCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => {
  const source = currentSettings.difficultAccessCostsEnabled ? currentSettings : companySettings;
  return {
    difficultAccessCostsEnabled:
      currentSettings.difficultAccessCostsEnabled || companySettings.difficultAccessCostsEnabled,
    ...pick(source, [DIFFICULT_ACCESS_GROUP]),
  };
};

const mergeExtraCostsQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): Partial<QuoteSettingsModel> => ({
  extraCostsEnabled: currentSettings.extraCostsEnabled || companySettings.extraCostsEnabled,
  extraCosts: uniqBy(
    [...(companySettings.extraCosts || []), ...(currentSettings.extraCosts || [])],
    'id',
  ),
});

export const mergeQuoteSettings = (
  companySettings: QuoteSettingsModel,
  currentSettings: QuoteSettingsModel,
): QuoteSettingsModel => {
  return {
    ...companySettings,
    ...mergeStoryCostsQuoteSettings(companySettings, currentSettings),
    ...mergeLayerCostsQuoteSettings(companySettings, currentSettings),
    ...mergeChimneyCostsQuoteSettings(companySettings, currentSettings),
    ...mergeSkylightCostsQuoteSettings(companySettings, currentSettings),
    ...mergeRoofConditionCostsQuoteSettings(companySettings, currentSettings),
    ...mergeRoofTypeCostsQuoteSettings(companySettings, currentSettings),
    ...mergeNewDeckingCostsQuoteSettings(companySettings, currentSettings),
    ...mergeDifficultAccessCostsQuoteSettings(companySettings, currentSettings),
    ...mergeExtraCostsQuoteSettings(companySettings, currentSettings),
  };
};

export const formatQuestionOptions = (
  options: OptionType[],
  disabled: boolean,
  singleSelectedValue?: string | number | null,
): OptionType[] =>
  options.map(option => ({
    ...option,
    disabled: disabled || singleSelectedValue === option.value,
  }));

export const formatStructuresBasedOnSqFtSettings = (
  structures?: (RoofleStructure | ReportStructureInformation)[],
  settings?: ChangesType['squareFeet'],
): StructureInformation[] => {
  if (!structures) {
    return [];
  }

  const isCustomSquareFeet = settings?.type === SquareFeetEnum.Custom;

  return (structures as RoofleStructure[]).map(structure => {
    const squareFeet = isCustomSquareFeet
      ? settings.value[structure.geoJsonPolygon.id as string]
      : structure.measurements.squareFeet;
    const wholeSquareFeet = isCustomSquareFeet
      ? settings.value[structure.geoJsonPolygon.id as string]
      : structure.measurements.wholeSquareFeet;

    return formatRoofleStructureToCommon(structure, { squareFeet, wholeSquareFeet });
  });
};

export const filterDiscounts = (
  discounts: DiscountModel[],
  additionalCostValues?: Record<number, Record<string, number>> | null,
): DiscountModel[] => {
  if (!additionalCostValues) {
    const totalDiscount = discounts.find(({ type }) => type === TOTAL_DISCOUNT_KEY);
    return totalDiscount ? [totalDiscount] : [];
  }

  const combinedAdditionalCosts = combineAdditionalCostsByStructure(additionalCostValues);
  const totalAdditionalCosts = Object.values(combinedAdditionalCosts).reduce(
    (sum, value) => sum + value,
    0,
  );

  if (!totalAdditionalCosts) {
    const totalDiscount = discounts.find(({ type }) => type === TOTAL_DISCOUNT_KEY);
    return totalDiscount ? [totalDiscount] : [];
  }

  return discounts.filter(
    ({ type }) => combinedAdditionalCosts[type] || STATIC_DISCOUNT_KEYS.includes(type),
  );
};

export const additionalCostCurrencyFormat = (value: number | string) => formatPrice(value);

export const convertToPercent = (value: number | string) =>
  +value === 0 ? 0 : Math.round((+value - 1) * 100);

export const filterSuggestedWasteFactorByProductType = ({
  products,
  structureSuggestedWasteFactor,
}: {
  products: (Line | StatisticsProduct)[];
  structureSuggestedWasteFactor: MLWasteFactor;
}): Partial<MLWasteFactor> =>
  products?.reduce((acc: Partial<MLWasteFactor>, product) => {
    const productType = product.type && /tile/i.test(product.type) ? 'Tile' : product.type;
    if (productType && structureSuggestedWasteFactor?.[productType] && !product.customProduct) {
      acc[productType] = structureSuggestedWasteFactor[productType];
    }
    return acc;
  }, {});

export const addProductsToTheUpdateLeadRequestBody = ({
  products,
  requestBody,
  quoteSetting,
}: {
  products: StatisticsProduct[];
  requestBody: PartialStatistics;
  quoteSetting?: QuoteSettingsModel;
}): void => {
  if (!requestBody.additionalInformation || !quoteSetting) {
    return;
  }

  requestBody.additionalInformation.products = products.map(product => {
    const statisticsProduct: StatisticsProduct = {
      name: product.productName || product.name,
      id: product.id,
      type: product.type,
      priceInfo: product.priceInfo,
    };
    if (product.customProduct) {
      statisticsProduct.customProduct = { id: product.customProduct.id };
    }
    return statisticsProduct;
  });
};

const addStructuresToTheUpdateLeadRequestBody = ({
  requestBody,
  structures,
  leadData,
}: {
  requestBody: PartialStatistics;
  structures: StatisticsStructure[];
  leadData?: Statistics;
}): void => {
  if (!requestBody.additionalInformation) {
    return;
  }

  const updatedStructures = structures.map(structure => {
    const initialStructure = leadData?.additionalInformation.structures.find(
      item => item.geoJsonPolygon?.id === structure.geoJsonPolygon?.id,
    );

    return {
      ...structure,
      slope: capitalize(structure.slope),
      initialSquareFeet: initialStructure
        ? initialStructure.initialSquareFeet
        : structure.initialSquareFeet,
    };
  });

  requestBody.additionalInformation.structures = updatedStructures;
};

const filterAdditionalConstBasedOnSavedStructures = ({
  requestBody,
  leadData,
}: {
  requestBody: PartialStatistics;
  leadData?: Statistics;
}): void => {
  if (
    !requestBody.additionalInformation?.additionalCost ||
    !leadData?.additionalInformation.additionalCost ||
    !requestBody.additionalInformation.structures
  ) {
    return;
  }

  const additionalCost =
    requestBody.additionalInformation.additionalCost ||
    leadData.additionalInformation.additionalCost;

  requestBody.additionalInformation.additionalCost =
    requestBody.additionalInformation.structures.reduce(
      (acc, structure) => ({
        ...acc,
        [structure.geoJsonPolygon?.id as string]:
          additionalCost[structure.geoJsonPolygon?.id as string],
      }),
      {},
    );
};

const addWasteFactorToTheUpdateLeadRequestBody = ({
  requestBody,
  structures,
  changes,
  reportWasteFactors,
  leadData,
}: {
  requestBody: PartialStatistics;
  structures: StatisticsStructure[];
  changes: Record<string, AdditionalInfoWasteFactorType>;
  reportWasteFactors?: StructureWasteFactorData[];
  leadData?: Statistics;
}): void => {
  if (!requestBody.additionalInformation) {
    return;
  }

  const squareFeetSettingsUpdates =
    requestBody.additionalInformation.squareFeetSettings ||
    leadData?.additionalInformation.squareFeetSettings;

  if (
    squareFeetSettingsUpdates &&
    squareFeetSettingsUpdates.uniqId &&
    isMeasurementReportSelectForTotalSqFt(squareFeetSettingsUpdates?.type) &&
    reportWasteFactors
  ) {
    requestBody.additionalInformation.thirdPartyReportWasteFactor = {
      orderId: squareFeetSettingsUpdates.uniqId as number,
      reportWasteFactors: reportWasteFactors.map(wasteFactorData => {
        const res: StructureWasteFactorData = {
          ...wasteFactorData,
          wasteFactor: {
            ...wasteFactorData.wasteFactor,
            ...changes[wasteFactorData.id],
          },
        };

        if (changes[wasteFactorData.id]?.usedWFType !== WasteFactorTypeEnum.Custom) {
          res.wasteFactor = omit(res.wasteFactor, ['custom']);
        }

        return res;
      }),
    };
    return;
  }

  const _structures = requestBody.additionalInformation.structures || structures;
  const updatedStructures = _structures.map(structure => {
    const structureGeoJsonId = structure.geoJsonPolygon?.id;
    if (!structureGeoJsonId) return structure;

    if (changes[structureGeoJsonId]?.usedWFType === WasteFactorTypeEnum.Custom) {
      structure.wasteFactor = {
        ...(structure.wasteFactor as StructureWasteFactor),
        usedWFType: WasteFactorTypeEnum.Custom,
        custom: changes[structureGeoJsonId]?.custom,
      };
    } else {
      delete structure.wasteFactor?.custom;
      if (changes[structureGeoJsonId]) {
        structure.wasteFactor = {
          ...(structure.wasteFactor as StructureWasteFactor),
          usedWFType: changes[structureGeoJsonId].usedWFType,
          default: changes[structureGeoJsonId].default,
        };
      }
    }

    return structure;
  });

  requestBody.additionalInformation.structures = updatedStructures;
};

export const buildUpdateLeadRequestBody = async ({
  quickQuoteRequestBody,
  changes,
  leadData,
  quoteSetting,
  reportWasteFactor,
}: {
  quickQuoteRequestBody: QuickQuoteRequestBody;
  changes: LeadUnsavedChanges;
  leadData?: Statistics;
  quoteSetting?: QuoteSettingsModel;
  reportWasteFactor?: StructureWasteFactorData[];
}): Promise<PartialStatistics> => {
  const requestBody: PartialStatistics = { additionalInformation: {} };
  const needToUpdateProducts =
    changes.productsChanged ||
    changes.structuresChanged ||
    changes.market ||
    changes.additionalCost ||
    changes.discount ||
    changes.wasteFactor ||
    changes.squareFeet ||
    changes.priceRangeEnabled !== undefined;

  const {
    numberOfStructures,
    numberOfIncludedStructures,
    mainRoofTotalSquareFeet,
    totalSquareFeet,
    additionalInformation: { structures, products, mapScreenshot },
  } = quickQuoteRequestBody;

  if (!requestBody.additionalInformation) {
    return requestBody;
  }

  if (changes.userInfo) {
    requestBody.firstName = changes.userInfo.firstName;
    requestBody.lastName = changes.userInfo.lastName;
    requestBody.phone = changes.userInfo.phone;
    requestBody.email = changes.userInfo.email;
  }

  if (changes.market) {
    requestBody.market = changes.market.name;
    requestBody.marketSlug = changes.market.slug;
  }

  if (changes.structuresChanged) {
    requestBody.numberOfStructures = numberOfStructures;
    requestBody.numberOfIncludedStructures = numberOfIncludedStructures;
    requestBody.mainRoofTotalSquareFeet = mainRoofTotalSquareFeet;
    requestBody.totalSquareFeet = totalSquareFeet;

    addStructuresToTheUpdateLeadRequestBody({ structures, leadData, requestBody });
    filterAdditionalConstBasedOnSavedStructures({ requestBody, leadData });
  }

  if (changes.mapChanged && mapScreenshot) {
    requestBody.additionalInformation.mapScreenshot = mapScreenshot;
  }

  if (changes.squareFeet) {
    const _structures = requestBody.additionalInformation.structures || structures;

    requestBody.additionalInformation.structures = _structures.map(structure => ({
      ...structure,
      customTotalSquareFeet:
        changes.squareFeet?.type === SquareFeetEnum.Custom
          ? changes.squareFeet.value[structure.geoJsonPolygon?.id as string]
          : 0,
    }));

    requestBody.additionalInformation.squareFeetSettings = omit(changes.squareFeet, ['value']);

    if (!isMeasurementReportSelectForTotalSqFt(changes.squareFeet.type)) {
      requestBody.additionalInformation.thirdPartyReportWasteFactor = null;
    }
  }

  if ('additionalCost' in changes || 'additionalCostEnabled' in changes) {
    const additionalCostEnabled = changes.additionalCostEnabled ?? true;
    requestBody.additionalInformation.additionalCost = additionalCostEnabled
      ? changes.additionalCost
      : null;
    requestBody.additionalInformation.additionalCostSettings = additionalCostEnabled
      ? changes.additionalCostSettings
      : null;
  }

  if (changes.discount || changes.filteredDiscount || 'discountEnabled' in changes) {
    const discountEnabled = changes.discountEnabled ?? true;
    requestBody.additionalInformation.discount = discountEnabled
      ? changes.filteredDiscount || changes.discount
      : { discounts: [] };
  }

  if (changes.wasteFactor) {
    addWasteFactorToTheUpdateLeadRequestBody({
      requestBody,
      structures,
      changes: changes.wasteFactor,
      reportWasteFactors: reportWasteFactor,
      leadData,
    });
  }

  if (changes.priceRangeEnabled !== undefined) {
    requestBody.additionalInformation.priceRangeEnabled = changes.priceRangeEnabled;
  }

  if (needToUpdateProducts) {
    addProductsToTheUpdateLeadRequestBody({
      products,
      requestBody,
      quoteSetting,
    });
  }

  if (changes.productsChanged || changes.market) {
    requestBody.additionalInformation.informationState = {
      productsHaveBeenChanged: false,
    };
  }

  return requestBody;
};

export const isTotalSquareFeetEqual = (
  settings1?: SquareFeetType,
  settings2?: SquareFeetType,
): boolean =>
  !!settings1 &&
  !!settings2 &&
  settings1.type === settings2.type &&
  settings1.uniqId === settings2.uniqId;
