import api from 'services/api';

export const getNotifications = (userId: number) => api.get(`user/${userId}/notifications`);

export const toggleReadNotification = (userId: number, notificationId: number) =>
  api.put(`user/${userId}/notifications/read/${notificationId}`);

export const deleteNotification = (userId: number, notificationId: number) =>
  api.delete(`user/${userId}/notifications/delete/${notificationId}`);

export const markNotificationsAsRead = (userId: number) =>
  api.put(`user/${userId}/notifications/read`);

export const batchReadNotifications = (userId: number, id: number[]) =>
  api.put(`user/${userId}/notifications/read/batch`, id);
