import { createContext, Dispatch } from 'react';

import { CompanyDetailsTabKeys, AdminDashboardQuery } from 'modules/admin/types';
import { initialQuery } from 'modules/admin/constants';
import { createDashboardLayoutContext } from 'components/DashboardLayout/createDashboardLayoutContext';

interface AdminDashboardContextType {
  query: AdminDashboardQuery;
  setQuery: (query: AdminDashboardQuery) => void;
  searchValue: string;
  setSearchValue: Dispatch<React.SetStateAction<string>>;
}

interface ContractorContextType {
  contractorId: number;
  isFullScreen: boolean;
}

export const AdminDashboardContext = createContext<AdminDashboardContextType>({
  query: initialQuery,
  setQuery: () => {},
  searchValue: '',
  setSearchValue: () => {},
});

export const DashboardLayoutContext = createDashboardLayoutContext<CompanyDetailsTabKeys>();

export const ContractorContext = createContext<ContractorContextType>({
  contractorId: 0,
  isFullScreen: false,
});
