import { createContext } from 'react';

import { DynamicTabData } from './types';

export interface DynamicTabsConfig<TSubTabKeys> {
  [dynamicTabId: number]: TSubTabKeys;
}

export interface DashboardLayoutContextType<TSubTabKeys> {
  activeTabKey: number;
  setActiveTabKey: (activeTabKey: number) => void;
  openedTabs: DynamicTabData[];
  setOpenedTabs: (openedTabs: DynamicTabData[]) => void;
  scrollToTheTab: (key: number) => void;
  closeTab: (key: number) => void;
  dynamicTabsConfig: DynamicTabsConfig<TSubTabKeys>;
  setDynamicTabsConfig: (dynamicTabsConfig: DynamicTabsConfig<TSubTabKeys>) => void;
  handleFullScreenClick: (key: number) => void;
}

export const createDashboardLayoutContext = <TSubTabKeys>() =>
  createContext<DashboardLayoutContextType<TSubTabKeys>>({
    activeTabKey: 0,
    setActiveTabKey: () => {},
    openedTabs: [],
    setOpenedTabs: () => {},
    scrollToTheTab: () => {},
    closeTab: () => {},
    dynamicTabsConfig: {},
    setDynamicTabsConfig: () => {},
    handleFullScreenClick: () => {},
  });
