import { I18nEnum } from 'types';
import {
  ValidatableFieldsToSectionTitleMap,
  WidgetSettings,
  WidgetSettingsConfigState,
  WidgetSettingsState,
} from 'modules/widget/types';
import { ModalTypes } from 'modules/modal/types';
import { DEFAULT_BRANDING_CONFIG } from './toolScript';

export * from './toolScript';

const name = 'company/';

export const SET_WIDGET_SETTINGS = `${name}SET_WIDGET_SETTINGS`;
export const SET_ALL_WIDGET_SETTINGS = `${name}SET_ALL_WIDGET_SETTINGS`;
export const RESET_WIDGET_SETTINGS = `${name}RESET_WIDGET_SETTINGS`;

export const SET_WIDGET_PRODUCTS = `${name}SET_WIDGET_PRODUCTS`;
export const SET_WIDGET_WILDCARD = `${name}SET_WIDGET_WILDCARD`;
export const SET_WIDGET_IFRAME_PREVIEW = `${name}SET_WIDGET_IFRAME_PREVIEW`;
export const SET_WIDGET_EXTERNAL_URL = `${name}SET_WIDGET_EXTERNAL_URL`;
export const SET_WIDGET_USER_INFO = `${name}SET_WIDGET_SET_WIDGET_USER_INFO`;
export const SET_MAP_PLACEHOLDER_MEDIA = `${name}SET_MAP_PLACEHOLDER_MEDIA`;
export const CLEAR_MAP_SETTINGS_MEDIA = `${name}CLEAR_MAP_SETTINGS`;
export const CLEAR_BRANDING = `${name}CLEAR_BRANDING`;

export const GET_WIDGET_PRODUCTS_KEY = `${name}GET_WIDGET_PRODUCTS_KEY`;
export const USER_IP_GEOLOCATION_KEY = `${name}USER_IP_GEOLOCATION_KEY`;

export const DEFAULT_WIDGET_SETTINGS: WidgetSettingsState = {
  loaded: false,
  demo: false,
  branding: DEFAULT_BRANDING_CONFIG,
  products: [],
  domains: [],
};

export const mapSettings = 'mapSettings' as const;
export const widgetBranding = 'widgetBranding' as const;
export const toolCallout = 'toolCallout' as const;
export const automations = 'automations' as const;

export const brandingValidatableFieldsToSectionTitleMap: ValidatableFieldsToSectionTitleMap = {
  'titles.fontColor': I18nEnum.TextIconsIllustrations,
  'subtitles.fontColor': I18nEnum.TextIconsIllustrations,
  'mainText.fontColor': I18nEnum.TextIconsIllustrations,
  'secondaryText.fontColor': I18nEnum.TextIconsIllustrations,
  'mainButton.fillColor': I18nEnum.Controls,
  'mainButton.strokeColor': I18nEnum.Controls,
  'mainButton.seeProductDetailsButtonText': I18nEnum.Controls,
  'mainButton.customFinancingButtonText': I18nEnum.Controls,
  'secondaryButton.fillColor': I18nEnum.Controls,
  'secondaryButton.strokeColor': I18nEnum.Controls,
  'background.fillColor': I18nEnum.Background,

  'roofQuoteProButton.text': I18nEnum.SlideoutTab,
  'callout.descriptionText': I18nEnum.Callout,
  'callout.titleText': I18nEnum.Callout,
  'titles.mainTitleText': I18nEnum.TextIconsIllustrations,
  'mainText.exampleText': I18nEnum.TextIconsIllustrations,
  'thankYou.thankYouTitleText': I18nEnum.ThankYouScreen,
  'thankYou.thankYouButtonText': I18nEnum.ThankYouScreen,

  'rqpLeads.contactOptInText': I18nEnum.RQPLeadsForm,
  'rqpLeads.smsOptInText': I18nEnum.RQPLeadsForm,
  'rqpLeads.titleText': I18nEnum.RQPLeadsForm,
  'rqpLeads.subTitleText': I18nEnum.RQPLeadsForm,
  'rqpLeads.buttonText': I18nEnum.RQPLeadsForm,
  'rqpLeads.legalText': I18nEnum.RQPLeadsForm,
  'otherContacts.contactOptInText': I18nEnum.OtherContactsForm,
  'otherContacts.smsOptInText': I18nEnum.OtherContactsForm,
  'otherContacts.titleText': I18nEnum.OtherContactsForm,
  'otherContacts.subTitleText': I18nEnum.OtherContactsForm,
  'otherContacts.buttonText': I18nEnum.OtherContactsForm,
  'otherContacts.legalText': I18nEnum.OtherContactsForm,

  'measurementsOrder.mainMeasurementsReportType': I18nEnum.MeasurementOrderSettings,

  'productDetails.sendProductButtonText': I18nEnum.Control,
  'productDetails.getFinalProposalButtonText': I18nEnum.Control,
} as const;

export const GEODATA_SESSION_STORAGE_LABEL = 'geodata';

const OC_NOT_EDITABLE_FIELDS: (keyof WidgetSettings)[] = [
  'backgroundFillColor',
  'checkboxFillColor',
  'checkboxStrokeColor',
  'checkboxCornerRadius',
  'iconsFillColor',
  'illustrationSelectedColor',
  'illustrationUnselectedColor',
  'mainButtonFillColor',
  'mainButtonFontColor',
  'mainButtonCornerRadius',
  'mainButtonStrokeColor',
  'mainButtonFont',
  'mainButtonFontWeight',
  'mainButtonFontSize',
  'seeProductDetailsButtonText',
  'mainTextFontColor',
  'mainTextFont',
  'mainTextExampleText',
  'roofFillColor',
  'roofStrokeColor',
  'secondaryButtonFillColor',
  'secondaryButtonFontColor',
  'secondaryButtonFont',
  'secondaryButtonStrokeColor',
  'secondaryButtonCornerRadius',
  'secondaryButtonFontWeight',
  'secondaryButtonFontSize',
  'secondaryTextFontColor',
  'secondaryTextFont',
  'titlesFontColor',
  'titlesFont',
  'subtitlesFontColor',
  'subtitlesFont',
  'titlesMainTitleText',
  'tooltipAndMapControlsFillColor',
  'tooltipAndMapControlsFontColor',
  'tooltipAndMapControlsStrokeColor',
  'tooltipAndMapControlsCornerRadius',
  'rqpButtonFillColor',
  'rqpButtonFont',
  'rqpButtonFontColor',
  'rqpButtonFontSize',
  'rqpButtonFontWeight',
  'rqpButtonStrokeColor',
  'rqpButtonText',
  'calloutFillColor',
  'calloutStrokeColor',
  'calloutTitleText',
  'calloutTitleFont',
  'calloutTitleFontColor',
  'calloutDescriptionText',
  'calloutDescriptionTextFont',
  'calloutDescriptionTextFontColor',
  'useOwensCorningWidgetBranding',
  'useOwensCorningToolCalloutBranding',
];

export const MOCK_OC_WIDGET_BRANDING_NOT_EDITABLE_FIELDS: (keyof WidgetSettings)[] = [
  'backgroundFillColor',
  'checkboxFillColor',
  'checkboxStrokeColor',
  'checkboxCornerRadius',
  'iconsFillColor',
  'illustrationSelectedColor',
  'illustrationUnselectedColor',
  'mainButtonFillColor',
  'mainButtonFontColor',
  'mainButtonCornerRadius',
  'mainButtonStrokeColor',
  'mainButtonFont',
  'mainButtonFontWeight',
  'mainButtonFontSize',
  'mainTextFontColor',
  'mainTextFont',
  'roofFillColor',
  'roofStrokeColor',
  'secondaryButtonFillColor',
  'secondaryButtonFontColor',
  'secondaryButtonFont',
  'secondaryButtonStrokeColor',
  'secondaryButtonCornerRadius',
  'secondaryButtonFontWeight',
  'secondaryButtonFontSize',
  'secondaryTextFontColor',
  'secondaryTextFont',
  'titlesFontColor',
  'titlesFont',
  'subtitlesFontColor',
  'subtitlesFont',
  'tooltipAndMapControlsFillColor',
  'tooltipAndMapControlsFontColor',
  'tooltipAndMapControlsStrokeColor',
  'tooltipAndMapControlsCornerRadius',
];

export const MOCK_OC_TOOL_CALLOUT_BRANDING_NOT_EDITABLE_FIELDS: (keyof WidgetSettings)[] = [
  'rqpButtonFillColor',
  'rqpButtonFont',
  'rqpButtonFontColor',
  'rqpButtonFontSize',
  'rqpButtonFontWeight',
  'rqpButtonStrokeColor',
  'calloutFillColor',
  'calloutStrokeColor',
  'calloutTitleFont',
  'calloutTitleFontColor',
  'calloutDescriptionTextFont',
  'calloutDescriptionTextFontColor',
];

export const DEFAULT_SETTINGS_CONFIG: WidgetSettingsConfigState = {
  initialDemoViewType: widgetBranding,
  notEditableFields: [],
  useOCBrandingToggleVisible: true,
  showUpgradeSubscriptionTooltip: false,
  mockOCWidgetBrandingNotEditableFields: MOCK_OC_WIDGET_BRANDING_NOT_EDITABLE_FIELDS,
  mockOCToolCalloutBrandingNotEditableFields: MOCK_OC_TOOL_CALLOUT_BRANDING_NOT_EDITABLE_FIELDS,
  demoViewTypeOptionsValue: [
    widgetBranding,
    toolCallout,
    mapSettings,
    ModalTypes.productDetails,
    ModalTypes.thankYou,
    automations,
    ModalTypes.almostDone,
    ModalTypes.potentialClient,
  ],
  roofQuoteProButton: {
    showFullSettings: true,
  },
  callout: {
    showFullSettings: true,
  },
};

export const OC_SETTINGS_CONFIG: WidgetSettingsConfigState = {
  initialDemoViewType: toolCallout,
  notEditableFields: OC_NOT_EDITABLE_FIELDS,
  mockOCWidgetBrandingNotEditableFields: MOCK_OC_WIDGET_BRANDING_NOT_EDITABLE_FIELDS,
  mockOCToolCalloutBrandingNotEditableFields: MOCK_OC_TOOL_CALLOUT_BRANDING_NOT_EDITABLE_FIELDS,
  useOCBrandingToggleVisible: false,
  showUpgradeSubscriptionTooltip: true,
  demoViewTypeOptionsValue: [
    toolCallout,
    mapSettings,
    ModalTypes.productDetails,
    ModalTypes.thankYou,
    automations,
    ModalTypes.almostDone,
    ModalTypes.potentialClient,
  ],
  roofQuoteProButton: {
    showFullSettings: false,
  },
  callout: {
    showFullSettings: false,
  },
};

export const GENERAL_SETTINGS_BLOCKER_KEY = 'generalSettingsBlocker';
