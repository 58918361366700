import { ModalTypes } from 'modules/modal/types';
import { ParcelState, RoofleStructure, StructureState } from './types';

export const SET_QUICK_QUOTE_ALL = 'quickQuote/SET_QUICK_QUOTE_ALL';
export const SET_QUICK_QUOTE_IS_LOADED = 'quickQuote/SET_QUICK_QUOTE_IS_LOADED';
export const SET_QUICK_QUOTE_IS_READY_TO_RENDER = 'quickQuote/SET_QUICK_QUOTE_IS_READY_TO_RENDER';
export const SET_QUICK_QUOTE_PARCEL = 'quickQuote/SET_QUICK_QUOTE_PARCEL';
export const SET_QUICK_QUOTE_ADDRESS_FEATURE = 'quickQuote/SET_QUICK_QUOTE_ADDRESS_FEATURE';
export const SET_QUICK_QUOTE_CENTERPOINT = 'quickQuote/SET_QUICK_QUOTE_CENTERPOINT';
export const SET_QUICK_QUOTE_INITIAL_STRUCTURES = 'quickQuote/SET_QUICK_QUOTE_INITIAL_STRUCTURES';
export const SET_QUICK_QUOTE_STRUCTURES = 'quickQuote/SET_QUICK_QUOTE_STRUCTURES';
export const SET_QUICK_QUOTE_STRUCTURE_SLOPE = 'quickQuote/SET_QUICK_QUOTE_STRUCTURE_SLOPE';
export const SET_QUICK_QUOTE_STRUCTURE_NAME = 'quickQuote/SET_QUICK_QUOTE_STRUCTURE_NAME';
export const SET_QUICK_QUOTE_USER_INFO = `quickQuote/SET_QUICK_QUOTE_USER_INFO`;
export const SET_QUICK_QUOTE_MARKET_SLUG = `quickQuote/SET_QUICK_QUOTE_MARKET_SLUG`;
export const SET_QUICK_QUOTE_CENTERPOINT_STATE = `quickQuote/SET_QUICK_QUOTE_CENTERPOINT_STATE`;
export const SET_QUICK_QUOTE_ML_PREDICTION_DATA_IN_STRUCTURES =
  'quickQuote/SET_QUICK_QUOTE_ML_PREDICTION_DATA_IN_STRUCTURES';
export const SET_QUICK_QUOTE_ML_PREDICTION_DATA = 'quickQuote/SET_QUICK_QUOTE_ML_PREDICTION_DATA';
export const SET_QUICK_QUOTE_PRODUCT_PRICE_SETTINGS =
  'quickQuote/SET_QUICK_QUOTE_PRODUCT_PRICE_SETTINGS';

export const CLEAR = 'quickQuote/CLEAR';
export const SET_ERROR = 'quickQuote/ERROR';
export const SET_REGIONAL_APPLIED = 'quickQuote/SET_REGIONAL_APPLIED';

export const STORAGE_ADDRESS_PATH = 'quickQuote/address';
export const STORAGE_ADDRESS_RESULTS_PATH = 'quickQuote/address_results';
export const STORAGE_ADDRESS_FEATURE_PATH = 'quickQuote/address_feature';

export const STORAGE_QUICK_QUOTE = 'quickQuote';
export const STORAGE_QUICK_QUOTE_MAP_ALIAS = 'map';
export const STORAGE_QUICK_QUOTE_MAP_DEMO_ALIAS = 'demo-map';
export const STORAGE_PAST_QUICK_QUOTE_MAPS_ALIAS = 'past-maps';

export const PERCENTAGE_TO_INCLUDE_STRUCTURE_IN_PARCEL = 50;

export const SET_MAP_CONFIG = 'quickQuote/SET_MAP_CONFIG';

export const squareCoeficient = 10.7639; // square meters / square feets;

export const STRUCTURES_LIMIT = 10;

export const SET_USER_DETAILS_PROVIDED = 'quickQuote/SET_USER_DETAILS_PROVIDED';

export const SET_LEAD_DATA = 'quickQuote/SET_LEAD_DATA';

const PARCEL = {
  type: 'Feature' as const,
  geometry: {
    type: 'Polygon' as const,
    coordinates: [
      [
        [-93.116711311793, 44.9481107664022],
        [-93.1165465383467, 44.9481113103468],
        [-93.1165498200636, 44.9482509548734],
        [-93.116832887992, 44.9483823008011],
        [-93.116895180833, 44.9484083362692],
        [-93.1169606280325, 44.9484301227002],
        [-93.1170286564713, 44.94844746931],
        [-93.1170986704246, 44.9484602241939],
        [-93.1171700567789, 44.948468275657],
        [-93.1171650065971, 44.9481092655407],
        [-93.1171649473237, 44.9479827615017],
        [-93.1170907056752, 44.9479826254892],
        [-93.1167099536937, 44.9479817424216],
        [-93.116711311793, 44.9481107664022],
      ],
    ],
  },
  properties: {
    headline: '400 Marshall Ave',
    path: '/us/mn/ramsey/st-paul/80860',
  },
  id: 80860,
};

const ROOFLE_STRUCTURE_1: RoofleStructure = {
  id: 'demo-structure-1',
  geoJsonPolygon: {
    id: 'demo-structure-1',
    type: 'Feature' as const,
    properties: { id: 'demo-structure-1', name: 'Main Roof', slope: 'medium' },
    geometry: {
      type: 'Polygon' as const,
      coordinates: [
        [
          [-93.11704739928246, 44.94842920059972],
          [-93.11704739928246, 44.948417810711504],
          [-93.11698570847511, 44.948417810711504],
          [-93.1169843673706, 44.948229877229664],
          [-93.11705008149147, 44.948229877229664],
          [-93.11705008149147, 44.948134011933746],
          [-93.11717212200165, 44.9481330627716],
          [-93.11717212200165, 44.94823747051362],
          [-93.11716005206108, 44.94823747051362],
          [-93.11716005206108, 44.94829062347293],
          [-93.11717614531517, 44.948289674313344],
          [-93.11717614531517, 44.94834947133475],
          [-93.11716139316559, 44.94834947133475],
          [-93.11716139316559, 44.94841686155405],
          [-93.11709836125374, 44.94841686155405],
          [-93.11709836125374, 44.94842920059972],
          [-93.11704739928246, 44.94842920059972],
        ],
      ],
    },
  },
  centroid: {
    type: 'Feature' as const,
    properties: {},
    geometry: { type: 'Point' as const, coordinates: [-93.11710632406175, 44.94831304727364] },
  },
  measurements: {
    computedSquareMeters: 406.1305700005177,
    initialSquareFeet: 4371.548842428572,
    initialWholeSquareFeet: 4372,
    squareFeet: 5070.996657217143,
    squareFeetToLocale: '5,071',
    squaresCount: 51,
    wholeSquareFeet: 5071,
  },
  name: 'Main Roof',
  slope: 'medium' as const,
  isIncluded: true,
  isError: false,
  nameError: null,
};

const ROOFLE_STRUCTURE_2: RoofleStructure = {
  id: 'demo-structure-2',
  geoJsonPolygon: {
    id: 'demo-structure-2',
    type: 'Feature' as const,
    properties: { id: 'demo-structure-2', name: 'Second Roof', slope: 'medium' },
    geometry: {
      type: 'Polygon' as const,
      coordinates: [
        [
          [-93.11675369739532, 44.94808560464429],
          [-93.11675369739532, 44.94802106152815],
          [-93.11684355139732, 44.94802106152815],
          [-93.11684355139732, 44.94808560464429],
          [-93.11675369739532, 44.94808560464429],
        ],
      ],
    },
  },
  centroid: {
    type: 'Feature' as const,
    properties: {},
    geometry: { type: 'Point' as const, coordinates: [-93.11679862439632, 44.94805333308622] },
  },
  measurements: {
    computedSquareMeters: 50.86372594542813,
    initialSquareFeet: 547.4920597039938,
    initialWholeSquareFeet: 548,
    squareFeet: 635.0907892566328,
    squareFeetToLocale: '636',
    squaresCount: 6.66,
    wholeSquareFeet: 636,
  },
  name: 'Second Roof',
  slope: 'medium' as const,
  isIncluded: false,
  isError: false,
  nameError: null,
};

export const DEFAULT_GEODATA_STATE = {
  parcel: null,
  parcelState: ParcelState.None,
  parcelStructures: [],
  structureMetaData: [],
};

export const DEFAULT_QUICK_QUOTE = {
  ...DEFAULT_GEODATA_STATE,
  parcel: PARCEL,
  parcelState: 1,
  centerpoint: [-93.11688, 44.94824] as [number, number],
  initialStructures: [ROOFLE_STRUCTURE_1, ROOFLE_STRUCTURE_2],
  structures: [ROOFLE_STRUCTURE_1, ROOFLE_STRUCTURE_2],
  totalMeasurements: {
    totalSquareFeet: 5071,
    totalSquareFeetToLocale: '5,071',
    totalSquaresCount: 51,
  },
  isLoaded: true,
  isReadyToRender: false,
  structureState: 5,
  hasError: false,
  errorMessage: '',
  regionalApplied: false,
  userDetailsProvided: false,
  userInfo: null,
  marketSlug: '',
  leadData: null,
  mlPrediction: { error: null, loading: false },
  productPriceSettings: null,
};

export const MAP_SETTINGS_QUICK_QUOTE = {
  ...DEFAULT_QUICK_QUOTE,
  parcel: null,
  parcelState: ParcelState.Initial,
  initialStructures: [],
  structures: [],
  totalMeasurements: {
    totalSquareFeet: 0,
    totalSquareFeetToLocale: '',
    totalSquaresCount: 0,
  },
  structureState: StructureState.Initial,
};

export const screenshotSettings = {
  width: 774,
  height: 400,
  zoom: 13,
  minZoom: 13,
  maxZoom: 19,
};

export const modalsForSendingMLPrediction: string[] = [
  ModalTypes.almostDone,
  ModalTypes.repQuotesAlmostDone,
];
