import { ListQuery } from 'types';
import { User } from 'modules/auth/types';
import { Company } from 'modules/company/types';
import { RecurlyAccountOption } from 'modules/admin/modals/types';
import {
  AllIntegrationStatuses,
  BeaconIntegrationStatus,
  EagleViewIntegrationStatus,
  GafIntegrationStatus,
  JobNimbusIntegrationStatus,
  LeapIntegrationStatus,
  MLIntegrationStatus,
  MomntIntegrationStatus,
  SalesRabbitIntegrationStatus,
  OneClickContractorIntegrationStatus,
} from 'modules/developer/types';
import {
  SubscriptionCountry,
  SubscriptionPlan,
  SubscriptionRequestType,
  SubscriptionType,
  SubscriptionTypeGeneral,
} from 'modules/subscription/types';
import { CompanyMarket, CompanyState as CompanyStateWithMarkets } from 'modules/markets/types';

export type RecurlyFiltersType = {
  account_exist?: boolean;
  no_account?: boolean;
};

// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum MapPlatformsFilterType {
  All = 'all',
  GoogleMaps = 'googleMaps',
  Mapbox = 'mapbox',
}

export interface Contractor extends User {
  company: ContractorCompany;
}

export interface ContractorListItem extends User {
  company: ContractorCompanyListItem;
}

export interface ContractorCompanyListItem extends Company {
  integrations: Integrations;
  widgetSetting: { wildcard: string; domains: string[] };
}

export interface ContractorCompany extends ContractorCompanyListItem {
  numberOfContacts: number;
  numberOfContactsPerMonth: number;
  markets: CompanyMarket[];
  states: CompanyStateWithMarkets[];
}

interface Integrations {
  momntStatus: MomntIntegrationStatus;
  salesRabbitStatus: SalesRabbitIntegrationStatus;
  jobNimbusStatus: JobNimbusIntegrationStatus;
  gafStatus: GafIntegrationStatus;
  eagleViewStatus: EagleViewIntegrationStatus;
  mlStatus: MLIntegrationStatus;
  beaconStatus: BeaconIntegrationStatus;
  leapStatus: LeapIntegrationStatus;
  oneClickContractorStatus: OneClickContractorIntegrationStatus;
}

export type IntegrationStatusesKeys = keyof Integrations;
export type IntegrationStatuses = Integrations[keyof Integrations];

export interface AddContractorBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  companyName: string;
  website: string;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  logo?: string;
  markets: string[];
  states: number[];
  hsCompanyId?: string;
  hsContactId?: string;
  domain: string;
  companyPhone: string;
  companyEmail: string;
  isNationalLicenseActive: boolean;
  isGoogleMapsActive: boolean;
  recurlyAccountOption: typeof RecurlyAccountOption[keyof typeof RecurlyAccountOption];
  recurlyAccountId?: string;
  subscriptionType: SubscriptionTypeGeneral;
  subscriptionPlan?: SubscriptionPlan | null;
  subscriptionCountry: SubscriptionCountry;
}

export type UpdateContractorBody = Omit<
  AddContractorBody,
  | 'subscriptionType'
  | 'subscriptionPlan'
  | 'subscriptionCountry'
  | 'subscriptionStartDate'
  | 'subscriptionEndDate'
  | 'isNationalLicenseActive'
  | 'markets'
  | 'states'
>;

export interface FilterQuery {
  markets?: Record<string, boolean>;
  states?: Record<string, boolean>;
  integrations?: Record<string, boolean>;
  recurly?: Record<string, boolean>;
}

export interface AdminDashboardQuery extends ListQuery {
  filter: FilterQuery;
}

export interface AdminDashboardFilters {
  markets: { name: string; selected: boolean }[];
  states: { name: string; selected: boolean }[];
  recurly: string[];
  mapPlatforms: string[];
  subscriptionTypes: string[];
  subscriptionPlans: string[];
  subscriptionRequests: string[];
}

export interface AdminDashboardScores {
  markets: Record<string, number>;
  states: Record<string, number>;
  integrations: Partial<Record<AllIntegrationStatuses, number>>;
  recurly: Partial<Record<keyof RecurlyFiltersType, number>>;
  mapPlatforms: Partial<Record<MapPlatformsFilterType, number>>;
  subscriptionTypes: Partial<Record<SubscriptionType, number> & { all: number; none: number }>;
  subscriptionPlans: Partial<Record<SubscriptionPlan, number> & { all: number; none: number }>;
  subscriptionRequests: Partial<
    Record<SubscriptionRequestType, number> & { all: number; none: number }
  >;
}

export interface ContractorsDropdownOption {
  userId: number;
  companyName: string;
  companyWebsite: string;
  subscriptionType: SubscriptionTypeGeneral;
  subscriptionCountry: SubscriptionCountry;
}
export interface AdminFiltersBody {
  [key: string]: string;
}

export enum CompanyDetailsTabKeys {
  Details = 'Details',
  Integrations = 'Integrations',
  MarketsAndLicense = 'MarketsAndLicense',
}
