import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import styled from 'styled-components';

import { I18nEnum } from 'types';
import InlineButton from 'components/Button/InlineButton';
import IconRestorer from 'components/Icons/IconRestorer';
import Tooltip from 'components/Tooltips/Tooltip';
import { actions, selectors, FormattedNotification } from 'modules/notifications';
import { buildNotifications } from 'modules/notifications/utils';

const PopupContent = ({ closePopup }: { closePopup: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifications = useSelector(selectors.selectNotifications);

  const toggleReadNotification = useCallback(
    (notificationId: number) => dispatch(actions.toggleReadUnreadNotification(notificationId)),
    [dispatch],
  );

  const handleDeleteNotification = useCallback(
    (notificationId: number) => dispatch(actions.deleteNotification(notificationId)),
    [dispatch],
  );

  const handleMarkAllAsRead = useCallback(
    () => dispatch(actions.markNotificationsAsRead()),
    [dispatch],
  );

  const handleNotificationClick = useCallback(
    (notification: FormattedNotification) => {
      navigate(notification.navigateTo);
      closePopup();
    },
    [navigate, closePopup],
  );

  const userNotifications = useMemo(
    () => notifications && buildNotifications(notifications),
    [notifications],
  );

  return (
    <>
      <Header>
        <Title>
          <FormattedMessage id={I18nEnum.Notifications} />
        </Title>
        <CloseIcon size={{ width: 24, height: 24 }} onClick={closePopup}>
          <img src="/images/svg/close-icon.svg" />
        </CloseIcon>
      </Header>
      {userNotifications.length ? (
        <>
          <Panel>
            <Latest>
              <FormattedMessage id={I18nEnum.Latest} />
            </Latest>
            <InlineButton onClick={handleMarkAllAsRead} title={I18nEnum.MarkAllAsRead} />
          </Panel>
          <NotificationSection>
            {userNotifications.map(notification => (
              <Notification
                onClick={() => handleNotificationClick(notification)}
                key={`${notification.id}`}>
                <NotificationIcon src={notification.icon} />
                <NotificationContent>
                  <NotificationBody>
                    <NotificationTitle>{notification.title}</NotificationTitle>
                    <span>{notification.description}</span>

                    <ActionsSection>
                      <Tooltip
                        tooltip={
                          <FormattedMessage
                            id={notification.isRead ? I18nEnum.MarkAsUnread : I18nEnum.MarkAsRead}
                          />
                        }>
                        <img
                          src={`/images/notifications/${
                            notification.isRead ? 'read' : 'unread'
                          }-icon.svg`}
                          onClick={event => {
                            event.stopPropagation();
                            toggleReadNotification(notification.id);
                          }}
                        />
                      </Tooltip>

                      <Tooltip tooltip={<FormattedMessage id={I18nEnum.ClearNotification} />}>
                        <DeleteIcon
                          src="/images/dashboard/delete-icon.svg"
                          onClick={event => {
                            event.stopPropagation();
                            handleDeleteNotification(notification.id);
                          }}
                        />
                      </Tooltip>
                    </ActionsSection>
                  </NotificationBody>
                  <NotificationTime>
                    {format(parseISO(notification.createdAt), 'PP HH:mm')}
                  </NotificationTime>
                </NotificationContent>
              </Notification>
            ))}
          </NotificationSection>
        </>
      ) : (
        <EmptyNotifications>
          <img src="/images/notifications/empty-notifications-icon.svg" />
          <EmptyNotificationsText>
            <FormattedMessage id={I18nEnum.YouHaveNoNotificationsYet} />
          </EmptyNotificationsText>
        </EmptyNotifications>
      )}
    </>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 18px;
  margin: 29px 24px 16px;
`;

const Title = styled(Typography)`
  color: rgba(0, 0, 0, 0.9);
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.45px;
`;

const CloseIcon = styled(IconRestorer)`
  opacity: 0.6;
  padding: 5px;
  cursor: pointer;
`;

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.29px;
`;

const Latest = styled.span`
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
`;

const NotificationSection = styled.div`
  max-height: 320px;
  overflow-y: auto;
`;

const DeleteIcon = styled.img`
  opacity: 0.6;
  visibility: hidden;
`;

const Notification = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 10px 24px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:focus-within {
    background-color: #f9f8f7;
    ${DeleteIcon} {
      visibility: visible;
    }
  }
`;

const NotificationIcon = styled.img`
  margin: 0 -5px;
`;

const NotificationContent = styled.div`
  flex: 1;
`;

const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-right: 50px;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  word-break: break-word;
`;

const NotificationTitle = styled.div`
  font-weight: 500;
`;

const ActionsSection = styled.div`
  display: flex;
  gap: 2px;
  position: absolute;
  right: 24px;
  top: 0;
`;

const NotificationTime = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 4px;
`;

const EmptyNotifications = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const EmptyNotificationsText = styled(Typography)`
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.368px;
  margin-top: 16px;
`;

export default PopupContent;
