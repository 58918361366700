import cloneDeep from 'lodash/cloneDeep';

import { ThunkResult, I18nEnum } from 'types';
import { intl } from 'intl';
import {
  services,
  constants,
  selectors,
  queries,
  RequestProductsBodyType,
  Label,
  CustomProduct,
  UpsertCardView,
} from '.';
import { Line, ColoredLine, RequestProductDifferenceType } from '.';
import { actions as spinnerActions } from 'modules/spinner';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { selectors as userSelectors } from 'modules/auth';
import { getWidgetProductsAPI } from 'modules/widget/services';
import { SupportRequestedType } from 'modules/email/types';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { selectors as marketSelectors, actions as marketActions } from 'modules/markets';
import {
  DefaultFinancingTypes,
  actions as financingActions,
  services as financingServices,
  constants as financingContstants,
  selectors as financingSelectors,
  FinancingType,
} from 'modules/financing';

export const setSelectedProducts = (selectedProducts: (Line | null)[], edited = true) => ({
  type: constants.SET_SELECTED_PRODUCTS,
  selectedProducts,
  edited,
});

export const setInitialSelectedProducts = (initialSelectedProducts: (Line | null)[]) => ({
  type: constants.SET_INITIAL_SELECTED_PRODUCTS,
  initialSelectedProducts,
});

export const setLabels = (labels: string[]) => ({
  type: constants.SET_LABELS,
  labels,
});

export const setUpsertCardView = (upsertCardView: UpsertCardView) => ({
  type: constants.SET_UPSERT_CARD_VIEW,
  upsertCardView,
});

export const setUpsertedProduct = (upsertedProduct: Line | null, upsertedProductInd: number) => ({
  type: constants.SET_UPSERTED_PRODUCT,
  upsertedProduct,
  upsertedProductInd,
});

export const setNewFinancingType = (newFinancingType?: FinancingType) => ({
  type: constants.SET_NEW_FINANCING_TYPE,
  newFinancingType,
});

export const setSelectedColoredLine = (productId: number, coloredLine: ColoredLine) => ({
  type: constants.SET_SELECTED_COLORED_LINE,
  productId,
  coloredLine,
});

export const setIsDisabledSubmitButton = (productId: number, disabled: boolean) => ({
  type: constants.SET_IS_DISABLED_SUBMIT_BUTTON,
  productId,
  disabled,
});

export const setIsLoadingSubmitButton = (productId: number, loading: boolean) => ({
  type: constants.SET_IS_LOADING_SUBMIT_BUTTON,
  productId,
  loading,
});

export const clearWidgetProduct = () => ({
  type: constants.CLEAR_WIDGET_PRODUCT,
});

export const getSelectedProducts =
  (marketSlug: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(getState());
      const { data } = await getWidgetProductsAPI(user.id, marketSlug);
      const selectedProducts = data.concat(new Array(8 - data.length).fill(null));
      const clonedProducts = cloneDeep(selectedProducts);

      dispatch(setSelectedProducts(clonedProducts, false));
      dispatch(setInitialSelectedProducts(clonedProducts));
    } catch (err) {
      dispatch(setSelectedProducts([], false));
    }
    dispatch(spinnerActions.hide());
  };

export const saveSelectedProducts =
  (marketSlug: string, shouldFetchProducs = true): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const selectedProducts = selectors.selectSelectedProducts(state);
    const newFinancingType = selectors.selectNewFinancingType(state);
    const basicFinancing = financingSelectors.selectDefinedBasicFinancing(state);

    try {
      dispatch(spinnerActions.show());

      const user = userSelectors.selectUser(state);
      const _selectedProducts = selectedProducts.reduce((acc: Line[], product) => {
        if (!product) {
          return acc;
        }
        if (newFinancingType) {
          //Currently every financing type (except CLP) has Basic Financing logic
          const isNewFinancingTypeBasic =
            newFinancingType.type !== DefaultFinancingTypes.ContractorLoanPro;
          const isCurrentFinancingTypeCLP =
            basicFinancing.financingType?.type === DefaultFinancingTypes.ContractorLoanPro;

          if (!isNewFinancingTypeBasic) {
            product.showTotal = true;
          }

          if (isNewFinancingTypeBasic && isCurrentFinancingTypeCLP) {
            product.showMonthly = product.loanProductId !== financingContstants.NoFinancing;
            product.loanProductId = financingContstants.NoFinancing;
          } else {
            product.loanProductId = product.showMonthly
              ? financingContstants.BasePackage
              : financingContstants.NoFinancing;
          }
        }
        return [...acc, product];
      }, []);

      await services.saveSelectedProductsAPI({
        userId: user.id,
        marketSlug,
        products: _selectedProducts,
      });
      if (newFinancingType) {
        await financingServices.saveFinancingAPI({
          userId: user.id,
          marketSlug,
          financing: {
            ...basicFinancing,
            financingType: newFinancingType,
          },
        });
        dispatch(marketActions.getUserMarkets());
        await dispatch(financingActions.getFinancing(marketSlug));
        dispatch(setNewFinancingType());
      }

      if (shouldFetchProducs) {
        dispatch(getSelectedProducts(marketSlug));
      }

      dispatch(
        modalActions.openModal(ModalTypes.successModal, {
          subtitle: I18nEnum.TheChangesHaveBeenSaved,
          description: intl.formatMessage({
            id: I18nEnum.AllSelectedProducts,
          }),
        }),
      );
    } catch (err) {
      dispatch(
        modalActions.openModal(ModalTypes.error, {
          action: saveSelectedProducts,
          args: [marketSlug],
          error: err.message,
          requestBody: selectedProducts,
          type: SupportRequestedType.saveProducts,
          title: I18nEnum.UnfortunatelyYourChangesHaveNotBeen,
        }),
      );
    }
    dispatch(spinnerActions.hide());
  };

export const requestProducts =
  (productRequest: RequestProductsBodyType): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());

    dispatch(spinnerActions.show());

    try {
      await services.requestProductsAPI(user.id, productRequest);
      dispatch(messageActions.openMessage(MessageTypes.success, I18nEnum.YourRequestHasBeenSent));
      dispatch(modalActions.closeModal());
    } catch {
      dispatch(
        messageActions.openMessage(MessageTypes.error, I18nEnum.UnfortunatelyTheRequestHasNot),
      );
    }

    dispatch(spinnerActions.hide());
  };

export const getCompanyLabels =
  (changedLabelId?: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const selectedProducts = selectors.selectSelectedProducts(state);
    const edited = selectors.selectEdited(state);

    dispatch(spinnerActions.show());

    try {
      const { data } = await services.getCompanyLabelsAPI(user.id);
      dispatch(setLabels(data));

      if (changedLabelId) {
        const label = data.find(_label => _label.id === changedLabelId);
        dispatch(
          setSelectedProducts(
            selectedProducts.map(line => {
              if (line && line.productLabel && line.productLabel.id === changedLabelId) {
                return { ...line, productLabel: label || null };
              }
              return line;
            }),
            edited,
          ),
        );
      }
    } catch (error) {}
    dispatch(spinnerActions.hide());
  };

export const addCompanyLabel =
  (label: string, callback?: (labelId: number) => void): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    const state = getState();
    const user = userSelectors.selectUser(state);

    try {
      const { data: labelId } = await services.addCompanyLabelAPI(user.id, label);
      dispatch(getCompanyLabels(labelId));
      callback && callback(labelId);
    } catch (error) {}
    dispatch(spinnerActions.hide());
    dispatch(modalActions.closeModal());
  };

export const updateCompanyLabel =
  (label: Label): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    const state = getState();
    const user = userSelectors.selectUser(state);

    try {
      await services.updateCompanyLabelAPI(user.id, label.id, label.name);
      dispatch(getCompanyLabels(label.id));
    } catch (error) {}
    dispatch(spinnerActions.hide());
    dispatch(modalActions.closeModal());
  };

export const deleteCompanyLabel =
  (label: Label): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    const state = getState();
    const user = userSelectors.selectUser(state);

    try {
      await services.deleteCompanyLabelAPI(user.id, label.id);
      dispatch(getCompanyLabels(label.id));
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          I18nEnum.ThisLabelHasBeenDeletedSuccessfully,
        ),
      );
      dispatch(modalActions.closeModal());
    } catch (error) {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          I18nEnum.UnfortunatelyThisLabelHasNotBeenDeleted,
        ),
      );
    }
    dispatch(spinnerActions.hide());
  };

export const upsertCustomProduct =
  (body: CustomProduct, successCallback: () => void): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const selectedMarket = marketSelectors.selectSelectedMarket(state);

    dispatch(spinnerActions.show());

    try {
      await services.upsertCustomProductAPI(user.id, body);
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          !body.id ? I18nEnum.YouProductHasBeenCreated : I18nEnum.TheChangesHaveBeenSaved,
        ),
      );
      queries.invalidateCustomProductsQueries();
      selectedMarket && (await dispatch(getSelectedProducts(selectedMarket.market.slug)));
      successCallback();
    } catch {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          !body.id
            ? I18nEnum.UnfortunatelyTheProduct
            : I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved,
        ),
      );
    }

    dispatch(spinnerActions.hide());
  };

export const deleteCustomProduct =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const selectedMarket = marketSelectors.selectSelectedMarket(state);

    dispatch(spinnerActions.show());

    try {
      await services.deleteCustomProductAPI(user.id, id);
      dispatch(
        messageActions.openMessage(MessageTypes.success, I18nEnum.ProductCardHasBeenDeleted),
      );
      queries.invalidateCustomProductsQueries();
      await dispatch(modalActions.closeModal());
      selectedMarket && (await dispatch(getSelectedProducts(selectedMarket.market.slug)));
    } catch {
      dispatch(messageActions.openMessage(MessageTypes.error, I18nEnum.UnfortunatelyProductCard));
    }

    dispatch(spinnerActions.hide());
  };

export const replaceUpsertedProduct =
  (data: Line, callback?: () => void): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const selectedProducts = selectors.selectSelectedProducts(state);
    const index = selectors.selectUpsertedProductInd(state);
    const copy = cloneDeep(selectedProducts);
    const product = { ...data, order: selectedProducts[index]?.order || data.order };
    copy[index] = product;
    dispatch({
      type: constants.SET_SELECTED_PRODUCTS,
      selectedProducts: copy,
    });
    dispatch(setUpsertedProduct(product, index));
    callback && callback();
  };

export const updateWidgetProduct =
  (
    id: number,
    product: Partial<RequestProductDifferenceType>,
    callback: () => void,
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const selectedMarket = marketSelectors.selectSelectedMarket(state);

    if (!selectedMarket) {
      return;
    }

    try {
      dispatch(spinnerActions.show());
      const { data } = await services.updateWidgetProductAPI(
        user.id,
        id,
        product,
        selectedMarket.market.slug,
      );

      dispatch(
        modalActions.openModal(ModalTypes.successModal, {
          subtitle: I18nEnum.TheChangesHaveBeenSaved,
          description: intl.formatMessage({
            id: I18nEnum.AllYourSettingsConfigurationHaveBeenAppliedToTheWidget,
          }),
        }),
      );
      dispatch(replaceUpsertedProduct(data));
      callback();
    } catch (error) {
      dispatch(
        modalActions.openModal(ModalTypes.error, {
          action: updateWidgetProduct,
          args: [id, product, callback],
          error: error.message,
          requestBody: {
            id,
            product,
          },
          type: SupportRequestedType.saveProductConfigs,
          title: I18nEnum.UnfortunatelyYourChangesHaveNotBeen,
        }),
      );
    }
    dispatch(spinnerActions.hide());
  };

export const resetWidgetProduct =
  (id: number, callback = () => {}): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const selectedMarket = marketSelectors.selectSelectedMarket(state);

    if (!selectedMarket) {
      return;
    }

    try {
      dispatch(spinnerActions.show());
      const { data } = await services.resetWidgetProductAPI(
        user.id,
        id,
        selectedMarket.market.slug,
      );
      dispatch(modalActions.closeModal());
      dispatch(replaceUpsertedProduct(data));
      callback();
    } catch (error) {
      // TODO: add error modal if needed
    }
    dispatch(spinnerActions.hide());
  };

export const applyProductInfo =
  (
    body: {
      productIds: number[];
      productInfo: Partial<RequestProductDifferenceType>;
    },
    hiddenAction = false,
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.ChangesHaveBeenApplied,
    };

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      const selectedMarket = marketSelectors.selectSelectedMarket(state);
      const selectedProducts = selectors.selectSelectedProducts(state);
      const edited = selectors.selectEdited(state);

      await services.applyProductInfoAPI(user.id, body);
      if (selectedMarket) {
        const { data } = await getWidgetProductsAPI(user.id, selectedMarket.market.slug);
        const copy = cloneDeep(selectedProducts).map(product => {
          if (!product || product.price === undefined || !body.productIds.includes(product.id)) {
            return product;
          }
          const serverProduct = data.find(item => item.id === product.id);
          if (!serverProduct) {
            return product;
          }
          return { ...serverProduct, order: product.order };
        });
        dispatch(setSelectedProducts(copy, edited));
      }
      !hiddenAction && dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheChanges;
    }
    dispatch(spinnerActions.hide());
    !hiddenAction && dispatch(messageActions.openMessage(message.type, message.text));
  };

export const openDeleteProductModal =
  (line: Line): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      const { data: notDeletableProductMarkets } = await services.getUndeletableProductMarketsAPI(
        user.id,
        line.id,
      );

      if (!notDeletableProductMarkets) {
        dispatch(
          modalActions.openModal(ModalTypes.warningModal, {
            primaryAction: () => dispatch(deleteCustomProduct(line.id)),
            subtitle: intl.formatMessage(
              { id: I18nEnum.AreYouSureDeleteProductName },
              { productName: line.name },
            ),
            description: intl.formatMessage({ id: I18nEnum.ThisProductCard }),
            primaryBtnText: I18nEnum.Delete,
            secondaryBtnText: I18nEnum.Cancel,
          }),
        );
      } else {
        dispatch(
          modalActions.openModal(ModalTypes.warningModal, {
            primaryAction: () => dispatch(modalActions.closeModal()),
            subtitle: intl.formatMessage({ id: I18nEnum.YouCanNotDeleteThisProduct }),
            description: intl.formatMessage(
              { id: I18nEnum.YouMustHaveAtLeast },
              { productName: line.name, markets: notDeletableProductMarkets },
            ),
            primaryBtnText: I18nEnum.Ok,
            hideSecondaryBtn: true,
          }),
        );
      }
    } catch (err) {}
    dispatch(spinnerActions.hide());
  };

export const updateProductsForMarket =
  (
    marketSlug: string,
    body: Partial<RequestProductDifferenceType>,
    callback: () => void,
    options = { silent: false },
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.updateProductsForMarketAPI(user.id, marketSlug, body);

      const { data } = await getWidgetProductsAPI(user.id, marketSlug);

      if (!options.silent) {
        dispatch(
          modalActions.openModal(ModalTypes.successModal, {
            subtitle: I18nEnum.TheChangesHaveBeenSaved,
            description: intl.formatMessage({
              id: I18nEnum.AllYourSettingsConfigurationHaveBeenAppliedToTheWidget,
            }),
          }),
        );
      }

      dispatch(setSelectedProducts(data, false));
      callback();
    } catch (err) {
      dispatch(
        modalActions.openModal(ModalTypes.error, {
          action: updateProductsForMarket,
          args: [marketSlug, body],
          error: err.message,
          requestBody: body,
          type: SupportRequestedType.saveProductConfigs,
          title: I18nEnum.UnfortunatelyYourChangesHaveNotBeen,
        }),
      );
    }
    dispatch(spinnerActions.hide());
  };
