import { I18nEnum } from 'types';
import { initialQuery as _initialQuery } from 'components/Table';
import {
  OrdersQuery,
  OrderStatuses,
  OrderStatus,
  MeasurementsFormFieldsKey,
  OrdersSettingsConfig,
} from './types';
import { CustomSlopes } from 'modules/repQuotes/types';
import { ORDER_FOR_STATISTICS_KEY } from 'modules/dashboard/constants';
import { GET_USER_MARKETS_KEY } from 'modules/markets/constants';
import { MeasurementsReportType } from 'modules/dashboard/types';
import { intl } from 'intl';

export const DASHBOARD_ORDER_FOR_STATISTICS_KEY = ORDER_FOR_STATISTICS_KEY;
export const MARKETS_USER_MARKETS_KEY = GET_USER_MARKETS_KEY;

const name = 'orders/';

export const ACCOUNTS_KEY = `${name}ACCOUNTS`;
export const TEMPLATES_KEY = `${name}TEMPLATES`;
export const TEMPLATE_KEY = `${name}TEMPLATE`;
export const TEMPLATE_ITEM_DETAILS_KEY = `${name}TEMPLATE_ITEM_DETAILS_KEY`;
export const ORDERS_HISTORY_KEY = `${name}ORDERS_HISTORY`;
export const ORDER_DETAILS_KEY = `${name}ORDER_DETAILS`;
export const TEMPLATES_ITEMS_KEY = `${name}TEMPLATES_ITEMS`;
export const JOBS_KEY = `${name}JOBS_KEY`;
export const FORMULAS_KEY = `${name}FORMULAS`;
export const ASSIGNMENT_MATCH_KEY = `${name}ASSIGNMENT_MATCH`;
export const TAX_RATES = `${name}TAX_RATES`;
export const PRODUCT_LIST = `${name}PRODUCT_LIST`;
export const PRODUCT_VARIATIONS = `${name}PRODUCT_VARIATIONS`;

export const STORAGE_HIDE_MARKETS_WARNING = `${name}STORAGE_HIDE_MARKETS_WARNING`;

export const initialQuery: OrdersQuery = { ..._initialQuery, filter: {} };

export const statusesI18nEnum: Record<OrderStatus, I18nEnum> = {
  [OrderStatuses.processing]: I18nEnum.Processing,
  [OrderStatuses.invoiced]: I18nEnum.Invoiced,
  [OrderStatuses.readyDelivery]: I18nEnum.DeliveryReady,
  [OrderStatuses.readyPickUp]: I18nEnum.PickUpReady,
  [OrderStatuses.pending]: I18nEnum.Pending,
  [OrderStatuses.delivered]: I18nEnum.Delivered,
  [OrderStatuses.draft]: I18nEnum.Draft,
};

export const MEASUREMENTS_FORM_FIELDS = {
  measurementReport: 'measurementReport',
  totalRoofArea: 'totalRoofArea',
  wasteFactor: 'wasteFactor',
  hips: 'hips',
  ridges: 'ridges',
  eaves: 'eaves',
  valleys: 'valleys',
  rakes: 'rakes',
  ridgeCap: 'ridgeCap',
  starter: 'starter',
  underlayment: 'underlayment',
  dripEdge: 'dripEdge',
  stepFlashing: 'stepFlashing',
  flashing: 'flashing',
  facets: 'facets',
  pitch: 'pitch',
  parapets: 'parapets',
  kitchenVent: 'kitchenVent',
  bathVent: 'bathVent',
  plumbingStack: 'plumbingStack',
  staticVent: 'staticVent',
  ridgeVent: 'ridgeVent',
  chimneys: 'chimneys',
  skylights: 'skylights',
  isIceWaterSettingsOn: 'isIceWaterSettingsOn',
  isIceWaterValleysOn: 'isIceWaterValleysOn',
  iceWaterValleys: 'iceWaterValleys',
  isIceWaterEavesOn: 'isIceWaterEavesOn',
  iceWaterEaves: 'iceWaterEaves',
  isHeadwallFlashingsOn: 'isHeadwallFlashingsOn',
  headwallFlashings: 'headwallFlashings',
  isIceWaterStepFlashingsOn: 'isIceWaterStepFlashingsOn',
  iceWaterStepFlashings: 'iceWaterStepFlashings',
  isIceWaterRakesOn: 'isIceWaterRakesOn',
  iceWaterRakes: 'iceWaterRakes',
  isPenetrationsOn: 'isPenetrationsOn',
  penetrations: 'penetrations',
  isDeckingAndSheathingOn: 'isDeckingAndSheathingOn',
  hasIceWaterToggleChange: 'hasIceWaterToggleChange',
  decking: 'decking',
};

export const measurementsToTitleMap: {
  [k in MeasurementsFormFieldsKey]: I18nEnum;
} = {
  [MEASUREMENTS_FORM_FIELDS.bathVent]: I18nEnum.BathVent,
  [MEASUREMENTS_FORM_FIELDS.chimneys]: I18nEnum.Chimneys,
  [MEASUREMENTS_FORM_FIELDS.decking]: I18nEnum.Decking,
  [MEASUREMENTS_FORM_FIELDS.dripEdge]: I18nEnum.DripEdge,
  [MEASUREMENTS_FORM_FIELDS.eaves]: I18nEnum.Eaves,
  [MEASUREMENTS_FORM_FIELDS.facets]: I18nEnum.Facets,
  [MEASUREMENTS_FORM_FIELDS.flashing]: I18nEnum.Flashing,
  [MEASUREMENTS_FORM_FIELDS.hips]: I18nEnum.Hips,
  [MEASUREMENTS_FORM_FIELDS.iceWaterEaves]: I18nEnum.IceAndWaterEaves,
  [MEASUREMENTS_FORM_FIELDS.headwallFlashings]: I18nEnum.IceAndWaterHeadwallFlashings,
  [MEASUREMENTS_FORM_FIELDS.penetrations]: I18nEnum.IceAndWaterPenetrations,
  [MEASUREMENTS_FORM_FIELDS.iceWaterRakes]: I18nEnum.IceAndWaterRakes,
  [MEASUREMENTS_FORM_FIELDS.iceWaterStepFlashings]: I18nEnum.IceAndWaterStepFlashings,
  [MEASUREMENTS_FORM_FIELDS.iceWaterValleys]: I18nEnum.IceAndWaterValleys,
  [MEASUREMENTS_FORM_FIELDS.kitchenVent]: I18nEnum.KitchenVent,
  [MEASUREMENTS_FORM_FIELDS.parapets]: I18nEnum.Parapets,
  [MEASUREMENTS_FORM_FIELDS.pitch]: I18nEnum.Pitch,
  [MEASUREMENTS_FORM_FIELDS.plumbingStack]: I18nEnum.PlumbingStack,
  [MEASUREMENTS_FORM_FIELDS.rakes]: I18nEnum.Rakes,
  [MEASUREMENTS_FORM_FIELDS.ridgeCap]: I18nEnum.RidgeCap,
  [MEASUREMENTS_FORM_FIELDS.ridges]: I18nEnum.Ridges,
  [MEASUREMENTS_FORM_FIELDS.ridgeVent]: I18nEnum.RidgeVent,
  [MEASUREMENTS_FORM_FIELDS.skylights]: I18nEnum.Skylights,
  [MEASUREMENTS_FORM_FIELDS.starter]: I18nEnum.Starter,
  [MEASUREMENTS_FORM_FIELDS.staticVent]: I18nEnum.StaticVent,
  [MEASUREMENTS_FORM_FIELDS.stepFlashing]: I18nEnum.StepFlashing,
  [MEASUREMENTS_FORM_FIELDS.totalRoofArea]: I18nEnum.TotalRoofArea,
  [MEASUREMENTS_FORM_FIELDS.underlayment]: I18nEnum.Underlayment,
  [MEASUREMENTS_FORM_FIELDS.valleys]: I18nEnum.Valleys,
  [MEASUREMENTS_FORM_FIELDS.wasteFactor]: I18nEnum.WasteFactor,
};

export const measurementsInitialValues: {
  [k in MeasurementsFormFieldsKey]: number;
} = {
  [MEASUREMENTS_FORM_FIELDS.bathVent]: 3,
  [MEASUREMENTS_FORM_FIELDS.chimneys]: 1,
  [MEASUREMENTS_FORM_FIELDS.dripEdge]: 300,
  [MEASUREMENTS_FORM_FIELDS.eaves]: 300,
  [MEASUREMENTS_FORM_FIELDS.facets]: 20,
  [MEASUREMENTS_FORM_FIELDS.flashing]: 500,
  [MEASUREMENTS_FORM_FIELDS.headwallFlashings]: 500,
  [MEASUREMENTS_FORM_FIELDS.hips]: 250,
  [MEASUREMENTS_FORM_FIELDS.iceWaterEaves]: 300,
  [MEASUREMENTS_FORM_FIELDS.iceWaterRakes]: 100,
  [MEASUREMENTS_FORM_FIELDS.iceWaterValleys]: 150,
  [MEASUREMENTS_FORM_FIELDS.iceWaterStepFlashings]: 150,
  [MEASUREMENTS_FORM_FIELDS.kitchenVent]: 1,
  [MEASUREMENTS_FORM_FIELDS.decking]: 10,
  [MEASUREMENTS_FORM_FIELDS.parapets]: 200,
  [MEASUREMENTS_FORM_FIELDS.penetrations]: 10,
  [MEASUREMENTS_FORM_FIELDS.pitch]: 1.158,
  [MEASUREMENTS_FORM_FIELDS.plumbingStack]: 2,
  [MEASUREMENTS_FORM_FIELDS.rakes]: 50,
  [MEASUREMENTS_FORM_FIELDS.ridgeCap]: 100,
  [MEASUREMENTS_FORM_FIELDS.ridges]: 100,
  [MEASUREMENTS_FORM_FIELDS.ridgeVent]: 5,
  [MEASUREMENTS_FORM_FIELDS.skylights]: 1,
  [MEASUREMENTS_FORM_FIELDS.starter]: 300,
  [MEASUREMENTS_FORM_FIELDS.staticVent]: 5,
  [MEASUREMENTS_FORM_FIELDS.stepFlashing]: 150,
  [MEASUREMENTS_FORM_FIELDS.totalRoofArea]: 2000,
  [MEASUREMENTS_FORM_FIELDS.underlayment]: 1000,
  [MEASUREMENTS_FORM_FIELDS.valleys]: 150,
  [MEASUREMENTS_FORM_FIELDS.wasteFactor]: 10,
};

export const pitchOptions = Object.keys(CustomSlopes).map(pitch => ({
  label: pitch,
  value: CustomSlopes[pitch].multiplier,
}));

export const imgUrl = 'https://beaconproplus.com';

export const ordersQueriesKeys = [
  ACCOUNTS_KEY,
  TEMPLATES_KEY,
  TEMPLATE_KEY,
  TEMPLATE_ITEM_DETAILS_KEY,
  ORDERS_HISTORY_KEY,
  ORDER_DETAILS_KEY,
  DASHBOARD_ORDER_FOR_STATISTICS_KEY,
  MARKETS_USER_MARKETS_KEY,
  TEMPLATES_ITEMS_KEY,
  JOBS_KEY,
  FORMULAS_KEY,
  ASSIGNMENT_MATCH_KEY,
];

export const measurementsDependingFields: {
  [k in MeasurementsFormFieldsKey]: MeasurementsFormFieldsKey[];
} = {
  [MEASUREMENTS_FORM_FIELDS.isIceWaterSettingsOn]: [
    MEASUREMENTS_FORM_FIELDS.iceWaterValleys,
    MEASUREMENTS_FORM_FIELDS.iceWaterEaves,
    MEASUREMENTS_FORM_FIELDS.iceWaterRakes,
    MEASUREMENTS_FORM_FIELDS.iceWaterStepFlashings,
    MEASUREMENTS_FORM_FIELDS.headwallFlashings,
    MEASUREMENTS_FORM_FIELDS.penetrations,
  ],
  [MEASUREMENTS_FORM_FIELDS.isDeckingAndSheathingOn]: [MEASUREMENTS_FORM_FIELDS.decking],
  [MEASUREMENTS_FORM_FIELDS.isIceWaterValleysOn]: [MEASUREMENTS_FORM_FIELDS.iceWaterValleys],
  [MEASUREMENTS_FORM_FIELDS.isIceWaterEavesOn]: [MEASUREMENTS_FORM_FIELDS.iceWaterEaves],
  [MEASUREMENTS_FORM_FIELDS.isIceWaterRakesOn]: [MEASUREMENTS_FORM_FIELDS.iceWaterRakes],
  [MEASUREMENTS_FORM_FIELDS.isHeadwallFlashingsOn]: [MEASUREMENTS_FORM_FIELDS.headwallFlashings],
  [MEASUREMENTS_FORM_FIELDS.isIceWaterStepFlashingsOn]: [
    MEASUREMENTS_FORM_FIELDS.iceWaterStepFlashings,
  ],
  [MEASUREMENTS_FORM_FIELDS.isPenetrationsOn]: [MEASUREMENTS_FORM_FIELDS.penetrations],
};

export const deletedMeasurementsFormFieldsBackup = {
  roofAndWallsIntersection: {
    value: [
      '(',
      MEASUREMENTS_FORM_FIELDS.headwallFlashings,
      '+',
      MEASUREMENTS_FORM_FIELDS.iceWaterStepFlashings,
      ')',
    ],
    preview: `(${intl.formatMessage({
      id: measurementsToTitleMap[MEASUREMENTS_FORM_FIELDS.headwallFlashings],
    })}+${intl.formatMessage({
      id: measurementsToTitleMap[MEASUREMENTS_FORM_FIELDS.iceWaterStepFlashings],
    })})`,
    name: 'Roof & Wall Intersection',
  },
} as const;

export const ReportTypeToTitleMap = {
  [MeasurementsReportType.EagleViewBidPerfect]: I18nEnum.EagleViewBidPerfect,
  [MeasurementsReportType.EagleViewPremiumCommercial]: I18nEnum.EagleViewPremiumCommercialReport,
  [MeasurementsReportType.EagleViewPremiumResidential]: I18nEnum.EagleViewPremiumResidentialReport,
  [MeasurementsReportType.GAFMeasurements]: I18nEnum.GAFQuickMeasure,
} as const;

export const DEFAULT_ORDERS_SETTINGS_CONFIG: OrdersSettingsConfig = {
  beaconAvailable: true,
};

export const CANADA_ORDERS_SETTINGS_CONFIG: OrdersSettingsConfig = {
  beaconAvailable: false,
};
