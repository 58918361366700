import { Feature, MultiPolygon, Point, Polygon, Properties, Position } from '@turf/helpers';

import { CustomSlopeType, QuickQuoteProductPriceSettings } from 'modules/repQuotes/types';
import {
  CommunicationOptions,
  Statistics,
  StructureWasteFactor,
  StructureWasteFactorPartialSuggested,
} from 'modules/dashboard/types';
import { SearchAddressFeature } from 'modules/search';

export type GeoJsonPolygon = GeoJSON.Feature<Polygon | MultiPolygon, Properties>;

export enum ParcelState {
  None,
  Found,
  NotFound,
  Error,
  Initial,
  NotIncluded,
  DeepLinkAddressError,
}

export enum StructureState {
  None,
  Found,
  NotFound,
  TooMany,
  EditedNone,
  EditedSome,
  Error,
  Initial,
}

export interface QuickQuoteState {
  parcel: GeoJsonPolygon | null;
  parcelState: ParcelState;
  structureMetaData: StructureMetaDataResponse;
  centerpoint: [number, number] | null;
  initialStructures: RoofleStructure[];
  parcelStructures: GeoJsonPolygon[];
  structures: RoofleStructure[];
  totalMeasurements: TotalMeasurements | null;
  isLoaded: boolean;
  isReadyToRender: boolean;
  structureState: StructureState;
  hasError: boolean;
  errorMessage: string | null;
  regionalApplied: boolean;
  userDetailsProvided: boolean;
  userInfo: QuickQuoteUserInfo | null;
  marketSlug?: string;
  centerpointState?: string;
  leadData: Statistics | null;
  mlPrediction: MLPrediction;
  productPriceSettings: QuickQuoteProductPriceSettings | null;
}

export type QuickQuoteUserInfo = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  communicationOptions?: CommunicationOptions;
};

export interface RoofleStructure {
  id: string;
  geoJsonPolygon: GeoJsonPolygon;
  centroid: Feature<Point, Properties> | undefined;
  measurements: PolygonMeasurements;
  name: string;
  previousName?: string;
  slope: StructureSlope;
  isIncluded?: boolean;
  isError: boolean;
  nameError: string | null;
  structureMetaData?: StructureMetaData;
  roofComplexity?: string;
  wasteFactor?: StructureWasteFactor;
  customTotalSquareFeet?: number;
}

export interface CommonStructureInformation {
  id: string;
  squareFeet: number;
  initialSquareFeet: number;
  wholeSquareFeet: number;
  slope: StructureSlope;
  name: string;
  wasteFactor?: StructureWasteFactor | StructureWasteFactorPartialSuggested;
  isIncluded?: boolean;
  roofComplexity?: string;
}

export interface ReportStructureInformation {
  name: string;
  id: string;
  squareFeet: number;
  slope: StructureSlope;
  wasteFactor: StructureWasteFactorPartialSuggested;
}

export type StructureInformation = CommonStructureInformation | ReportStructureInformation;

export type MLWasteFactor = {
  'Asphalt Shingle': number;
  Synthetic: number;
  Metal: number;
  Wood: number;
  Tile: number;
  Slate: number;
};

export interface TotalMeasurements {
  totalSquareFeet: number;
  totalSquareFeetToLocale: string;
  totalSquaresCount: number;
}

export interface PolygonMeasurements {
  computedSquareMeters: number;
  initialSquareFeet: number;
  initialWholeSquareFeet: number;
  squareFeet: number;
  squareFeetToLocale: string;
  squaresCount: number;
  wholeSquareFeet: number;
}

export interface StructureMetaData {
  averageSlope: number;
  averagePitch: string;
  averagePitchName: string;
  maxHeight: number;
  meanHeight: number;
}

export type StructureSlope = 'flat' | 'shallow' | 'medium' | 'steep' | CustomSlopeType;
export enum StructureSlopeEnum {
  flat = 'flat',
  shallow = 'shallow',
  medium = 'medium',
  steep = 'steep',
  custom = 'custom',
}

export interface PastAddressesState {
  address: string;
  search: {
    addressFeature: SearchAddressFeature;
  };
  quickQuote: QuickQuoteState;
}

export type MapBoxInstanceCreateMap = {
  centerpoint: [number, number];
  parcel: GeoJsonPolygon | null;
};

export type StructureMetaDataResponse = GeoJSON.Feature<
  Polygon | MultiPolygon,
  StructureMetaDataProperties
>[];

export interface StructureMetaDataProperties {
  Address_No: string;
  Address: string;
  Area_SqFt: string;
  bld_UUID: string;
  City: string;
  County: string;
  CTFIPS: string;
  GrossArea: string;
  HAG: string;
  LAG: string;
  Latitude: string;
  lbcs_activity_desc: string | null;
  lbcs_activity: string | null;
  lbcs_function_desc: string | null;
  lbcs_function: null;
  lbcs_ownership_desc: string | null;
  lbcs_ownership: string | null;
  lbcs_site_desc: string | null;
  lbcs_site: string | null;
  lbcs_structure_desc: string | null;
  lbcs_structure: string | null;
  ll_uuid: string;
  Location: [number, number];
  Longitude: string;
  MaxHeight: string;
  MaxObjectH: string;
  MeanElev: string;
  MeanHeight: string;
  MaxSlope: string;
  MeanSlope: string;
  ModeSlope: string;
  Shape_Area: string;
  Shape_Length: string;
  Source: string;
  SourceDate: string;
  State: string;
  Stories: string;
  STPostal: string;
  str_UUID: string;
  Street: string;
  Volume: string;
  ZIP: string;
}

export type MLPredictionDataType = {
  [structureId: string]: {
    roofComplexity?: string;
    wasteFactor?: {
      'Asphalt Shingle': number;
      Synthetic: number;
      Metal: number;
      Wood: number;
      Tile: number;
      Slate: number;
    };
  } | null;
};

export type MLPrediction = {
  eventId?: string;
  error: unknown;
  loading?: boolean;
  MLPredictionData?: MLPredictionDataType;
};

export type MLPredictionResponse = {
  structures: MLPredictionDataType;
  eventId: string;
};

export enum WasteFactorTypeEnum {
  Suggested = 'suggested',
  Custom = 'custom',
  Default = 'default',
  None = 'none',
}

export type QuickQuoteMapsCallbacks = {
  onPolygonSelection: (isSelected: boolean) => void;
  onVertexSelected: () => void;
  onPolygonComplete: () => void;
  onMapClick: () => void;
};

export enum MapProviderType {
  GoogleMaps = 'googleMaps',
  Mapbox = 'mapbox',
}

type StructurePositionsType = Position[][] | Position[][][];

export type StructureCoordinates = Record<string, StructurePositionsType>;
