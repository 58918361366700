import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { I18nEnum } from 'types';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { MessageTypes, actions as messageActions } from 'modules/message';
import { actions as modalActions } from 'modules/modal';
import { services, constants, Company, CompanySmtpServer } from 'modules/company';
import { selectors as userSelectors } from 'modules/auth';
import { queryClient } from 'modules/reactQuery';
import { invalidateEmailSettings } from 'modules/emailsSettings/mutations';

export const invalidateCompany = () => queryClient.invalidateQueries([constants.COMPANY_KEY]);

export const useUpdateCompany = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{
    company: Company;
    params?: { successMessage?: string; errorMessage?: string; specialErrorMessage?: string };
  }>({
    mutationFn: async variables => {
      return services.updateCompanyApi(user.id, variables.company);
    },
    onSuccess: (_, { params = {} }) => {
      invalidateCompany();
      dispatch(modalActions.closeModal());
      if (params.successMessage) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.success,
            intl.formatMessage({ id: params.successMessage }),
          ),
        );
      }
    },
    onError: (err, { params = {} }) => {
      if (params.errorMessage) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.error,
            intl.formatMessage({
              id: err.code === 403 ? params.specialErrorMessage : params.errorMessage,
            }),
          ),
        );
      }
    },
  });
};

export const useUpdateCompanyEmail = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<Company & { companySmtpServer?: CompanySmtpServer }>({
    mutationFn: async variables => {
      return services.updateCompanyApi(user.id, variables);
    },
    onSuccess: () => {
      invalidateCompany();
      invalidateEmailSettings();
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.TheChangesHaveBeenSaved }),
        ),
      );
    },
    onError: error => {
      if (error.code === 400) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.error,
            intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
          ),
        );
      }
    },
  });
};
