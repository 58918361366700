import { intl } from 'intl';

import { I18nEnum } from 'types';
import {
  AllIntegrationStatuses,
  BeaconIntegrationStatus,
  EagleViewIntegrationStatus,
  GafIntegrationStatus,
  GroupedIntegrationStatus,
  MLIntegrationStatus,
  MomntIntegrationStatus,
  SalesRabbitIntegrationStatus,
  JobNimbusIntegrationStatus,
  LeapIntegrationStatus,
  OneClickContractorIntegrationStatus,
} from 'modules/developer/types';
import { initialQuery as _initialQuery } from 'components/Table';
import { OptionType, TreeData } from 'components/Inputs/Select';
import { getOptionKeyToLabelMap } from 'components/Inputs/Select/tree-data.helper';
import { MapPlatformsFilterType, AdminDashboardFilters, AdminDashboardScores } from './types';
import {
  subscriptionPlanToTranslationMap,
  subscriptionRequestToTranslationMap,
  subscriptionTypeToTranslationMap,
} from 'modules/subscription/constants';
import {
  ContractorFormKey,
  MapPlatformOption,
  RecurlyAccountOption,
  SubscriptionFormKey,
} from './modals/types';
import { ValidatableFieldsToSectionTitleMap } from 'modules/widget/types';

const name = 'admin/';

export const CONTRACTORS_KEY = `${name}CONTRACTORS`;
export const CONTRACTORS_FILTER_KEY = `${name}CONTRACTORS_FILTER`;
export const RECURLY_ACCOUNTS_KEY = `${name}RECURLY_ACCOUNTS`;
export const COMPANY_RECURLY_ACCOUNT_KEY = `${name}COMPANY_RECURLY_ACCOUNT`;
export const CONTRACTOR_KEY = `${name}CONTRACTOR`;
export const CONTRACTORS_FOR_DROPDOWN_KEY = `${name}CONTRACTORS_FOR_DROPDOWN`;

export const adminDashboardKeys = [
  CONTRACTORS_KEY,
  CONTRACTORS_FILTER_KEY,
  CONTRACTORS_FOR_DROPDOWN_KEY,
];

export const contractorDependentKeys = [COMPANY_RECURLY_ACCOUNT_KEY, CONTRACTOR_KEY];

export const ADMIN_DASHBOARD_ROOT_ELEMENT_ID = 'admin-dashboard';

export const initialQuery = { ..._initialQuery, filter: {} };

export const mapPlatformOptions = [
  { id: MapPlatformOption.mapbox, text: I18nEnum.Mapbox },
  { id: MapPlatformOption.googleMaps, text: I18nEnum.GoogleMaps },
];

export const recurlyAccountOptions = [
  { id: RecurlyAccountOption.existing, text: I18nEnum.Existing },
  { id: RecurlyAccountOption.new, text: I18nEnum.New },
];

export const integrationOptions: TreeData<OptionType> = {
  node: {
    label: I18nEnum.All,
    value: GroupedIntegrationStatus.All,
  },
  children: [
    {
      node: {
        label: I18nEnum.Beacon,
        value: GroupedIntegrationStatus.Beacon,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: BeaconIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: BeaconIntegrationStatus.Active,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.EagleView,
        value: GroupedIntegrationStatus.EagleView,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: EagleViewIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: EagleViewIntegrationStatus.Active,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.GAF,
        value: GroupedIntegrationStatus.Gaf,
      },
      children: [
        {
          node: {
            label: I18nEnum.TrialStatus,
            value: GafIntegrationStatus.Trial,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: GafIntegrationStatus.Active,
          },
        },
        {
          node: {
            label: I18nEnum.Inactive,
            value: GafIntegrationStatus.Inactive,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.JobNimbus,
        value: GroupedIntegrationStatus.JobNimbus,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: JobNimbusIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: JobNimbusIntegrationStatus.Active,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.Leap,
        value: GroupedIntegrationStatus.Leap,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: LeapIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: LeapIntegrationStatus.Active,
          },
        },
        {
          node: {
            label: I18nEnum.Inactive,
            value: LeapIntegrationStatus.Inactive,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.ML,
        value: GroupedIntegrationStatus.ML,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: MLIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: MLIntegrationStatus.Active,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.Momnt,
        value: GroupedIntegrationStatus.Momnt,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: MomntIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.VerificationHold,
            value: MomntIntegrationStatus.VerificationHold,
          },
        },
        {
          node: {
            label: I18nEnum.InIntake,
            value: MomntIntegrationStatus.InIntake,
          },
        },
        {
          node: {
            label: I18nEnum.IntakePartialActive,
            value: MomntIntegrationStatus.IntakePartialActive,
          },
        },
        {
          node: {
            label: I18nEnum.IntakeDecline,
            value: MomntIntegrationStatus.IntakeDecline,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: MomntIntegrationStatus.Active,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.OneClickContractor,
        value: GroupedIntegrationStatus.OneClickContractor,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: OneClickContractorIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: OneClickContractorIntegrationStatus.Active,
          },
        },
        {
          node: {
            label: I18nEnum.Inactive,
            value: OneClickContractorIntegrationStatus.Inactive,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.SalesRabbit,
        value: GroupedIntegrationStatus.SalesRabbit,
      },
      children: [
        {
          node: {
            label: I18nEnum.NoIntegration,
            value: SalesRabbitIntegrationStatus.NoIntegration,
          },
        },
        {
          node: {
            label: I18nEnum.Cancelled,
            value: SalesRabbitIntegrationStatus.Cancelled,
          },
        },
        {
          node: {
            label: I18nEnum.Active,
            value: SalesRabbitIntegrationStatus.Active,
          },
        },
      ],
    },
    {
      node: {
        label: I18nEnum.None,
        value: GroupedIntegrationStatus.None,
      },
    },
  ],
};

export const recurlyOptions: OptionType[] = [
  {
    label: intl.formatMessage({ id: I18nEnum.AccountExist }),
    value: 'account_exist',
  },
  {
    label: intl.formatMessage({ id: I18nEnum.NoAccount }),
    value: 'no_account',
  },
];

export const mapPlatformsOptions: OptionType[] = [
  {
    label: intl.formatMessage({ id: I18nEnum.GoogleMaps }),
    value: MapPlatformsFilterType.GoogleMaps,
  },
  {
    label: intl.formatMessage({ id: I18nEnum.Mapbox }),
    value: MapPlatformsFilterType.Mapbox,
  },
];

export const getOptionsWithNoneOption = (
  translationMap: Record<string, I18nEnum>,
): OptionType[] => [
  ...Object.keys(translationMap).map(key => ({
    label: intl.formatMessage({ id: translationMap[key] }),
    value: key,
  })),
  {
    label: intl.formatMessage({ id: I18nEnum.None }),
    value: 'none',
  },
];

export const subscriptionTypesOptions: OptionType[] = Object.keys(
  subscriptionTypeToTranslationMap,
).map(key => ({
  label: intl.formatMessage({ id: subscriptionTypeToTranslationMap[key] }),
  value: key,
}));

export const subscriptionPlansOptions: OptionType[] = getOptionsWithNoneOption(
  subscriptionPlanToTranslationMap,
);

export const subscriptionRequestOptions: OptionType[] = getOptionsWithNoneOption(
  subscriptionRequestToTranslationMap,
);

export const integrationStatusToI18nIdMap = getOptionKeyToLabelMap(integrationOptions);

export const hiddenIntegrationStatusMap: Partial<Record<AllIntegrationStatuses, boolean>> = {
  [MomntIntegrationStatus.NoIntegration]: true,
  [SalesRabbitIntegrationStatus.NoIntegration]: true,
  [JobNimbusIntegrationStatus.NoIntegration]: true,
  [LeapIntegrationStatus.NoIntegration]: true,
  [EagleViewIntegrationStatus.NoIntegration]: true,
  [MLIntegrationStatus.NoIntegration]: true,
  [BeaconIntegrationStatus.NoIntegration]: true,
};

const imgPath = '/images/admin-dashboard-statuses/';

export const integrationStatusI18nToImageMap = {
  [I18nEnum.NoIntegration]: `${imgPath}no-integration-icon.svg`,
  [I18nEnum.Active]: '/images/svg/presence-mark-icon.svg',
  [I18nEnum.TrialStatus]: `${imgPath}trial-icon.svg`,
  [I18nEnum.Inactive]: '/images/svg/absence-mark-icon.svg',
  [I18nEnum.VerificationHold]: `${imgPath}verification-hold-icon.svg`,
  [I18nEnum.InIntake]: `${imgPath}intake-icon.svg`,
  [I18nEnum.IntakePartialActive]: `${imgPath}intake-partial-active-icon.svg`,
  [I18nEnum.IntakeDecline]: `${imgPath}cancelled-icon.svg`,
  [I18nEnum.Cancelled]: `${imgPath}cancelled-icon.svg`,
};

export const initialFiltersValues: AdminDashboardFilters = {
  markets: [],
  states: [],
  recurly: [],
  mapPlatforms: [],
  subscriptionTypes: [],
  subscriptionPlans: [],
  subscriptionRequests: [],
};

export const initialScoresValues: AdminDashboardScores = {
  integrations: {},
  recurly: {},
  markets: {},
  states: {},
  mapPlatforms: {},
  subscriptionTypes: {},
  subscriptionPlans: {},
  subscriptionRequests: {},
};

export const contractorFieldsToSectionTitleMap: ValidatableFieldsToSectionTitleMap = {
  // CompanyInfo
  [ContractorFormKey.CompanyName]: I18nEnum.CompanyInfo,
  [ContractorFormKey.HsCompanyId]: I18nEnum.CompanyInfo,
  [ContractorFormKey.Website]: I18nEnum.CompanyInfo,
  [ContractorFormKey.CompanyEmail]: I18nEnum.CompanyInfo,
  [ContractorFormKey.CompanyPhone]: I18nEnum.CompanyInfo,
  [ContractorFormKey.Domain]: I18nEnum.CompanyInfo,
  [ContractorFormKey.Logo]: I18nEnum.CompanyInfo,
  [ContractorFormKey.MapPlatform]: I18nEnum.CompanyInfo,
  // Recurly Account
  [ContractorFormKey.RecurlyAccountType]: I18nEnum.RecurlyAccount,
  [ContractorFormKey.RecurlyAccountId]: I18nEnum.RecurlyAccount,
  // Company Owner
  [ContractorFormKey.FirstName]: I18nEnum.CompanyOwner,
  [ContractorFormKey.LastName]: I18nEnum.CompanyOwner,
  [ContractorFormKey.Email]: I18nEnum.CompanyOwner,
  [ContractorFormKey.Phone]: I18nEnum.CompanyOwner,
  [ContractorFormKey.JobTitle]: I18nEnum.CompanyOwner,
  // Subscription
  [SubscriptionFormKey.Type]: I18nEnum.CompanySubscription,
  [SubscriptionFormKey.Country]: I18nEnum.CompanySubscription,
  [SubscriptionFormKey.StartDate]: I18nEnum.CompanySubscription,
  [SubscriptionFormKey.EndDate]: I18nEnum.CompanySubscription,
  [SubscriptionFormKey.Markets]: I18nEnum.CompanySubscription,
  [SubscriptionFormKey.States]: I18nEnum.CompanySubscription,
} as const;
