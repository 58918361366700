import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const useDeepCompareEffect = (callback: EffectCallback, dependencies: DependencyList) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffect;
