import api from 'services/api';
import {
  Creds,
  EagleviewConnection,
  IntegrationType,
  JobNimbusIntegration,
  LeapIntegration,
  OneClickContractorIntegration,
  WebhooksType,
} from './types';

export const getCompanyWebhooksAPI = (userId: number) => api.get(`user/${userId}/company/webhooks`);
export const updateCompanyWebhooksAPI = (userId: number, body: Partial<WebhooksType>) =>
  api.patch(`user/${userId}/company/webhooks`, body);

export const getCompanyIntegrationAPI = (userId: number) =>
  api.get(`user/${userId}/company/integration`);
export const updateCompanyIntegrationAPI = (userId: number, body: Partial<IntegrationType>) =>
  api.patch(`user/${userId}/company/integration`, body);

export const getCompanyJobNimbusIntegrationAPI = (userId: number) =>
  api.get(`user/${userId}/company/jobnimbus-integration`);
export const updateCompanyJobNimbusIntegrationAPI = (
  userId: number,
  body: Partial<JobNimbusIntegration>,
) => api.patch(`user/${userId}/company/jobnimbus-integration`, body);

export const getCompanyLeapIntegrationAPI = (userId: number) =>
  api.get(`user/${userId}/company/leap-integration`);
export const updateCompanyLeapIntegrationAPI = (userId: number, body: Partial<LeapIntegration>) =>
  api.patch(`user/${userId}/company/leap-integration`, body);

export const getCompanyOneClickContractorIntegrationAPI = (userId: number) =>
  api.get(`user/${userId}/company/one-click-contractor-integration`);
export const updateCompanyOneClickContractorIntegrationAPI = (
  userId: number,
  body: Partial<OneClickContractorIntegration>,
) => api.patch(`user/${userId}/company/one-click-contractor-integration`, body);

export const upsertEagleViewConnectionAPI = (
  userId: number,
  body: { id?: number; oauthCode: string; marketIds?: string | number[] },
) => api.post(`user/${userId}/eagleview/connection`, body);
export const getEagleViewConnectionsAPI = (userId: number) =>
  api.get(`user/${userId}/eagleview/connections`);
export const deleteEagleviewConnectionAPI = (userId: number, ids: number[]) =>
  api.delete(`user/${userId}/eagleview/connection`, { data: { ids } });
export const editEagleviewConnectionAPI = (userId: number, body: Partial<EagleviewConnection>[]) =>
  api.patch(`user/${userId}/eagleview/connections`, body);

export const getBeaconConnectionAPI = (userId: number) =>
  api.get(`user/${userId}/beacon/connection`);
export const upsertBeaconConnectionAPI = (userId: number, body: Creds) =>
  api.post(`user/${userId}/beacon/connection`, body);
export const deleteBeaconConnectionAPI = (userId: number) =>
  api.delete(`user/${userId}/beacon/connection`);
