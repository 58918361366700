import { UserRoutes } from 'types';

export interface NotificationsState {
  notifications: Notification[];
}

type DependentField<T extends NotificationType> = T extends typeof NotificationTypes.Markets
  ? MarketsNotificationBody
  : T extends typeof NotificationTypes.Test
  ? Record<string, string>
  : never;

export interface INotification<T extends NotificationType> {
  id: number;
  body: DependentField<T>;
  createdAt: string;
  isRead: boolean;
  type: T;
  deleted: boolean;
  deleteDate: string;
}

interface IFormattedNotification<T extends NotificationType> extends INotification<T> {
  title: string;
  description: string;
  icon: string;
  navigateTo: UserRoutes;
}

export type Notification =
  | INotification<typeof NotificationTypes.Markets>
  | INotification<typeof NotificationTypes.Test>;

export type FormattedNotification =
  | IFormattedNotification<typeof NotificationTypes.Markets>
  | IFormattedNotification<typeof NotificationTypes.Test>;

export const NotificationTypes = {
  Markets: 'Markets',
  Test: 'Test', // temp type to have correctly set up types for Notification.body dependent on Notification.type
} as const;

export type NotificationType = typeof NotificationTypes[keyof typeof NotificationTypes];

interface MarketsNotificationBody {
  markets: string[];
  action: typeof MarketsNotificationActions[keyof typeof MarketsNotificationActions];
}

export const MarketsNotificationActions = {
  added: 'added',
  deleted: 'deleted',
} as const;
