import { I18nEnum } from 'types';
import { Subscription, SubscriptionConfig, SubscriptionType } from 'modules/subscription/types';
import {
  DEFAULT_SUBSCRIPTION_CONFIG,
  SUBSCRIPTION_CONFIG_MAP,
} from 'modules/subscription/constants';
import { getSubscriptionType } from './toolScript';

export const getSubscriptionRequestTranslationId = (subscription: Subscription): string => {
  if (subscription.cancelationRequested) {
    return I18nEnum.Pause;
  }
  if (subscription.activationRequested) {
    return I18nEnum.Activation;
  }
  return '';
};

export const getSubscriptionConfig = (subscriptions: Subscription[]): SubscriptionConfig => {
  const type = getSubscriptionType(subscriptions);
  return getSubscriptionConfigByType(type);
};

export const getSubscriptionConfigByType = (type: SubscriptionType): SubscriptionConfig =>
  SUBSCRIPTION_CONFIG_MAP[type] || DEFAULT_SUBSCRIPTION_CONFIG;
