import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { I18nEnum } from 'types';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import {
  services as profileServices,
  constants as profileConstants,
  queries as profileQueries,
  ProfileState,
  UpsertSmtpServerVariables,
} from 'modules/profile';
import * as modalActions from 'modules/modal/actions';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { useLocalStorage } from 'modules/hooks';
import { SHOULD_SHOW_SMTP_SERVER_CONNECTION_WARNING_MODAL_STORAGE_KEY } from 'modules/product/constants';

export const useUpdateProfile = (props?: { successMessage: string; errorMessage: string }) => {
  const { successMessage, errorMessage } = props || {};

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useAppMutation<ProfileState>({
    mutationFn: profileData => profileServices.updateProfileApi(profileData),
    onSuccess: () => {
      dispatch(modalActions.closeModal());
      successMessage && dispatch(messageActions.openMessage(MessageTypes.success, successMessage));

      queryClient.invalidateQueries({
        queryKey: [profileConstants.PROFILE_KEY],
      });
    },
    onError: () => {
      errorMessage && dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};

export const useUpsertSmtpServer = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [, setShouldShowSmtpServerConnectionWarningModal] = useLocalStorage(
    SHOULD_SHOW_SMTP_SERVER_CONNECTION_WARNING_MODAL_STORAGE_KEY,
  );

  const { data: profile } = profileQueries.useGetProfile();

  return useAppMutation<UpsertSmtpServerVariables>({
    mutationFn: async variables => {
      if (!profile?.id) return;

      return profileServices.upsertSmtpServer(profile.id, variables.body);
    },
    onSuccess: (_data, { successMessageId }) => {
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: successMessageId || I18nEnum.TheChangesHaveBeenSaved }),
        ),
      );

      setShouldShowSmtpServerConnectionWarningModal(true);
      queryClient.invalidateQueries({
        queryKey: [profileConstants.PROFILE_KEY],
      });
    },
    onError: error => {
      if (error.code === 400) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.error,
            intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
          ),
        );
      }
    },
  });
};
