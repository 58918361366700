import { I18nEnum } from 'types';
import {
  AddAdditionalCostsFormKey,
  AddAdditionalCostsFormValues,
  AdditionalCostsFormKey,
  CompanyPublicQuoteSettingsFormValues,
  GroupValidationResult,
  InstantQuotesFormKey,
  PublicQuoteSettingsModel,
  QuoteSettingsFormValues,
  QuoteSettingsModel,
  StaticQuoteSettingsFormValues,
} from 'modules/quoteSettings/types';
import { StructureSlopeEnum } from 'modules/quickQuote/types';
import { OptionType } from 'components/Inputs/Select/types';
import {
  DifficultAccess,
  NewDecking,
  PriceRangeType,
  PriceType,
  Quantity,
  RoofCondition,
  RoofType,
} from 'modules/global/types';

const name = 'quoteSettings/';

export const QUOTE_SETTINGS_KEY = `${name}QUOTE_SETTINGS`;
export const MARKET_QUOTE_SETTINGS_KEY = `${name}MARKET_QUOTE_SETTINGS`;

/**
 * Sync with DEFAULT_PUBLIC_QUOTE_SETTINGS_FORM_VALUES
 **/
export const DEFAULT_PUBLIC_QUOTE_SETTINGS_MODEL: PublicQuoteSettingsModel = {
  steepSlopeChargeEnabled: false,
  steepSlopeChargeValue: 0,
  steepSlopeChargeType: PriceType.PerSquare,
  wasteFactorEnabled: false,
  wasteFactorValue: 1.1,
  wasteFactorBasedOnFacetCountEnabled: false,
  priceRangeEnabled: false,
  priceRangeValue: 10,
  priceRangeType: PriceRangeType.Full,
  suggestedSlopeEnabled: true,
  squareFootageEnabled: false,
};

/**
 * Sync with DEFAULT_STATIC_QUOTE_SETTINGS_FORM_VALUES
 **/
export const DEFAULT_QUOTE_SETTINGS_MODEL: QuoteSettingsModel = {
  ...DEFAULT_PUBLIC_QUOTE_SETTINGS_MODEL,
  storyCostsEnabled: false,
  twoStoriesCost: 0,
  twoStoriesCostType: PriceType.PerSquare,
  manyStoriesCost: 0,
  manyStoriesCostType: PriceType.PerSquare,
  layerCostsEnabled: false,
  twoLayersCost: 0,
  twoLayersCostType: PriceType.PerSquare,
  manyLayersCost: 0,
  manyLayersCostType: PriceType.PerSquare,
  chimneyCostsEnabled: false,
  chimneyFlashingCost: 0,
  twoChimneysFlashingCost: 0,
  manyChimneysFlashingCost: 0,
  skylightCostsEnabled: false,
  skylightFlashingCost: 0,
  twoSkylightsFlashingCost: 0,
  manySkylightsFlashingCost: 0,
  roofConditionCostsEnabled: false,
  algaeStainsCost: 0,
  algaeStainsCostType: PriceType.PerSquare,
  hailDamageCost: 0,
  hailDamageCostType: PriceType.PerSquare,
  windDamageCost: 0,
  windDamageCostType: PriceType.PerSquare,
  missingShinglesCost: 0,
  missingShinglesCostType: PriceType.PerSquare,
  roofLeaksCost: 0,
  roofLeaksCostType: PriceType.PerSquare,
  saggingCost: 0,
  saggingCostType: PriceType.PerSquare,
  structuralDamageCost: 0,
  structuralDamageCostType: PriceType.PerSquare,
  roofTypeCostsEnabled: false,
  asphaltShingleRoofCost: 0,
  asphaltShingleRoofCostType: PriceType.PerSquare,
  metalRoofCost: 0,
  metalRoofCostType: PriceType.PerSquare,
  syntheticRoofCost: 0,
  syntheticRoofCostType: PriceType.PerSquare,
  tileRoofCost: 0,
  tileRoofCostType: PriceType.PerSquare,
  woodRoofCost: 0,
  woodRoofCostType: PriceType.PerSquare,
  newDeckingCostsEnabled: false,
  partialNewDeckingCost: 0,
  fullDeckingReplacementCost: 0,
  difficultAccessCostsEnabled: false,
  difficultAccessCost: 0,
  difficultAccessCostType: PriceType.PerSquare,
  extraCostsEnabled: false,
};

/**
 * Sync with DEFAULT_PUBLIC_QUOTE_SETTINGS_MODEL
 */
const DEFAULT_PUBLIC_QUOTE_SETTINGS_FORM_VALUES: CompanyPublicQuoteSettingsFormValues = {
  [InstantQuotesFormKey.SteepSlopeChargeEnabled]: false,
  [InstantQuotesFormKey.SteepSlopeChargeValue]: '0',
  [InstantQuotesFormKey.SteepSlopeChargePriceType]: PriceType.PerSquare,
  [InstantQuotesFormKey.WasteFactorEnabled]: false,
  [InstantQuotesFormKey.WasteFactorBasedOnFacetCountEnabled]: false,
  [InstantQuotesFormKey.WasteFactorValue]: '10',
  [InstantQuotesFormKey.PriceRangeEnabled]: false,
  [InstantQuotesFormKey.PriceRangeValue]: 10,
  [InstantQuotesFormKey.PriceRangeType]: PriceRangeType.Full,
  [InstantQuotesFormKey.SuggestedSlopeEnabled]: true,
  [InstantQuotesFormKey.SquareFootageEnabled]: false,
};

/**
 * Sync with DEFAULT_QUOTE_SETTINGS_MODEL
 */
export const DEFAULT_STATIC_QUOTE_SETTINGS_FORM_VALUES: StaticQuoteSettingsFormValues = {
  ...DEFAULT_PUBLIC_QUOTE_SETTINGS_FORM_VALUES,
  [AdditionalCostsFormKey.StoryCostsEnabled]: false,
  [AdditionalCostsFormKey.TwoStoriesCost]: '0',
  [AdditionalCostsFormKey.TwoStoriesCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.ManyStoriesCost]: '0',
  [AdditionalCostsFormKey.ManyStoriesCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.LayerCostsEnabled]: false,
  [AdditionalCostsFormKey.TwoLayersCost]: '0',
  [AdditionalCostsFormKey.TwoLayersCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.ManyLayersCost]: '0',
  [AdditionalCostsFormKey.ManyLayersCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.ChimneyCostsEnabled]: false,
  [AdditionalCostsFormKey.ChimneyFlashingCost]: '0',
  [AdditionalCostsFormKey.TwoChimneysFlashingCost]: '0',
  [AdditionalCostsFormKey.ManyChimneysFlashingCost]: '0',
  [AdditionalCostsFormKey.SkylightCostsEnabled]: false,
  [AdditionalCostsFormKey.SkylightFlashingCost]: '0',
  [AdditionalCostsFormKey.TwoSkylightsFlashingCost]: '0',
  [AdditionalCostsFormKey.ManySkylightsFlashingCost]: '0',
  [AdditionalCostsFormKey.RoofConditionCostsEnabled]: false,
  [AdditionalCostsFormKey.AlgaeStainsCost]: '0',
  [AdditionalCostsFormKey.AlgaeStainsCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.HailDamageCost]: '0',
  [AdditionalCostsFormKey.HailDamageCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.WindDamageCost]: '0',
  [AdditionalCostsFormKey.WindDamageCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.MissingShinglesCost]: '0',
  [AdditionalCostsFormKey.MissingShinglesCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.RoofLeaksCost]: '0',
  [AdditionalCostsFormKey.RoofLeaksCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.SaggingCost]: '0',
  [AdditionalCostsFormKey.SaggingCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.StructuralDamageCost]: '0',
  [AdditionalCostsFormKey.StructuralDamageCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.RoofTypeCostsEnabled]: false,
  [AdditionalCostsFormKey.AsphaltShingleRoofCost]: '0',
  [AdditionalCostsFormKey.AsphaltShingleRoofCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.MetalRoofCost]: '0',
  [AdditionalCostsFormKey.MetalRoofCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.SyntheticRoofCost]: '0',
  [AdditionalCostsFormKey.SyntheticRoofCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.TileRoofCost]: '0',
  [AdditionalCostsFormKey.TileRoofCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.WoodRoofCost]: '0',
  [AdditionalCostsFormKey.WoodRoofCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.NewDeckingCostsEnabled]: false,
  [AdditionalCostsFormKey.PartialNewDeckingCost]: '0',
  [AdditionalCostsFormKey.FullDeckingReplacementCost]: '0',
  [AdditionalCostsFormKey.DifficultAccessCostsEnabled]: false,
  [AdditionalCostsFormKey.DifficultAccessCost]: '0',
  [AdditionalCostsFormKey.DifficultAccessCostType]: PriceType.PerSquare,
  [AdditionalCostsFormKey.OtherExtraCostsEnabled]: false,
};

export const DEFAULT_QUOTE_SETTINGS: QuoteSettingsFormValues = {
  [InstantQuotesFormKey.DefaultSlope]: StructureSlopeEnum.medium,
  [InstantQuotesFormKey.ApplyDefaultSlopeToAllMarkets]: true,
  [InstantQuotesFormKey.SlopeByMarket]: {},
  [InstantQuotesFormKey.LastSelectedMarket]: '',
  ...DEFAULT_STATIC_QUOTE_SETTINGS_FORM_VALUES,
};

export const INITIAL_GROUP_VALIDATION_RESULT: GroupValidationResult = {
  extraCostsInvalid: false,
  instantQoutesFeatureFieldsInvalid: false,
  additionalCostsFeatureFieldsInvalid: false,
};

export const STEEP_SLOPE_CHARGE_GROUP_NAMES: string[][] = [
  [InstantQuotesFormKey.SteepSlopeChargeValue],
  [InstantQuotesFormKey.SteepSlopeChargePriceType],
];

export const WASTE_FACTOR_GROUP_NAMES: string[][] = [
  [InstantQuotesFormKey.WasteFactorBasedOnFacetCountEnabled],
  [InstantQuotesFormKey.WasteFactorValue],
];

export const PRICE_RANGE_GROUP_NAMES: string[][] = [
  [InstantQuotesFormKey.PriceRangeValue],
  [InstantQuotesFormKey.PriceRangeType],
];

export const STORIES_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.TwoStoriesCost],
  [AdditionalCostsFormKey.TwoStoriesCostType],
  [AdditionalCostsFormKey.ManyStoriesCost],
  [AdditionalCostsFormKey.ManyStoriesCostType],
];

export const STORIES_GROUP: (keyof QuoteSettingsModel)[] = [
  'twoStoriesCost',
  'twoStoriesCostType',
  'manyStoriesCost',
  'manyStoriesCostType',
];

export const LAYERS_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.TwoLayersCost],
  [AdditionalCostsFormKey.TwoLayersCostType],
  [AdditionalCostsFormKey.ManyLayersCost],
  [AdditionalCostsFormKey.ManyLayersCostType],
];

export const LAYERS_GROUP: (keyof QuoteSettingsModel)[] = [
  'twoLayersCost',
  'twoLayersCostType',
  'manyLayersCost',
  'manyLayersCostType',
];

export const CHIMNEY_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.ChimneyFlashingCost],
  [AdditionalCostsFormKey.TwoChimneysFlashingCost],
  [AdditionalCostsFormKey.ManyChimneysFlashingCost],
];

export const CHIMNEY_GROUP: (keyof QuoteSettingsModel)[] = [
  'chimneyFlashingCost',
  'twoChimneysFlashingCost',
  'manyChimneysFlashingCost',
];

export const SKYLIGHT_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.SkylightFlashingCost],
  [AdditionalCostsFormKey.TwoSkylightsFlashingCost],
  [AdditionalCostsFormKey.ManySkylightsFlashingCost],
];

export const SKYLIGHT_GROUP: (keyof QuoteSettingsModel)[] = [
  'skylightFlashingCost',
  'twoSkylightsFlashingCost',
  'manySkylightsFlashingCost',
];

export const ROOF_CONDITION_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.AlgaeStainsCost],
  [AdditionalCostsFormKey.AlgaeStainsCostType],
  [AdditionalCostsFormKey.HailDamageCost],
  [AdditionalCostsFormKey.HailDamageCostType],
  [AdditionalCostsFormKey.WindDamageCost],
  [AdditionalCostsFormKey.WindDamageCostType],
  [AdditionalCostsFormKey.MissingShinglesCost],
  [AdditionalCostsFormKey.MissingShinglesCostType],
  [AdditionalCostsFormKey.RoofLeaksCost],
  [AdditionalCostsFormKey.RoofLeaksCostType],
  [AdditionalCostsFormKey.SaggingCost],
  [AdditionalCostsFormKey.SaggingCostType],
  [AdditionalCostsFormKey.StructuralDamageCost],
  [AdditionalCostsFormKey.StructuralDamageCostType],
];

export const ROOF_CONDITION_GROUP: (keyof QuoteSettingsModel)[] = [
  'algaeStainsCost',
  'algaeStainsCostType',
  'hailDamageCost',
  'hailDamageCostType',
  'windDamageCost',
  'windDamageCostType',
  'missingShinglesCost',
  'missingShinglesCostType',
  'roofLeaksCost',
  'roofLeaksCostType',
  'saggingCost',
  'saggingCostType',
  'structuralDamageCost',
  'structuralDamageCostType',
];

export const ROOF_TYPE_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.AsphaltShingleRoofCost],
  [AdditionalCostsFormKey.AsphaltShingleRoofCostType],
  [AdditionalCostsFormKey.MetalRoofCost],
  [AdditionalCostsFormKey.MetalRoofCostType],
  [AdditionalCostsFormKey.SyntheticRoofCost],
  [AdditionalCostsFormKey.SyntheticRoofCostType],
  [AdditionalCostsFormKey.TileRoofCost],
  [AdditionalCostsFormKey.TileRoofCostType],
  [AdditionalCostsFormKey.WoodRoofCost],
  [AdditionalCostsFormKey.WoodRoofCostType],
];

export const ROOF_TYPE_GROUP: (keyof QuoteSettingsModel)[] = [
  'asphaltShingleRoofCost',
  'asphaltShingleRoofCostType',
  'metalRoofCost',
  'metalRoofCostType',
  'syntheticRoofCost',
  'syntheticRoofCostType',
  'tileRoofCost',
  'tileRoofCostType',
  'woodRoofCost',
  'woodRoofCostType',
];

export const NEW_DECKING_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.PartialNewDeckingCost],
  [AdditionalCostsFormKey.FullDeckingReplacementCost],
];

export const NEW_DECKING_GROUP: (keyof QuoteSettingsModel)[] = [
  'partialNewDeckingCost',
  'fullDeckingReplacementCost',
];

export const DIFFICULT_ACCESS_GROUP_NAMES: string[][] = [
  [AdditionalCostsFormKey.DifficultAccessCost],
  [AdditionalCostsFormKey.DifficultAccessCostType],
];

export const DIFFICULT_ACCESS_GROUP: (keyof QuoteSettingsModel)[] = [
  'difficultAccessCost',
  'difficultAccessCostType',
];

export const ADDITIONAL_COSTS_TOGGLES: string[] = [
  AdditionalCostsFormKey.StoryCostsEnabled,
  AdditionalCostsFormKey.LayerCostsEnabled,
  AdditionalCostsFormKey.ChimneyCostsEnabled,
  AdditionalCostsFormKey.SkylightCostsEnabled,
  AdditionalCostsFormKey.RoofConditionCostsEnabled,
  AdditionalCostsFormKey.RoofTypeCostsEnabled,
  AdditionalCostsFormKey.NewDeckingCostsEnabled,
  AdditionalCostsFormKey.DifficultAccessCostsEnabled,
  AdditionalCostsFormKey.OtherExtraCostsEnabled,
];

export const ADDITIONAL_COSTS_TOGGLES_IN_MODEL: (keyof QuoteSettingsModel)[] = [
  'storyCostsEnabled',
  'layerCostsEnabled',
  'chimneyCostsEnabled',
  'skylightCostsEnabled',
  'roofConditionCostsEnabled',
  'roofTypeCostsEnabled',
  'newDeckingCostsEnabled',
  'difficultAccessCostsEnabled',
  'extraCostsEnabled',
];

export const DEFAULT_ADD_ADDITIONAL_COSTS_FORM_VALUES: AddAdditionalCostsFormValues = {
  [AddAdditionalCostsFormKey.StoryCost]: Quantity.One,
  [AddAdditionalCostsFormKey.LayerCost]: Quantity.One,
  [AddAdditionalCostsFormKey.ChimneyFlashingCost]: Quantity.None,
  [AddAdditionalCostsFormKey.SkylightFlashingCost]: Quantity.None,
  [AddAdditionalCostsFormKey.RoofConditionCosts]: [RoofCondition.None],
  [AddAdditionalCostsFormKey.RoofTypeCost]: RoofType.AsphaltShingle,
  [AddAdditionalCostsFormKey.NewDeckingCost]: NewDecking.No,
  [AddAdditionalCostsFormKey.DifficultAccessCost]: DifficultAccess.NotApplicable,
};

export const PRICE_TYPE_OPTIONS: OptionType[] = [
  {
    value: PriceType.PerSquare,
    label: I18nEnum.PerSq,
  },
  {
    value: PriceType.Fixed,
    label: I18nEnum.Fixed,
  },
];

export const MAX_PRICE_RANGE_VALUE = 99;
export const MIN_PRICE_RANGE_VALUE = 1;
export const PRICE_RANGE_STEP = 1;

export const TOTAL_DISCOUNT_KEY = 'total';

export const TOTAL_ADDITIONAL_COST_DISCOUNT_KEY = 'totalAdditionalCost';

export const STATIC_DISCOUNT_KEYS: string[] = [
  TOTAL_DISCOUNT_KEY,
  TOTAL_ADDITIONAL_COST_DISCOUNT_KEY,
];

export const staticDiscountsMap = {
  [TOTAL_ADDITIONAL_COST_DISCOUNT_KEY]: I18nEnum.TotalAdditionalCost,
  [TOTAL_DISCOUNT_KEY]: I18nEnum.Total,
};

export const validatedFields: string[] = [
  InstantQuotesFormKey.DefaultSlope,
  InstantQuotesFormKey.SteepSlopeChargeValue,
  InstantQuotesFormKey.SteepSlopeChargePriceType,
  InstantQuotesFormKey.WasteFactorValue,
  InstantQuotesFormKey.PriceRangeValue,
  InstantQuotesFormKey.PriceRangeType,
  ...STORIES_GROUP_NAMES.flat(),
  ...LAYERS_GROUP_NAMES.flat(),
  ...CHIMNEY_GROUP_NAMES.flat(),
  ...SKYLIGHT_GROUP_NAMES.flat(),
  ...ROOF_CONDITION_GROUP_NAMES.flat(),
  ...ROOF_TYPE_GROUP_NAMES.flat(),
  ...NEW_DECKING_GROUP_NAMES.flat(),
  ...DIFFICULT_ACCESS_GROUP_NAMES.flat(),
];
