import { useSelector } from 'react-redux';

import { selectUser } from 'modules/auth/selectors';
import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { OneClickContractorIntegrationType } from '../types';
import { ONE_CLICK_CONTRACTOR_CONNECTION_KEY } from '../constants';
import { services } from '..';

export const useGetOneClickContractorIntegration = () => {
  const user = useSelector(selectUser);

  const { data: oneClickContractorIntegration, isFetching } =
    useAppQuery<OneClickContractorIntegrationType>(
      [ONE_CLICK_CONTRACTOR_CONNECTION_KEY],
      () => services.getCompanyOneClickContractorIntegrationAPI(user.id),
      {
        enabled: !!user.id,
      },
    );

  oneClickContractorIntegration?.settings?.jobTypes.forEach(jobType => {
    if (!jobType.org_job_object_link) {
      jobType.org_job_object_link = {
        sort_order: 1000,
      };
    }
  });

  return !isFetching ? oneClickContractorIntegration : undefined;
};
