import { ModalTypes } from 'modules/modal/types';
import { QuickQuoteConfigState } from 'modules/quickQuoteConfig';
import { QuickQuoteModules } from 'modules/quickQuoteConfig/constants';
import { I18nEnum } from 'types';

export const DEFAULT_REP_QUOTE_CONFIG: QuickQuoteConfigState = {
  userId: 0,
  title: I18nEnum.RepQuotes,
  hideDescription: true,
  subContent: 'SubContent',
  infoContent: 'AdditionalInformation',
  showThankYouScreen: false,
  showGetHelp: false,
  statistics: {
    track: false,
    sendStatisticsSuccessMessage: I18nEnum.LeadHasBeenCreatedSuccessfully,
    sendStatisticsFailMessage: I18nEnum.UnfortunatelyTheLeadHasNotBeenCreated,
  },
  shouldTrackWidgetAnalytics: false,
  usePersistentStorages: false,
  moduleKey: QuickQuoteModules.repQuotes,
  productsDescription: 'ProductsDescription',
  search: {
    iCantFindComponent: 'ICantFindMyAddress',
  },
  map: {
    showMap: true,
    showMapActions: true,
    controls: ['RepQuotesEditControl'],
    structureTools: {
      slope: {
        activeComponent: 'CustomActiveSlope',
        component: 'CustomSlope',
        text: 'CustomSlopeText',
      },
      mainButton: {
        title: I18nEnum.CreateQuoteManually,
        hidden: true,
      },
      notFoundSupportText: I18nEnum.YouCanManuallyDrawYourRoofShape,
      notIncludedMarketsSupportText: I18nEnum.ThisAddressIsLocatedOutsideYourServiceArea,
    },
    navigation: {
      isNotEditable: true,
      primaryBtnTitle: I18nEnum.CreateQuote,
    },
  },
  productCard: {
    sendDetailsTitle: I18nEnum.EmailDetails,
    sendDetailsDisableTitle: I18nEnum.DetailsWereSent,
    instantLoan: {
      buttonTitle: I18nEnum.SendLoanInvitation,
      shouldSendEmail: false,
    },
  },
  modals: {
    almostDone: {
      reCaptcha: false,
    },
    invitationSent: {
      className: 'rep-quotes',
      modalType: ModalTypes.repQuotesInvitationSent,
      imageSize: {
        height: 203,
      },
    },
    howInstantLoanWorks: {
      className: 'rep-quotes',
      modalType: ModalTypes.repQuotesHowInstantLoanWorks,
    },
    thankYou: {
      subtitle: I18nEnum.WeHaveEmailedYouProductDetails,
    },
    productDetails: {
      className: 'rep-quotes',
      modalType: ModalTypes.repQuotesProductDetails,
    },
  },
  inputs: {
    showLabel: true,
  },
  // callbacks
  trackAnalyticsCallback: 'trackRepQuotesAnalytics',
  selectAddressCallback: 'checkExistingLead',
  clearAddressCallback: 'clearSearchRepQuotes',
  customSlopeClickCallback: 'updateCustomSlope',
  activeSlopeHandler: 'checkIsSlopeActive',
  // todo: from what I can tell, this callback is never reached because
  // `map.structureTools.mainButton.hidden = true` in the above configuration
  // this would be executed in MapStructureTools.tsx with <OkButton />
  // so I think it may be safe to delete `startMapEditing` in repQuotes/actions.ts
  structureToolsMainButtonCallback: 'startMapEditing',
  mapNavigationPrimaryCallback: 'createQuotes',
  useProductCardSendDetails: 'useProductCardSendDetails',
  useCreateLead: 'useCreateRepQuoteLead',
  productCardSelectColoredLineCallback: 'selectColoredLine',
  productCardInstantLoanCallback: 'getPreApproved',
  almostDoneModalCallback: 'quickQuoteCreateLead',
};

export const OC_REP_QUOTE_CONFIG: QuickQuoteConfigState = {
  userId: 0,
  title: I18nEnum.RepQuotes,
  hideDescription: true,
  subContent: 'SubContent',
  infoContent: 'AdditionalInformation',
  showThankYouScreen: false,
  showGetHelp: false,
  statistics: {
    track: false,
    sendStatisticsSuccessMessage: I18nEnum.LeadHasBeenCreatedSuccessfully,
    sendStatisticsFailMessage: I18nEnum.UnfortunatelyTheLeadHasNotBeenCreated,
  },
  shouldTrackWidgetAnalytics: false,
  usePersistentStorages: false,
  moduleKey: QuickQuoteModules.repQuotes,
  productsDescription: 'ProductsDescription',
  search: {
    iCantFindComponent: 'ICantFindMyAddress',
  },
  map: {
    showMap: true,
    showMapActions: true,
    controls: ['RepQuotesEditControl'],
    structureTools: {
      slope: {
        activeComponent: 'CustomActiveSlope',
        component: 'CustomSlope',
        text: 'CustomSlopeText',
      },
      mainButton: {
        title: I18nEnum.CreateQuoteManually,
        hidden: true,
      },
      notFoundSupportText: I18nEnum.YouCanManuallyDrawYourRoofShape,
      notIncludedMarketsSupportText: I18nEnum.ThisAddressIsLocatedOutsideYourServiceArea,
    },
    navigation: {
      isNotEditable: true,
      primaryBtnTitle: I18nEnum.CreateQuote,
    },
  },
  productCard: {
    sendDetailsTitle: I18nEnum.EmailDetails,
    sendDetailsDisableTitle: I18nEnum.DetailsWereSent,
    instantLoan: {
      buttonTitle: I18nEnum.SendLoanInvitation,
      shouldSendEmail: false,
    },
  },
  modals: {
    almostDone: {
      reCaptcha: false,
    },
    invitationSent: {
      className: 'rep-quotes',
      modalType: ModalTypes.repQuotesInvitationSent,
      imageSize: {
        height: 203,
      },
    },
    howInstantLoanWorks: {
      className: 'rep-quotes',
      modalType: ModalTypes.repQuotesHowInstantLoanWorks,
    },
    thankYou: {
      subtitle: I18nEnum.WeHaveEmailedYouProductDetails,
    },
    productDetails: {
      className: 'rep-quotes',
      modalType: ModalTypes.repQuotesProductDetails,
    },
  },
  inputs: {
    showLabel: true,
  },
  // callbacks
  trackAnalyticsCallback: 'trackRepQuotesAnalytics',
  selectAddressCallback: 'checkExistingLead',
  clearAddressCallback: 'clearSearchRepQuotes',
  customSlopeClickCallback: 'updateCustomSlope',
  activeSlopeHandler: 'checkIsSlopeActive',
  // todo: from what I can tell, this callback is never reached because
  // `map.structureTools.mainButton.hidden = true` in the above configuration
  // this would be executed in MapStructureTools.tsx with <OkButton />
  // so I think it may be safe to delete `startMapEditing` in repQuotes/actions.ts
  structureToolsMainButtonCallback: 'startMapEditing',
  mapNavigationPrimaryCallback: 'createQuotes',
  useProductCardSendDetails: 'useProductCardSendDetails',
  useCreateLead: 'useCreateRepQuoteLead',
  productCardSelectColoredLineCallback: 'selectColoredLine',
  productCardInstantLoanCallback: 'getPreApproved',
  almostDoneModalCallback: 'quickQuoteCreateLead',
};
