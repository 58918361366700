import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Tag from './Tag';
import { OptionType } from './index';
import { I18nEnum } from '../../../types';
import Tooltip, {
  TooltipContainer as TooltipContainerBase,
  TooltipTrigger as TooltipTriggerBase,
} from '../../Tooltips/Tooltip';

interface TagsContainersProps {
  values: string[];
  options: OptionType[];
  disabled?: boolean;
  onTagClose: (value: string | number) => void;
}

const TagsScrollableContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 192px;
  margin-top: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

const TagContainer = styled.div`
  display: inline-flex;
  max-width: 100%;
  position: relative;
`;

const TooltipContainer = styled(TooltipContainerBase)`
  z-index: 10001;
`;

const TruncatedContent = styled.span`
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TooltipTrigger = styled(TooltipTriggerBase)`
  max-width: 100%;
  cursor: pointer;
`;

const getLabel = (label: string | JSX.Element) =>
  Object.keys(I18nEnum).includes(label as string) ? (
    <FormattedMessage id={label as string} />
  ) : (
    label
  );

const TruncatedTag = ({
  option,
  ind,
  closable,
  toggleSetShowTooltipForInd,
  onTagClose,
}: {
  option: OptionType;
  ind: number;
  closable: boolean;
  toggleSetShowTooltipForInd: (ind: number) => void;
  onTagClose: (value: string | number) => void;
}) => {
  const onOptionTextMouseOver = useCallback(
    e => {
      if (e.target.scrollWidth > e.target.clientWidth) {
        toggleSetShowTooltipForInd(ind);
      }
    },
    [ind, toggleSetShowTooltipForInd],
  );

  const onOptionTextMouseLeave = useCallback(() => {
    toggleSetShowTooltipForInd(-1);
  }, [toggleSetShowTooltipForInd]);

  const label = useMemo(() => getLabel(option.label), [option]);
  const _onTagClose = useCallback(() => {
    onTagClose(option.value);
  }, [onTagClose, option]);

  return (
    <Tag
      onMouseOver={onOptionTextMouseOver}
      onMouseLeave={onOptionTextMouseLeave}
      color="primary"
      key={option.value}
      closable={closable}
      onClose={_onTagClose}>
      <TruncatedContent>{label}</TruncatedContent>
    </Tag>
  );
};

const TagsContainer = ({ values, options, onTagClose, disabled = false }: TagsContainersProps) => {
  const [showTooltipForInd, setShowTooltipForInd] = useState(-1);

  const toggleSetShowTooltipForInd = useCallback((ind: number) => {
    setShowTooltipForInd(ind);
  }, []);

  const isSomeOptionDisabled = useMemo(() => options.some(item => item.disabled), [options]);

  if (!values?.length) {
    return null;
  }

  const allSelected = values.some(val => val === 'all');

  const tags =
    allSelected && !isSomeOptionDisabled
      ? [options[0]]
      : options.filter(option => option.value !== 'all' && values.includes(option.value as string));

  return (
    <TagsScrollableContainer>
      {tags.map((option, index) => (
        <Tooltip
          key={option.value}
          trigger="manual"
          tooltip={showTooltipForInd === index && getLabel(option.label)}
          ContainerComponent={TooltipContainer}
          TriggerComponent={TooltipTrigger}
          isShown={showTooltipForInd === index}>
          <TagContainer>
            <TruncatedTag
              ind={index}
              toggleSetShowTooltipForInd={toggleSetShowTooltipForInd}
              option={option}
              closable={!disabled}
              onTagClose={onTagClose}
            />
          </TagContainer>
        </Tooltip>
      ))}
    </TagsScrollableContainer>
  );
};

export default TagsContainer;
