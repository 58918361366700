import { ModalTypes } from 'modules/modal/types';
import { I18nEnum } from 'types';
import { QuickQuoteConfigState } from './types';

const name = 'quick-quote-config/';

export const UPDATE = `${name}UPDATE`;
export const RESET = `${name}RESET`;

export const QuickQuoteModules = {
  quickQuote: 'modules/quickQuote',
  repQuotes: 'modules/repQuotes',
} as const;

export const DEFAULT_QQ_CONFIG: QuickQuoteConfigState = {
  userId: 0,
  showThankYouScreen: true,
  showGetHelp: true,
  statistics: {
    track: true,
    sendStatisticsSuccessMessage: I18nEnum.SendStatisticsSuccessMessage,
    sendStatisticsFailMessage: I18nEnum.SendStatisticsFailMessage,
  },
  shouldTrackWidgetAnalytics: true,
  usePersistentStorages: true,
  moduleKey: QuickQuoteModules.quickQuote,
  search: {
    iCantFindComponent: 'ICantFindMyAddress',
  },
  map: {
    showMap: true,
    showMapActions: false,
    controls: [],
    structureTools: {
      notFoundSupportText: I18nEnum.YouCanManuallyDrawYourRoofShapeOrClickGetHelp,
      notIncludedMarketsSupportText: I18nEnum.YourAddressIsNotWhithinOurServiceArea,
      // todo: I don't think this button is ever revealed any more
      // may be able to delete this
      mainButton: {
        title: I18nEnum.IWouldStillLikeAQuote,
        hidden: false,
      },
    },
    navigation: {
      isNotEditable: false,
      primaryBtnTitle: I18nEnum.SeeMyPrice,
    },
  },
  productCard: {
    sendDetailsTitle: '',
    sendDetailsDisableTitle: I18nEnum.DetailsWereSent,
    instantLoan: {
      buttonTitle: I18nEnum.GetPreApproved,
      shouldSendEmail: true,
    },
  },
  modals: {
    almostDone: {
      reCaptcha: true,
    },
    invitationSent: {
      className: '',
      modalType: ModalTypes.invitationSent,
      imageSize: {},
    },
    howInstantLoanWorks: {
      className: '',
      modalType: ModalTypes.howInstantLoanWorks,
    },
    thankYou: {
      subtitle: I18nEnum.WeHaveEmailedYouProductDetails,
    },
    productDetails: {
      className: '',
      modalType: ModalTypes.widgetProductDetails,
    },
  },
  inputs: {
    showLabel: false,
  },
  // callbacks
  trackAnalyticsCallback: 'trackWidgetAnalytics',
  selectAddressCallback: 'selectAddress',
  clearAddressCallback: 'clear',
  useProductCardSendDetails: 'usePublicRequestWidgetProductEmail',
  useCreateLead: 'useCreateQuickQuoteLead',
  productCardInstantLoanCallback: 'quickQuoteGetPreApproved',
  almostDoneModalCallback: 'getPublicWidgetProducts',
};

export const OC_QUICK_QUOTE_CONFIG: QuickQuoteConfigState = {
  userId: 0,
  showThankYouScreen: true,
  showGetHelp: true,
  statistics: {
    track: true,
    sendStatisticsSuccessMessage: I18nEnum.SendStatisticsSuccessMessage,
    sendStatisticsFailMessage: I18nEnum.SendStatisticsFailMessage,
  },
  shouldTrackWidgetAnalytics: true,
  usePersistentStorages: true,
  moduleKey: QuickQuoteModules.quickQuote,
  search: {
    iCantFindComponent: 'ICantFindMyAddress',
  },
  map: {
    showMap: true,
    showMapActions: false,
    controls: [],
    structureTools: {
      notFoundSupportText: I18nEnum.YouCanManuallyDrawYourRoofShapeOrClickGetHelp,
      notIncludedMarketsSupportText: I18nEnum.YourAddressIsNotWhithinOurServiceArea,
      // todo: I don't think this button is ever revealed any more
      // may be able to delete this
      mainButton: {
        title: I18nEnum.IWouldStillLikeAQuote,
        hidden: false,
      },
    },
    navigation: {
      isNotEditable: false,
      primaryBtnTitle: I18nEnum.SeeMyPrice,
    },
  },
  productCard: {
    sendDetailsTitle: '',
    sendDetailsDisableTitle: I18nEnum.DetailsWereSent,
    instantLoan: {
      buttonTitle: I18nEnum.GetPreApproved,
      shouldSendEmail: true,
    },
  },
  modals: {
    almostDone: {
      reCaptcha: true,
    },
    invitationSent: {
      className: '',
      modalType: ModalTypes.invitationSent,
      imageSize: {},
    },
    howInstantLoanWorks: {
      className: '',
      modalType: ModalTypes.howInstantLoanWorks,
    },
    thankYou: {
      subtitle: I18nEnum.WeHaveEmailedYouProductDetails,
    },
    productDetails: {
      className: '',
      modalType: ModalTypes.widgetProductDetails,
    },
  },
  inputs: {
    showLabel: false,
  },
  // callbacks
  trackAnalyticsCallback: 'trackWidgetAnalytics',
  selectAddressCallback: 'selectAddress',
  clearAddressCallback: 'clear',
  useProductCardSendDetails: 'usePublicRequestWidgetProductEmail',
  useCreateLead: 'useCreateQuickQuoteLead',
  productCardInstantLoanCallback: 'quickQuoteGetPreApproved',
  almostDoneModalCallback: 'getPublicWidgetProducts',
};
