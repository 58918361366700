import api from 'services/api';
import { AddContractorBody, UpdateContractorBody, AdminFiltersBody } from 'modules/admin/types';
import {
  SubscriptionMarketsUpdate,
  SubscriptionRequestType,
  SubscriptionUpdate,
} from 'modules/subscription/types';

export const getContractorsDashboardAPI = (userId: number, body?: AdminFiltersBody) =>
  api.post(`admin/${userId}/contractors`, body);

export const getContractorsDashboardFilterAPI = (userId: number, body?: AdminFiltersBody) =>
  api.post(`admin/${userId}/contractors/filter`, body);

export const getContractorAPI = (userId: number, contractorId: number) =>
  api.get(`admin/${userId}/contractor/${contractorId}`);

export const addContractorAPI = (userId: number, body: AddContractorBody) =>
  api.post(`admin/${userId}/contractor`, body);

export const updateContractorAPI = (
  userId: number,
  contractorId: number,
  body: UpdateContractorBody,
) => api.patch(`admin/${userId}/contractor/${contractorId}`, body);

export const deleteContractorAPI = (userId: number, contractorId: number) =>
  api.delete(`admin/${userId}/contractor/${contractorId}`);

export const updateSubscriptionAPI = ({
  userId,
  data,
  contractorId,
  subscriptionId,
}: {
  userId: number;
  data: SubscriptionUpdate;
  contractorId: number;
  subscriptionId: number;
}) => api.patch(`admin/${userId}/contractor/${contractorId}/subscription/${subscriptionId}`, data);

export const updateSubscriptionMarketsAPI = ({
  userId,
  data,
  contractorId,
}: {
  userId: number;
  data: SubscriptionMarketsUpdate;
  contractorId: number;
}) => api.post(`admin/${userId}/update-markets/${contractorId}`, data);

export const toggleSubscriptionAPI = (userId: number, contractorId: number) =>
  api.post(`admin/${userId}/contractor/${contractorId}/toggle-subscription`);

export const sendPasswordAPI = (userId: number, contractorId: number) =>
  api.get(`admin/${userId}/contractor/${contractorId}/send-password`);

export const resetSubscriptionStatusChangingRequestApi = ({
  userId,
  contractorId,
  subscriptionId,
  body,
}: {
  userId: number;
  contractorId: number;
  subscriptionId: number;
  body: { requestType: SubscriptionRequestType };
}) =>
  api.post(
    `admin/${userId}/contractor/${contractorId}/subscription/${subscriptionId}/reset-status-changing-request`,
    body,
  );

export const getHSCompanyListAPI = (userId: number, search: string) =>
  api.get(`admin/${userId}/hs-company-list`, { params: { search } });

export const getHSCompanyContactAPI = (userId: number, hsCompanyId: number) =>
  api.get(`admin/${userId}/company-contact/${hsCompanyId}`);

export const getContractorToken = (adminId: number, contractorId: number) =>
  api.get(`admin/${adminId}/contractor/${contractorId}/auth`);
export const getContractorsForDropdownAPI = (userId: number, params: { search: string }) =>
  api.get(`admin/${userId}/contractors-dropdown`, {
    params,
  });
export const getRecurlyAccountsAPI = (adminId: number) =>
  api.get(`admin/${adminId}/recurly-accounts`);
export const getCompanyRecurlyAccountAPI = (adminId: number, contractorId: number) =>
  api.get(`admin/${adminId}/contractor/${contractorId}/recurly-account`);
