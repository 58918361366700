import { ThunkResult } from 'types';
import { selectors as userSelectors } from 'modules/auth';
import { selectors, constants, services, Notification } from 'modules/notifications';

export const _setUserNotifications = (notifications: Notification[]) => ({
  type: constants.SET_NOTIFICATIONS,
  notifications,
});

export const getNotifications =
  (userId: number): ThunkResult<Promise<void>> =>
  async dispatch => {
    try {
      const { data: notifications = [] }: { data: Notification[] } =
        await services.getNotifications(userId);

      const userNotifications = notifications.sort(
        (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt),
      );

      dispatch(_setUserNotifications(userNotifications));
    } catch (error) {
      console.log(error);
    }
  };

export const addUserNotification =
  (notification: Notification): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const notifications = selectors.selectNotifications(getState());

    const userNotifications = [...notifications, notification].sort(
      (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt),
    );

    dispatch(_setUserNotifications(userNotifications));
  };

export const toggleReadUnreadNotification =
  (notificationId: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const user = userSelectors.selectUser(getState());
      const notifications = selectors.selectNotifications(getState());

      const { data: toggledId } = await services.toggleReadNotification(user.id, notificationId);

      const userNotifications = notifications.map(notification => ({
        ...notification,
        isRead: notification.id === +toggledId ? !notification.isRead : notification.isRead,
      }));

      dispatch(_setUserNotifications(userNotifications));
    } catch (error) {
      console.log(error);
    }
  };

export const deleteNotification =
  (notificationId: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const user = userSelectors.selectUser(getState());
      const notifications = selectors.selectNotifications(getState());

      const { data: deletedId } = await services.deleteNotification(user.id, notificationId);

      const userNotifications = notifications.filter(
        notification => notification.id !== +deletedId,
      );

      dispatch(_setUserNotifications(userNotifications));
    } catch (error) {
      console.log(error);
    }
  };

export const markNotificationsAsRead =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    try {
      const user = userSelectors.selectUser(getState());
      const notifications = selectors.selectNotifications(getState());

      await services.markNotificationsAsRead(user.id);

      const userNotifications = notifications.map(notification => ({
        ...notification,
        isRead: true,
      }));

      dispatch(_setUserNotifications(userNotifications));
    } catch (error) {
      console.log(error);
    }
  };

export const batchReadNotifications =
  (ids: number[]): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const user = userSelectors.selectUser(getState());
      const notifications = selectors.selectNotifications(getState());

      await services.batchReadNotifications(user.id, ids);

      const userNotifications = notifications.map(notification => ({
        ...notification,
        isRead: ids.includes(notification.id) || notification.isRead,
      }));

      dispatch(_setUserNotifications(userNotifications));
    } catch (error) {
      console.log(error);
    }
  };
