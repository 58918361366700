import { useContext } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { useAppQuery, queryClient } from 'modules/reactQuery';
import {
  Line,
  CatalogListQuery,
  constants as productConstants,
  services,
  TabKeys,
} from 'modules/product';
import { selectors as userSelectors } from 'modules/auth';
import { getWidgetProductsAPI } from 'modules/widget/services';
import ProductsContext from './components/ProductsContext';
import { useGetSubscriptionConfig } from 'modules/subscription/queries';

export const invalidateProductsQueries = () =>
  Promise.all(productConstants.productsQueries.map(key => queryClient.invalidateQueries([key])));

export const invalidateCustomProductsQueries = () =>
  Promise.all(
    productConstants.customProductsQueries.map(key => queryClient.invalidateQueries([key])),
  );

export const invalidateMarketProductsQuery = (exact?: boolean) =>
  queryClient.invalidateQueries({
    queryKey: [productConstants.MARKET_PRODUCTS_KEY],
    exact,
  });

export const useGetMarketProducts = (marketSlug?: string) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Line[]>(
    [productConstants.MARKET_PRODUCTS_KEY, marketSlug],
    ({ queryKey }) => {
      const [_, _marketSlug] = queryKey;
      return getWidgetProductsAPI(user.id, _marketSlug as string);
    },
    {
      enabled: !!(user.id && marketSlug),
    },
  );
};

export const useGetCatalogProducts = () => {
  const user = useSelector(userSelectors.selectUser);
  const {
    [TabKeys.roofleCatalog]: { query: contextQuery },
  } = useContext(ProductsContext);
  const { data: config } = useGetSubscriptionConfig();

  const query = cloneDeep(contextQuery);
  const countryAvailableIn = config?.productSettings.countryAvailableIn;
  query.filter.countryAvailableIn = countryAvailableIn;

  return useAppQuery<Line[]>(
    [productConstants.CATALOG_PRODUCTS_KEY, query],
    ({ queryKey }) => {
      const [_, _query] = queryKey;
      return services.getProductsApi(_query as CatalogListQuery);
    },
    {
      enabled: !!user.id,
    },
  );
};

export const useGetCatalogProductsCount = () => {
  const user = useSelector(userSelectors.selectUser);
  const {
    [TabKeys.roofleCatalog]: { query: contextQuery },
  } = useContext(ProductsContext);
  const { data: config } = useGetSubscriptionConfig();

  const query = cloneDeep(contextQuery);
  const countryAvailableIn = config?.productSettings.countryAvailableIn;
  query.filter.countryAvailableIn = countryAvailableIn;

  return useAppQuery<Record<string, number>>(
    [productConstants.CATALOG_PRODUCTS_COUNT_KEY, query],
    ({ queryKey }) => {
      const [_, _query] = queryKey;
      return services.getProductsCountApi(_query as CatalogListQuery);
    },
    {
      enabled: !!user.id,
    },
  );
};

export const useGetCustomProducts = () => {
  const user = useSelector(userSelectors.selectUser);
  const {
    [TabKeys.customCatalog]: { query },
  } = useContext(ProductsContext);

  return useAppQuery<Line[]>(
    [productConstants.CUSTOM_PRODUCTS_KEY, query],
    ({ queryKey }) => {
      const [_, _query] = queryKey;
      return services.getCustomProductsAPI(user.id, _query as CatalogListQuery);
    },
    {
      enabled: !!user.id,
    },
  );
};

export const useGetCustomProductsCount = () => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Record<string, number>>(
    [productConstants.CUSTOM_PRODUCTS_COUNT_KEY],
    () => services.getCustomProductsCountAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};
