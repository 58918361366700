import { MouseEvent as ReactMouseEvent, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ButtonHTMLType } from 'antd/lib/button/button';

import { I18nEnum } from 'types';

type Colors = 'orange' | 'white';

interface Props {
  title: string | JSX.Element;
  className?: string;
  tabIndex?: number;
  href?: string;
  target?: string;
  color?: Colors;
  htmlType?: ButtonHTMLType;
  disabled?: boolean;
  onClick?: (e: ReactMouseEvent<any>) => void;
}

const InlineButton = ({
  title,
  className,
  href,
  tabIndex,
  target,
  color,
  htmlType,
  disabled,
  onClick,
}: Props) => {
  const intl = useIntl();
  const _title = useMemo(
    () =>
      typeof title === 'string' && Object.keys(I18nEnum).includes(title)
        ? intl.formatMessage({ id: title })
        : title,
    [intl, title],
  );

  return href ? (
    <A
      to={href}
      className={className}
      onClick={onClick}
      tabIndex={tabIndex}
      target={target}
      color={color}>
      {_title}
    </A>
  ) : (
    <Button
      className={className}
      onClick={onClick}
      tabIndex={tabIndex}
      color={color}
      type={htmlType}
      disabled={disabled}>
      {_title}
    </Button>
  );
};

const InlineButtonStyle = css<{ color?: Colors }>`
  display: inline-block;

  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.colors.blue};
  ${props => props.color === 'orange' && `color: ${props.theme.colors.orange};`}
  ${props => props.color === 'white' && `color: rgba(255, 255, 255, 0.8);`}
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.darkBlue};
    ${props => props.color === 'orange' && `color: ${props.theme.colors.darkOrange};`}
    ${props => props.color === 'white' && `color: ${props.theme.colors.white};`}
    text-decoration: underline;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const Button = styled.button<{ color?: Colors }>`
  ${InlineButtonStyle}
`;

const A = styled(Link)<{ color?: Colors }>`
  && {
    ${InlineButtonStyle}
  }
`;

export default InlineButton;
