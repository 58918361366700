import pick from 'lodash/pick';

import { removeURLParams } from 'utils';
import { I18nEnum, ThunkResult } from 'types';
import { intl } from 'intl';
import {
  QuickQuoteUserInfo,
  actions as quickQuoteActions,
  services as quickQuoteServices,
  StructureSlope,
  RoofleStructure,
} from 'modules/quickQuote';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { selectors as userSelectors } from 'modules/auth';
import { actions as financingActions } from 'modules/financing';
import { actions as widgetActions, selectors as widgetSelectors } from 'modules/widget';
import { actions as spinnerActions } from 'modules/spinner';
import { ColoredLine } from 'modules/product';
import { actions as messageActions, MessageTypes } from 'modules/message';
import {
  GoogleSearchResultType,
  actions as searchActions,
  selectors as searchSelectors,
} from 'modules/search';
import { actions as qqConfigActions } from 'modules/quickQuoteConfig';
import { googleMapsInstance } from 'modules/googleMaps/classes/GoogleMapsInstance';
import { setGoogleMapsIsEditing } from 'modules/googleMaps/actions';
import { LeadStatus, Statistics } from 'modules/dashboard';
import { EventAction, UserAction, trackUserAction } from 'modules/userAction';
import { actions as globalActions, WidgetAction } from 'modules/global';
import { getGeoDataDetails } from 'modules/search/actions';
import * as subscriptionSelectors from 'modules/subscription/selectors';
import {
  selectors as repQuotesSelectors,
  actions as repQuotesActions,
  services as repQuotesServices,
  constants,
} from '.';
import { CustomSlopes, UserWidgetSetting } from './types';

type UpdateSlopeCallback = ({ structure, slope }) => void;

export const setUserWidgetSettins = (userWidgetSettings: UserWidgetSetting) => ({
  type: constants.SET_USER_WIDGET_SETTINGS,
  userWidgetSettings,
});

export const _setSelectColoredLine = (coloredLine: { [key: string]: ColoredLine }) => ({
  type: constants.SET_SELECTED_COLOR_LINE,
  coloredLine,
});

export const setLeadSessionId = (leadSessionId: string | number) => ({
  type: constants.SET_LEAD_SESSION_ID,
  leadSessionId,
});

export const checkIsSlopeActive = structure => structure.slope in CustomSlopes;

export const updateCustomSlope =
  ({
    structure,
    updateSlope,
    initialStructure,
  }: {
    structure: RoofleStructure;
    updateSlope: UpdateSlopeCallback;
    initialStructure?: RoofleStructure;
  }): ThunkResult<void> =>
  dispatch => {
    dispatch(
      modalActions.openModal(ModalTypes.customSlope, {
        callback: (slope: StructureSlope) => updateSlope({ structure, slope }),
        slope: structure.slope,
        initialStructure,
      }),
    );
  };

export const createQuotes = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const { selectConfig } = await import('modules/quickQuoteConfig/selectors');
  const state = getState();

  const { saveLeadInfo } = repQuotesSelectors.selectUserWidgetSettings(state);
  const userInfo = pick(userSelectors.selectUser(state), [
    'firstName',
    'lastName',
    'email',
    'phone',
    'id',
  ]);
  const { wildcard } = widgetSelectors.selectWidget(state);
  const { trackAnalyticsCallback } = selectConfig(getState());

  if (saveLeadInfo) {
    dispatch(modalActions.openModal(ModalTypes.repQuotesAlmostDone));
    return;
  }

  if (userInfo) {
    dispatch(quickQuoteActions.setQuickQuoteUserInfo(userInfo as unknown as QuickQuoteUserInfo));
    dispatch(widgetActions.getPublicWidgetProducts(wildcard));
  }

  dispatch(
    trackAnalyticsCallback({
      widgetAction: null,
      userAction: { label: I18nEnum.CreateQuote },
    }),
  );
};

// todo: this needs to be branched for mapbox, but see comments in
// `repQuotes/quickQuoteConfig.ts` regarding this may be dead code
export const startMapEditing = (): ThunkResult<void> => dispatch => {
  console.warn('todo: not sure how to test this');
  dispatch(setGoogleMapsIsEditing({ isEditing: true }));
  googleMapsInstance.startEditor();
};

export const getPreApproved =
  ({ line, priceInfo }): ThunkResult<void> =>
  (dispatch, getState) => {
    const state = getState();

    const { saveLeadInfo } = repQuotesSelectors.selectUserWidgetSettings(state);
    const user = userSelectors.selectUser(state);
    const { leadSessionId } = repQuotesSelectors.selectRepQuotes(state);

    trackUserAction(user.id, {
      action: EventAction.buttonClick,
      label: I18nEnum.SendLoanInvitation,
      meta: { leadId: leadSessionId },
    });

    if (!saveLeadInfo) {
      dispatch(modalActions.openModal(ModalTypes.sendLoanInvitation, { line, priceInfo }));
      return;
    }

    dispatch(financingActions.inviteConsumerQuickQuote({ line, priceInfo }));
    dispatch(modalActions.openModal(ModalTypes.repQuotesInvitationSent, { line, priceInfo }));
  };

export const selectColoredLine = (coloredLine: ColoredLine, id: number) => dispatch => {
  dispatch(_setSelectColoredLine({ [id]: coloredLine }));
};

export const quickQuoteStartOver = (): ThunkResult<void> => dispatch => {
  dispatch(
    modalActions.openModal(ModalTypes.warningModal, {
      primaryAction: async () => {
        try {
          dispatch(clearSearchRepQuotes());
          dispatch(modalActions.closeModal());
          dispatch(
            messageActions.openMessage(
              MessageTypes.success,
              I18nEnum.RepQuotesHasBeenStartedOverSuccessfully,
            ),
          );
        } catch (error) {
          dispatch(
            messageActions.openMessage(
              MessageTypes.error,
              I18nEnum.UnfortunatelyTheRepQuoteHasNotBeenStartedOver,
            ),
          );
        }
      },
      subtitle: I18nEnum.AreYouSureYouWantToStartOver,
      description: intl.formatMessage({ id: I18nEnum.AllUnsavedRoofsSettingsWillBeLost }),
      primaryBtnText: I18nEnum.StartOver,
    }),
  );
};

export const quickQuoteCreateLead =
  (wildcard: string): ThunkResult<void> =>
  async (dispatch, getState) => {
    const subscriptionConfig = subscriptionSelectors.selectSubscriptionConfig(getState());
    dispatch(
      qqConfigActions.update({
        shouldTrackWidgetAnalytics: true,
        statistics: { ...subscriptionConfig.defaultRepQuoteConfigState.statistics, track: true },
      }),
    );
    await dispatch(widgetActions.getPublicWidgetProducts(wildcard));

    dispatch(repQuotesActions.upsertUserWidgetSettings({ saveLeadInfo: true }));
  };

export const checkExistingLead =
  (result: GoogleSearchResultType): ThunkResult<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const geoDataDetails = await dispatch(getGeoDataDetails(result));
    const address = geoDataDetails.formattedAddress;
    dispatch(spinnerActions.show());
    dispatch(repQuotesActions.setLeadSessionId(''));

    try {
      const { data } = await quickQuoteServices.getStatisticsForAddressAPI(
        user.id,
        encodeURIComponent(address),
      );
      const notDeletedLeads = (data as Statistics[]).filter(
        lead => lead.status !== LeadStatus.deleted,
      );

      if (notDeletedLeads.length) {
        dispatch(
          modalActions.openModal(ModalTypes.warningModal, {
            primaryAction: () => {
              trackUserAction(user.id, {
                action: EventAction.buttonClick,
                label: I18nEnum.Open,
                meta: { address },
              });
              if (notDeletedLeads.length > 1) {
                dispatch(
                  modalActions.openModal(ModalTypes.openExistingLead, {
                    leads: notDeletedLeads,
                    onClose: () => dispatch(searchActions.selectAddress(result)),
                    fromScratch: true,
                  }),
                );
              } else {
                const leadId = notDeletedLeads[0].id;
                dispatch(repQuotesActions.setLeadSessionId(leadId));
                dispatch(modalActions.closeModal());
                trackUserAction(user.id, {
                  action: EventAction.buttonClick,
                  label: I18nEnum.OpenExistingLead,
                  meta: { address, leadId },
                });
              }
            },
            secondaryAction: () => {
              dispatch(searchActions.selectAddress(result));
              dispatch(modalActions.closeModal());
              trackUserAction(user.id, {
                action: EventAction.buttonClick,
                label: I18nEnum.CreateNewLead,
                meta: { address },
              });
            },
            onClose: () => {
              dispatch(searchActions.selectAddress(result));
            },
            subtitle: intl.formatMessage({ id: I18nEnum.ThisLeadAlreadyExists }),
            description: intl.formatMessage({ id: I18nEnum.YouHaveInTheSystemTheLead }),
            primaryBtnText: I18nEnum.Open,
            secondaryBtnText: I18nEnum.CreateNewLead,
          }),
        );
      } else {
        dispatch(searchActions.selectAddress(result));
      }
    } catch (e) {
      dispatch(searchActions.selectAddress(result));
    }
    dispatch(spinnerActions.hide());
  };

export const getUserWidgetSettings = (): ThunkResult<void> => async (dispatch, getState) => {
  try {
    const user = userSelectors.selectUser(getState());

    const { data } = await repQuotesServices.getUserWidgetSettingsAPI(user.id);
    data && dispatch(setUserWidgetSettins(data));
  } catch (e) {}
};

export const upsertUserWidgetSettings =
  (body: Partial<UserWidgetSetting>): ThunkResult<void> =>
  async (dispatch, getState) => {
    try {
      const user = userSelectors.selectUser(getState());

      await repQuotesServices.upsertUserWidgetSettingsAPI(user.id, body);
      dispatch(getUserWidgetSettings());
    } catch (e) {}
  };

export const trackRepQuotesAnalytics =
  ({
    widgetAction,
    userAction,
  }: {
    widgetAction: WidgetAction | null;
    userAction: Partial<UserAction>;
  }): ThunkResult<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const address = searchSelectors.selectAddress(state);
    const { leadSessionId } = repQuotesSelectors.selectRepQuotes(state);

    trackUserAction(user.id, {
      action: userAction.action || EventAction.buttonClick,
      label: userAction.label || '',
      meta: { leadId: leadSessionId || undefined, address, ...userAction.meta },
    });

    dispatch(globalActions.trackWidgetAnalytics({ widgetAction }));
  };

export const clearSearchRepQuotes = (): ThunkResult<void> => async dispatch => {
  await dispatch(searchActions.clear());
  removeURLParams();
  dispatch(setLeadSessionId(''));
};
