import { UserState, AllPermissionsType, AllPermissions, Roles, User } from 'modules/auth/types';
import { AdminRoutes, UserRoutes } from 'types';

export const getUserFromTokenPayload = (token: string) => {
  try {
    const tokenPayload = token.split('.')[1];
    const user = JSON.parse(window.atob(tokenPayload));

    return user;
  } catch (error) {
    console.log(error);
  }
};

export const checkPermissions = ({
  user,
  permissionAccess,
  roleAccess,
}: {
  user: UserState;
  permissionAccess?: AllPermissionsType[];
  roleAccess?: Roles[];
}) => {
  if (!(user && user.id)) {
    return { hasCorrespondedPermission: false, hasCorrespondedRole: false };
  }
  const hasCorrespondedPermission = !!(
    permissionAccess &&
    permissionAccess.length &&
    user.role?.permissions.find(permission =>
      permissionAccess.find(item => item === permission.module),
    )
  );
  const hasCorrespondedRole = !!(
    roleAccess &&
    roleAccess.length &&
    roleAccess.find(role => role === user.role?.name)
  );
  return { hasCorrespondedPermission, hasCorrespondedRole };
};

export const isAdmin = (user: UserState) =>
  !!user.role && [Roles.admin, Roles.superAdmin].includes(user.role.name);

export const isSalesOrProductionRep = (user: UserState) =>
  !!user.role && [Roles.salesRep, Roles.productionRep].includes(user.role.name);

export const getUserFullName = (user?: User) => (user ? `${user.firstName} ${user.lastName}` : '');

const pages: {
  [k in UserRoutes]?: { permission: AllPermissionsType; backUpPage?: UserRoutes };
} = {
  [UserRoutes.dashboard]: {
    permission: AllPermissions.customerStatistics,
    backUpPage: UserRoutes.financingSettings,
  },
  [UserRoutes.repQuotes]: {
    permission: AllPermissions.repQuotes,
    backUpPage: UserRoutes.financingSettings,
  },
  [UserRoutes.financingSettings]: {
    permission: AllPermissions.financingSettings,
    backUpPage: UserRoutes.companyProfile,
  },
  [UserRoutes.companyProfile]: {
    permission: AllPermissions.companyProfile,
    backUpPage: UserRoutes.profile,
  },
  [UserRoutes.profile]: {
    permission: AllPermissions.userProfile,
  },
};

export const getUserStartPage = (user: UserState): AdminRoutes | UserRoutes => {
  if (isAdmin(user)) {
    return AdminRoutes.adminDashboard;
  }

  let pageToNavigate: UserRoutes = UserRoutes.dashboard;
  if (user.role.name === Roles.salesRep) {
    pageToNavigate = UserRoutes.repQuotes;
  }

  let hasPermission = false;

  while (!hasPermission) {
    const page = pages[pageToNavigate];

    hasPermission = user.role.permissions.some(permission => permission.module === page.permission);
    if (!hasPermission) {
      pageToNavigate = page.backUpPage;
    }
  }

  return pageToNavigate;
};
