import { actions as spinnerActions } from 'modules/spinner';
import { selectors as authSelectors } from 'modules/auth';
import { I18nEnum, ThunkResult } from 'types';
import { constants, services } from '.';
import { Snippet, SnippetTypeEnum, SnippetValue } from './types';
import { selectSnippets } from './selectors';
import { actions as modalActions } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';

const _setSnippets = (snippets: Snippet[]) => ({
  type: constants.SET_SNIPPETS,
  snippets,
});

export const _setSnippet = (
  snippetType: keyof typeof SnippetTypeEnum,
  snippet: SnippetValue<string | number>,
) => ({
  type: constants.SET_SNIPPET,
  snippetType,
  snippetValue: snippet,
});

export const _clearSnippetsToInsert = () => ({
  type: constants.CLEAR_SNIPPETS_TO_INSERT,
});

export const getSnippets = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(spinnerActions.show());
  try {
    const user = authSelectors.selectUser(getState());

    const { data } = await services.getSnippetsAPI(user.id);

    dispatch(_setSnippets(data));
  } catch (err) {}

  dispatch(spinnerActions.hide());
};

export const deleteSnippet =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const snippets = selectSnippets(getState());
    dispatch(spinnerActions.show());
    try {
      const user = authSelectors.selectUser(getState());

      await services.deleteSnippetAPI(user.id, id);

      dispatch(_setSnippets(snippets.filter(snippet => snippet.id !== id)));
      dispatch(modalActions.closeModal());
      dispatch(messageActions.openMessage(MessageTypes.success, I18nEnum.RecordHasBeenDeleted));
    } catch (err) {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          I18nEnum.UnfortunatelyTheRecordHasNotBeenDeleted,
        ),
      );
    }

    dispatch(spinnerActions.hide());
  };

export const createSnippet =
  (
    snippetType: keyof typeof SnippetTypeEnum,
    values: Partial<Snippet> & { productName?: string },
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const snippets = selectSnippets(getState());
    dispatch(spinnerActions.show());
    try {
      const user = authSelectors.selectUser(getState());

      const { data } = await services.createSnippetAPI(user.id, snippetType, values);

      dispatch(_setSnippets([data, ...snippets]));
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(MessageTypes.success, I18nEnum.SnippetHasBeenAddedSuccessfully),
      );
    } catch (err) {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          I18nEnum.UnfortunatelySnippetHasNotBeenCreated,
        ),
      );
    }

    dispatch(spinnerActions.hide());
  };

export const updateSnippet =
  (snippetId: number, values: Partial<Snippet>): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const snippets = selectSnippets(getState());
    dispatch(spinnerActions.show());

    try {
      const user = authSelectors.selectUser(getState());

      const { data }: { data: Snippet } = await services.updateSnippetAPI(
        user.id,
        snippetId,
        values,
      );

      const _snippets = snippets.map(snippet => {
        if (snippet.id === data.id) {
          return data;
        }

        return snippet;
      });

      dispatch(_setSnippets(_snippets));
      dispatch(modalActions.closeModal());
      dispatch(messageActions.openMessage(MessageTypes.success, I18nEnum.TheChangesHaveBeenSaved));
    } catch (err) {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved,
        ),
      );
    }

    dispatch(spinnerActions.hide());
  };
