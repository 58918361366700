import { Feature, Polygon } from 'geojson';
import { Properties } from '@turf/helpers';

import { MarketServiceZone, NationalServiceAreaDifferenceType, ServiceAreaType } from './types';

import api from 'services/api';
import { RequestMarketsAndStatesBodyType } from '.';

export const getLogisticsMarketsAPI = (userId: number) =>
  api.get(`admin/${userId}/logistics-markets`);

export const getUserMarketsAPI = (userId: number) => api.get(`user/${userId}/markets`);

export const getWidgetMarketBySlugAPI = (wildcard: string, slug: string) =>
  api.get(`widget/${wildcard}/market/${slug}`);

export const updateUserMarketAPI = (userId: number, slug: string, body: ServiceAreaType) =>
  api.post(`user/${userId}/markets/${slug}`, body);

export const updateUserStatesAPI = (userId: number, body: NationalServiceAreaDifferenceType) =>
  api.patch(`user/${userId}/states`, body);

export const resetUserMarketsAPI = (userId: number) => api.get(`user/${userId}/reset-markets`);

export const resetNationalLicenseAPI = (userId: number) =>
  api.get(`user/${userId}/reset-national-license`);

export const getAllMarketsAPI = (userId: number) => api.get(`user/${userId}/all-markets`);

export const requestMarketsAndStates = (userId: number, body: RequestMarketsAndStatesBodyType) =>
  api.post(`user/${userId}/markets-and-states/request`, body);

export const getAllStatesAPI = (userId: number, shouldIncludeMarkets = false) =>
  api.get(`user/${userId}/states/all`, { params: { shouldIncludeMarkets } });

export const getStatesByUserAPI = (userId: number) => api.get(`user/${userId}/states`);

export const buildCirclePolygons = (
  userId: number,
  body: {
    roofQuoteProStates: string[];
    circle: Feature<Polygon, Properties>;
    useStripedPolygon?: boolean;
    useRadiusFilledPolygon?: boolean;
  },
  abortController?: AbortController,
) => api.post(`user/${userId}/build-circle-poligons`, body, { signal: abortController?.signal });

export const buildTurfCircle = (
  userId: number,
  body: Pick<MarketServiceZone, 'centerpoint' | 'radiusInMiles'>,
  abortController?: AbortController,
) => api.post(`user/${userId}/build-turf-circle`, body, { signal: abortController?.signal });

export const getCountryStatesFeatureCollectionAPI = (userId: number) =>
  api.get(`user/${userId}/country-states-feature-collection`);

export const applyMarketSettingsAPI = (
  userId: number,
  originalMarketSlug: string,
  marketSlugs: string[],
) => api.post(`user/${userId}/market/${originalMarketSlug}/apply-settings`, { marketSlugs });
