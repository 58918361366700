import { useState, cloneElement, useCallback, useMemo, FC, CSSProperties } from 'react';
import {
  useFloating,
  useInteractions,
  useClick,
  useDismiss,
  FloatingPortal,
  autoUpdate,
  offset,
  shift,
  flip,
  Placement,
} from '@floating-ui/react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { I18nEnum } from 'types';
import Tooltip, { ITooltipProps } from './Tooltips/Tooltip';
import { useTrackMoreActionsItemClickAction } from 'modules/userAction';

export interface ActionType {
  img: JSX.Element;
  label: I18nEnum;
  disabled?: boolean;
  onClick?: () => any;
  Wrapper?: FC<{ children: JSX.Element; closeMenu: () => void }>;
  additionalInfoForTracking?: Record<string, unknown>;
}

export interface IPopupMenuProps {
  actions: ActionType[];
  icon?: JSX.Element;
  panelStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  disabled?: boolean;
  placement?: Placement;
  className?: string;
  tooltipSettings?: Partial<Omit<ITooltipProps, 'children'>>;
  floatingPortalRoot?: HTMLElement | null;
  includeFlip?: boolean;
  extraContent?: JSX.Element;
}

const PopupMenu = ({
  actions,
  icon = <MoreIcon src="/images/svg/more-icon.svg" />,
  panelStyle,
  iconStyle,
  disabled,
  placement = 'bottom-end',
  tooltipSettings,
  className,
  floatingPortalRoot,
  includeFlip,
  extraContent,
}: IPopupMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(10), shift({ mainAxis: false }), includeFlip ? flip() : undefined],
  });

  const dismiss = useDismiss(context);
  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const closeMenu = useCallback(() => setIsOpen(false), []);

  const referenceProps = useMemo(() => {
    const props = getReferenceProps();
    return { ...props, onClick: !disabled ? props.onClick : undefined };
  }, [getReferenceProps, disabled]);

  return (
    <>
      <Tooltip
        tooltip={tooltipSettings?.tooltip || <FormattedMessage id={I18nEnum.MoreActions} />}
        {...tooltipSettings}>
        {cloneElement(icon, {
          isOpen,
          ref: refs.setReference,
          style: iconStyle,
          ...referenceProps,
          className,
        })}
      </Tooltip>
      <FloatingPortal root={floatingPortalRoot}>
        {isOpen && (
          <ActionsPanel
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              ...panelStyle,
            }}
            {...getFloatingProps()}>
            {extraContent}
            {actions.map(action =>
              !action.Wrapper ? (
                <ActionItemComponent key={action.label} action={action} closeMenu={closeMenu} />
              ) : (
                <action.Wrapper key={action.label} closeMenu={closeMenu}>
                  <ActionItemComponent action={action} closeMenu={closeMenu} />
                </action.Wrapper>
              ),
            )}
          </ActionsPanel>
        )}
      </FloatingPortal>
    </>
  );
};

export const ActionItemComponent = ({
  action,
  closeMenu,
}: {
  action: ActionType;
  closeMenu: () => void;
}) => {
  const trackMoreActionsItemClickAction = useTrackMoreActionsItemClickAction();

  const handleClick = useCallback(() => {
    if (!action.disabled && action.onClick) {
      action.onClick();
      trackMoreActionsItemClickAction(action.label, action.additionalInfoForTracking);
      closeMenu();
    }
  }, [action, closeMenu, trackMoreActionsItemClickAction]);

  return (
    <ActionItem onClick={handleClick} disabled={action.disabled}>
      {action.img}
      <span>
        <FormattedMessage id={action.label} />
      </span>
    </ActionItem>
  );
};

const MoreIcon = styled.img`
  cursor: pointer;
  filter: ${props => props.theme.colorFilters.grey};
`;

const ActionsPanel = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 6px;
  padding: 8px 0;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 15;
`;

export const ActionItem = styled.span<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  color: ${props => props.theme.colors.black};
  padding: 0 12px;
  white-space: nowrap;
  cursor: pointer;
  column-gap: 8px;

  img {
    height: 20px;
    width: 20px;
  }

  ${props =>
    props.disabled &&
    `
    color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;

    &:hover {
      background-color: unset;
      transition: none;
    }
  `}

  @media ${props => props.theme.mediaQueries.small} {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
`;

export default PopupMenu;
