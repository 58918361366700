import { I18nEnum } from 'types';

// Tab keys used on BE in order to redirect a user to a specific tab from an email.
// Remember to change the corresponding enum on BE side when updating this one.
export enum DeveloperTabKeys {
  ToolScripts = 'ToolScripts',
  Webhooks = 'Webhooks',
  Integrations = 'Integrations',
}

export type WebhooksType = {
  statisticsUrl: string[];
  productRequestUrl: string[];
  addressOnlyUrl: string[];
};

export type WebhookType = keyof WebhooksType;

export enum WebhooksSelectOptions {
  statisticsUrl = 'statisticsUrl',
  addressOnlyUrl = 'addressOnlyUrl',
  productRequestUrl = 'productRequestUrl',
}

export type IntegrationType = {
  salesRabbitApiKey: string;
  salesRabbitEnabled: boolean;
};

export interface JobNimbusIntegration {
  apiKey: string;
  enabled: boolean;
  // Contact Workflow properties
  recordTypeId: number;
  statusId: number;
  sourceId: number;
  // Job Workflow properties
  jobRecordTypeId: number;
  jobStatusId: number;
}

export interface JobNimbusIntegrationType {
  entity: JobNimbusIntegration | null;
  settings: JobNimbusSettings | null;
}

export interface LeapIntegrationType {
  entity: LeapIntegration | null;
  settings: LeapSettings | null;
}

export interface OneClickContractorIntegrationType {
  entity: OneClickContractorIntegration | null;
  settings: OneClickContractorSettings | null;
}

export type WebhookRenderData = {
  description: I18nEnum;
  formItemName: string;
  placeholder: string;
  JSONExample: Record<string, any>;
};

export type EagleviewConnection = {
  id: number;
  name: string;
  isConnected: boolean;
  eagleviewUserId: string;
  markets: { id: number; name: string }[];
};

// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum MomntIntegrationStatus {
  NoIntegration = 'momnt_no_integration',
  VerificationHold = 'momnt_verification_hold',
  InIntake = 'momnt_in_intake',
  IntakePartialActive = 'momnt_intake_partial_active',
  IntakeDecline = 'momnt_intake_decline',
  Active = 'momnt_active',
}

// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum SalesRabbitIntegrationStatus {
  Cancelled = 'sr_cancelled',
  Active = 'sr_active',
  NoIntegration = 'sr_no_integration',
}

// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum GafIntegrationStatus {
  Trial = 'gaf_trial',
  Active = 'gaf_active',
  Inactive = 'gaf_inactive',
}

// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum EagleViewIntegrationStatus {
  NoIntegration = 'ev_no_integration',
  Active = 'ev_active',
}

// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum MLIntegrationStatus {
  NoIntegration = 'ml_no_integration',
  Active = 'ml_active',
}
// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum BeaconIntegrationStatus {
  NoIntegration = 'b_no_integration',
  Active = 'b_active',
}
// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum JobNimbusIntegrationStatus {
  NoIntegration = 'jn_no_integration',
  Active = 'jn_active',
}
// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum LeapIntegrationStatus {
  NoIntegration = 'leap_no_integration',
  Active = 'leap_active',
  Inactive = 'leap_inactive',
}
// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum OneClickContractorIntegrationStatus {
  NoIntegration = 'one_click_contractor_no_integration',
  Active = 'one_click_contractor_active',
  Inactive = 'one_click_contractor_inactive',
}
// The enum used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export enum GroupedIntegrationStatus {
  All = 'all',
  None = 'none',
  Momnt = 'momnt',
  SalesRabbit = 'sr',
  Gaf = 'gaf',
  EagleView = 'ev',
  ML = 'ml',
  Beacon = 'b',
  JobNimbus = 'jn',
  Leap = 'leap',
  OneClickContractor = 'occ',
}
// The type used on BE side. Remember to change the corresponding enum on BE side when updating this one.
export type AllIntegrationStatuses =
  | GroupedIntegrationStatus
  | MomntIntegrationStatus
  | SalesRabbitIntegrationStatus
  | GafIntegrationStatus
  | EagleViewIntegrationStatus
  | MLIntegrationStatus
  | BeaconIntegrationStatus
  | JobNimbusIntegrationStatus
  | LeapIntegrationStatus;

export interface BeaconConnection {
  id: number;
  username: string;
  isConnected: boolean;
}

export interface Creds {
  id?: number;
  username: string;
  password: string;
}

export interface JobNimbusSettings {
  workflows: JobNimbusWorkflow[];
  fileTypes: JobNimbusFileType[];
  taskTypes: JobNimbusTaskType[];
  custom_fields_job: JobNimbusCustomFieldsJob[];
  custom_fields_contact: JobNimbusCustomFieldsContact[];
  custom_fields_workorder: any[];
  locations: JobNimbusLocation[];
  activityTypes: JobNimbusActivityType[];
  enabled_features: JobNimbusFeature[];
  country_name: string;
  engage: JobNimbusEngage;
  contact_display_name_template: string;
  budget: JobNimbusBudget;
  sources: JobNimbusSource[];
  profiles: JobNimbusProfile[];
}

export interface JobNimbusWorkflow {
  id: number;
  order: number;
  object_type: 'contact' | 'workorder' | 'job';
  name: string;
  is_sub_contractor: boolean;
  is_supplier: boolean;
  is_active: boolean;
  can_access_by_all: boolean;
  is_vendor: boolean;
  status: JobNimbusWorkflowStatus[];
  icon: string;
}

export interface JobNimbusWorkflowStatus {
  id: number;
  name: string;
  stage: string;
  is_active: boolean;
  is_archived: boolean;
  send_to_quickbooks: boolean;
  order: number;
  profile_ids: number[];
}

export interface JobNimbusFileType {
  FileTypeId: number;
  TypeName: string;
  IsActive: boolean;
}

export interface JobNimbusTaskType {
  TaskTypeId: number;
  TypeName: string;
  DefaultName: string;
  Icon: string;
  HideFromTaskList: boolean;
  HideFromCalendarView: boolean;
  IsActive: boolean;
}

export interface JobNimbusCustomFieldsJob {
  field: string;
  title: string;
  type: string;
  visible: boolean;
  is_currency: boolean;
  options: string[];
  is_ddn: boolean;
  is_required: boolean;
}

export interface JobNimbusCustomFieldsContact {
  field: string;
  title: string;
  type: string;
  visible: boolean;
  is_currency: boolean;
  options: string[];
  is_ddn: boolean;
  is_required: boolean;
}

export interface JobNimbusLocation {
  id: number;
  gohighlevel_location_id: any | null;
  hubspot_id: string;
  name: string;
  date_updated_for_gohighlevel: number;
  code: any | null;
  address_line1: string | null;
  address_line2: string | null;
  city: string;
  state_text: string | null;
  country_name: string;
  zip: string;
  phone: string;
  logo_id: any | null;
  header_color: string;
  is_active: boolean;
  calendar: {
    weekends: boolean;
    start_day: number;
    business_hours: {
      start: number;
      end: number;
    };
  };
}

export interface JobNimbusActivityType {
  ActivityTypeId: number;
  TypeName: string;
  ShowInJobShare: boolean;
  IsActive: boolean;
}

export type JobNimbusFeature =
  | 'Profile Based Access'
  | 'QuickBooks'
  | 'Material Order'
  | 'Work Order'
  | 'Central Documents'
  | 'Work Order Scheduling'
  | 'Sub-Contractor'
  | 'Budget'
  | 'Contact Scheduling'
  | 'Job Scheduling';

export interface JobNimbusEngage {
  beta: {
    enabled_by: any | null;
    enabled_date: number;
    end_date: number;
  };
  plan: {
    id: any | null;
    tier_id: any | null;
    price: number;
  };
}

export interface JobNimbusBudget {
  template: any[];
  commissions: any[];
  required_gross_margin: number;
  required_net_margin: number;
}

export interface JobNimbusSource {
  JobSourceId: number;
  SourceName: string;
  IsActive: boolean;
}

export interface JobNimbusProfile {
  id: number;
  name: string;
  level: JobNimbusLevel;
  estimate: JobNimbusVCED[];
  workorder: JobNimbusVCED[];
  materialorder: JobNimbusVCED[];
  proposal: JobNimbusVCED[];
  invoice: JobNimbusVCED[];
  creditmemo: JobNimbusVCED[];
  central_document: JobNimbusVCED[];
  budget: JobNimbusVCED[];
  features: string[];
}

export type JobNimbusLevel = 'full' | 'limited';
export type JobNimbusVCED = 'v' | 'c' | 'e' | 'd';

export interface LeapIntegration {
  apiKey: string;
  enabled: boolean;
  workflowStageId: string;
}

export interface LeapSettings {
  workflowStages: LeapWorkflowStage[];
}

export interface LeapWorkflowStage {
  id: number;
  code: string;
  workflow_id: number;
  name: string;
  locked: number;
  position: number;
  color: string;
  options: any[];
  created_at: any[];
  updated_at: any[];
  jobs_count: number;
}

export interface OneClickContractorIntegration {
  username: string;
  password: string;
  enabled: boolean;
  jobTypeId: number | null;
}

export interface OneClickContractorSettings {
  jobTypes: OneClickContractorJobType[];
}

export interface OneClickContractorJobType {
  id: number;
  kind: string;
  name: string;
  org_id: number | null;
  description: string | null;
  color: string | null;
  created_at: string;
  updated_at: string;
  org_job_object_link: {
    sort_order: number;
    id?: number;
    org_id?: number;
    object_id?: number;
  };
}
