import { ReactNode, useCallback, useMemo, FC, HTMLAttributes } from 'react';
import { Table } from 'antd';
import debounce from 'lodash/debounce';
import { TableProps as _TableProps, ColumnType } from 'antd/es/table';

import NoResultsBase from 'components/NoResults';
import styled from 'styled-components';
import { I18nEnum, SortingType, ListQuery } from 'types';

const DEBOUNCE_DELAY_MS = 500;

export type ColumnsType<T> = ColumnType<T>[];

export const sortOptions: {
  [key: string]: any;
} = {
  ascend: SortingType.asc.toUpperCase(),
  descend: SortingType.desc.toUpperCase(),
};

export const initialPagination = {
  show: 10,
  page: 1,
};

export const initialQuery: ListQuery = {
  search: '',
  pagination: initialPagination,
  sorting: {},
};

export interface TableProps<RecordType> extends _TableProps<RecordType> {
  columns: ColumnsType<RecordType>;
  shadowed?: boolean;
  onTableChange?: _TableProps<RecordType>['onChange'];
  debounceTime?: number;
  noResultsTitle?: I18nEnum;
  noResultsDescription?: I18nEnum | ReactNode;
  shouldIgnoreOnCell?: boolean;
}

const TableComponent = <RecordType,>({
  className,
  dataSource,
  columns: _columns,
  loading,
  pagination = false,
  onTableChange = () => {},
  debounceTime = DEBOUNCE_DELAY_MS,
  noResultsTitle = I18nEnum.NoData,
  noResultsDescription = I18nEnum.WeAreUnableToUploadData,
  shouldIgnoreOnCell, // tables with grouped headers call onCell incorrectly
  ...props
}: TableProps<RecordType>) => {
  const _onTableChange = useMemo(
    () => debounce(onTableChange, debounceTime),
    [onTableChange, debounceTime],
  );

  const getRowId = useCallback(
    (record, index) => record.id || (index !== undefined && index.toString()),
    [],
  );

  const columns = useMemo(
    () =>
      shouldIgnoreOnCell
        ? _columns
        : _columns?.map(column => {
            const attributeValue = column.dataIndex || column.key;
            return {
              ...column,
              onCell: () => ({ 'data-column': attributeValue } as HTMLAttributes<unknown>), // onCell function allows only existing HTMLAttributes
              onHeaderCell: () => ({ 'data-header': attributeValue } as HTMLAttributes<unknown>), // onHeaderCell function allows only existing HTMLAttributes
            };
          }),
    [_columns, shouldIgnoreOnCell],
  );

  return (
    <>
      {loading || (dataSource && dataSource.length) ? (
        <TableStyled<FC<TableProps<RecordType>>>
          className={className}
          dataSource={dataSource}
          columns={columns}
          rowKey={getRowId}
          onChange={_onTableChange}
          loading={loading}
          pagination={pagination}
          {...props}
        />
      ) : (
        <NoResults
          className={className}
          title={noResultsTitle}
          subtitle={noResultsDescription}
          icon={<img src="/images/svg/search-no-results-icon.svg" height="58px" width="58px" />}
        />
      )}
    </>
  );
};

const tableColumnShadow = 'inset -30px 0px 15px 2px';

const Shadow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  box-shadow: ${tableColumnShadow} ${props => props.theme.colors.white};
`;

const TableStyled = styled(Table)<{ shadowed?: boolean }>`
  .ant-table {
    border-radius: 6px;
    box-shadow: ${props => (props.shadowed ? '0px 4px 4px rgba(0, 0, 0, 0.14)' : 'none')};
  }
  .ant-table-content {
    border-radius: 6px;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${props => props.theme.colors.orange};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #fce5da;
  }

  .ant-spin-dot-item {
    background-color: ${props => props.theme.colors.orange};
  }

  .ant-spin-text {
    color: ${props => props.theme.colors.darkBlue};
    font-weight: 500;
  }

  .ant-pagination-item-active {
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link,
  .ant-pagination-item-link,
  .ant-pagination-item-link:hover,
  .ant-pagination-item-link:focus {
    border: 0;
    background: inherit;
    color: ${props => props.theme.colors.blue};
  }

  td.ant-table-cell {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  th.thead-first-line {
    border-bottom: 0 !important;
    height: 64px;
  }

  .ant-pagination-item {
    border-radius: 50%;
    background-color: inherit;
    border: 0;

    &-active {
      border: 1px solid ${props => props.theme.colors.blue};
    }

    & a {
      color: ${props => props.theme.colors.blue};
    }
  }

  ${props =>
    props.bordered &&
    `
    .ant-table-tbody .ant-table-cell {
      
    }
  `}

  .ant-table-thead > tr > th {
    padding: 8px 10px 6px 16px;
    background-color: ${props => props.theme.colors.white};
    vertical-align: top;
  }

  .ant-table-column-sorters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }

  .ant-table-row .ant-table-cell {
    background-color: ${props => props.theme.colors.white};
  }

  .ant-table-row:nth-child(2n + 0) {
    background-color: ${props => props.theme.colors.lightBeige};

    ${Shadow} {
      box-shadow: ${tableColumnShadow} ${props => props.theme.colors.lightBeige};
    }

    .ant-table-cell {
      background-color: ${props => props.theme.colors.lightBeige};
    }
  }

  .ant-table-row-expand-icon,
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: ${props => props.theme.colors.orange};
    border-color: ${props => props.theme.colors.grey};
  }

  .ant-table.ant-table-bordered > .ant-table-container,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
    border: 0;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border: 0;
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
    border: 0;
  }

  .ant-table.ant-table-ping-left
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > .ant-table-cell-fix-left-last {
    border-right-width: 0;
  }

  .ant-table-row-expand-icon-cell {
    width: 50px;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    &.ant-table-row-expand-icon-cell,
    &.ant-table-selection-column {
      border-right: none;
    }
  }

  .ant-table-content::-webkit-scrollbar-track {
    border-radius: 0 0 6px 6px;
  }

  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container:before {
    border-radius: 6px 0 0 6px;
  }

  .ant-table-cell.ant-table-selection-column {
    padding: 16px 0px 8px;
  }

  th.ant-table-cell.ant-table-selection-column {
    padding-top: 23px;
  }

  .ant-table-selection > .ant-checkbox > .ant-checkbox-inner:after {
    left: 25%;
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: rgba(0, 0, 0, 0.4);
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgba(0, 0, 0, 0.4);
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border: 1.6px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(236, 93, 21, 0.8);
    border-color: rgba(236, 93, 21, 0.8) !important;
  }

  .ant-checkbox-checked:after {
    border: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    width: 6px;
    height: 13px;
    top: 40%;
    left: 30%;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: rgba(236, 93, 21, 0.8);
    width: 14px;
    height: 14px;
    border-radius: 4px;
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first:after {
    box-shadow: none;
  }
`;

const NoResults = styled(NoResultsBase)`
  overflow: auto;
  padding: 32px 0;
`;

export default TableComponent;
