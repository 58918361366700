import store from 'store';

import { FinancingSetting, OfferCode, RateSheet } from 'modules/financing/types';
import { Line } from 'modules/product';
import {
  RoofleStructure,
  QuickQuoteState,
  selectors as quickQuoteSelectors,
  CommonStructureInformation,
  StructureSlope,
} from 'modules/quickQuote';
import { PublicQuoteSettingsModel } from 'modules/quoteSettings/types';
import { QuickQuoteProductPriceSettings, SquareFeetEnum } from 'modules/repQuotes';
import { selectors as companySelectors } from 'modules/company';
import { SearchAddressFeature, selectors as searchSelectors } from 'modules/search';
import { selectors as financingSelectors } from 'modules/financing';
import MeasurementsReportAdapter from 'services/MeasurementsReportAdapter';
import { ReportType } from 'modules/dashboard';

import { State } from 'types';
import {
  formatRoofleStructureToCommon,
  getPaymentMultiplier,
  isMeasurementReportSelectForTotalSqFt,
} from './utils';
import { PricesParams } from '../types';

export class PriceConfig {
  constructor({
    squareFeetPrice = 0,
    fixedPrice,
    state,
    selectQQFinancing = true,
    unavailableStates,
    line,
    orders,
  }: PricesParams) {
    this.squareFeetPrice = squareFeetPrice;
    this.fixedPrice = Number((squareFeetPrice * 100).toFixed(2));
    this.isFixedPrice = fixedPrice;
    this.orders = orders || [];
    this.product = line;
    this.state = state || store.getState();
    this.selectQQFinancing = selectQQFinancing;
    this.unavailableStates = unavailableStates || [];
  }

  readonly fixedPrice: number;

  readonly isFixedPrice: boolean;

  readonly orders: ReportType[];

  readonly product?: Line;

  readonly selectQQFinancing: boolean;

  readonly state: State;

  readonly squareFeetPrice: number;

  readonly unavailableStates: string[];

  get addressFeature(): SearchAddressFeature | null {
    return searchSelectors.selectAddressFeature(this.state);
  }

  get centerpointState(): string {
    return this.selectQQFinancing ? this.quickQuote.centerpointState || '' : '';
  }

  get companyQuoteSettings(): PublicQuoteSettingsModel | null {
    return companySelectors.selectCompanyQuoteSettings(this.state);
  }

  get financing(): FinancingSetting {
    return this.selectQQFinancing
      ? quickQuoteSelectors.selectQuickQuoteMarketFinancing(store.getState())
      : financingSelectors.selectDefinedBasicFinancing(store.getState());
  }

  get includedStructures(): CommonStructureInformation[] {
    const { type, value, uniqId } = this.productPriceSettings?.squareFeet || {
      type: SquareFeetEnum.InstantQuote,
      value: {},
    };

    if (!isMeasurementReportSelectForTotalSqFt(type)) {
      return this.quickQuote.structures
        .filter((structure: RoofleStructure) => structure.isIncluded)
        .map((structure: RoofleStructure) => {
          const isCustomSquareFeet = type === SquareFeetEnum.Custom;

          const squareFeet = isCustomSquareFeet
            ? value[structure.geoJsonPolygon.id as string]
            : structure.measurements.squareFeet;
          const wholeSquareFeet = isCustomSquareFeet
            ? value[structure.geoJsonPolygon.id as string]
            : structure.measurements.wholeSquareFeet;

          return formatRoofleStructureToCommon(structure, { squareFeet, wholeSquareFeet });
        });
    }

    const selectedOrder = this.orders.find(item => item.id === uniqId);

    if (!selectedOrder) {
      return [];
    }

    return new MeasurementsReportAdapter(selectedOrder).structures.map(structure => ({
      ...structure,
      wholeSquareFeet: structure.squareFeet,
      initialSquareFeet: structure.squareFeet,
    }));
  }

  get includedStructuresTotalSquareFeet(): number {
    const totalSquareFeet = this.includedStructures.reduce(
      (sqft, structure) => sqft + structure.wholeSquareFeet,
      0,
    );

    return Math.ceil(totalSquareFeet);
  }

  get isUserDetailsProvided(): boolean {
    return quickQuoteSelectors.selectUserDetailsProvided(this.state);
  }

  get offerCodes(): OfferCode[] {
    return financingSelectors.selectOfferCodes(store.getState()).data;
  }

  get paymentMultiplier(): number {
    return getPaymentMultiplier(this.financing.percentageRate, this.financing.months);
  }

  get productPriceSettings(): QuickQuoteProductPriceSettings | null {
    return quickQuoteSelectors.selectProductPriceSettings(this.state);
  }

  get quickQuote(): QuickQuoteState {
    return quickQuoteSelectors.selectQuickQuote(this.state);
  }

  get rateSheets(): RateSheet[] {
    return financingSelectors.selectRateSheets(store.getState());
  }
}

export class OriginalPriceConfig extends PriceConfig {
  get productPriceSettings(): null {
    return null;
  }
}

export class InstantQuotePriceConfig extends PriceConfig {
  get includedStructures(): CommonStructureInformation[] {
    return this.quickQuote.structures
      .filter((structure: RoofleStructure) => structure.isIncluded)
      .map(structure => formatRoofleStructureToCommon(structure));
  }
}
