import { Suspense } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import whatInput from 'what-input';

import Layout from 'components/Layout/Layout';
import InitRedux from './global/components/InitRedux';
import ProtectedRoute from './router/protectedRouter';
import Modals from './modal/components/Modals';
import Spinner from './spinner/components/Spinner';
import Message from './message/components/Message';
import routes, { previewRoutes, publicRoutes } from './router/routes';
import 'antd/dist/antd.min.css';
import '../styles.css';
import WarningList from 'modules/global/components/WarningList';
import Head from 'components/Helmet/Helmet';
import ErrorBoundary from 'components/ErrorBoundary';
import PreviewRouter from './router/previewRouter';
import Provider from './Provider';
import store from 'store';

whatInput.specificKeys([9]);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {publicRoutes.map((route, index) => (
        <Route
          path={route.path}
          key={index}
          element={
            <>
              <InitRedux />
              <Modals />
              <ErrorBoundary>
                <Suspense>
                  <route.Component />
                </Suspense>
              </ErrorBoundary>
            </>
          }
        />
      ))}
      {previewRoutes.map((route, index) => (
        <Route
          path={route.path}
          key={index}
          element={
            <>
              <InitRedux />
              <PreviewRouter>
                <ErrorBoundary>
                  <Suspense>
                    <route.Component />
                  </Suspense>
                </ErrorBoundary>
              </PreviewRouter>
            </>
          }
        />
      ))}
      {routes.map((route, ind) => (
        <Route
          key={ind}
          path={route.path}
          element={
            <>
              <InitRedux />
              <ProtectedRoute
                permissionAccess={route.permissionAccess}
                roleAccess={route.roleAccess}
                isAuthenticationRestricted={route.isAuthenticationRestricted}
              />
            </>
          }>
          {!!(route.permissionAccess || route.roleAccess) ? (
            <Route
              path={route.path}
              key={ind}
              element={
                <Layout protectedRoute={true}>
                  <Modals />
                  <WarningList />
                  <ErrorBoundary>
                    <Suspense>
                      <route.Component />
                    </Suspense>
                  </ErrorBoundary>
                </Layout>
              }
            />
          ) : (
            <Route
              path={route.path}
              key={ind}
              element={
                <>
                  <Modals />
                  <ErrorBoundary>
                    <Suspense>
                      <route.Component />
                    </Suspense>
                  </ErrorBoundary>
                </>
              }
            />
          )}
        </Route>
      ))}
    </>,
  ),
);

function App() {
  return (
    <Provider store={store}>
      <Head title="Loading..." />
      <Spinner />
      <Message />
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
