import { I18nEnum, UserRoutes } from 'types';
import { intl } from 'intl';
import {
  Notification,
  INotification,
  FormattedNotification,
  NotificationTypes,
} from 'modules/notifications/types';

const imagesPath = '/images/notifications';

export const buildNotifications = (notifications: Notification[]): FormattedNotification[] =>
  notifications.map(notification => {
    switch (notification.type) {
      case NotificationTypes.Markets:
        return buildMarketsNotification(notification);
      default:
        return {
          ...notification,
          title: '',
          description: '',
          icon: '',
          navigateTo: UserRoutes.dashboard,
        };
    }
  });

const buildMarketsNotification = (
  notification: INotification<typeof NotificationTypes.Markets>,
): FormattedNotification => {
  const marketsLength = notification.body.markets.length;
  return {
    ...notification,
    title: intl.formatMessage({ id: I18nEnum.NewMarketsAddedTitle }, { marketsLength }),
    description: intl.formatMessage(
      { id: I18nEnum.NewMarketsAddedDescription },
      {
        marketsLength,
        markets: notification.body.markets.join(', '),
      },
    ),
    icon: `${imagesPath}/markets-added-icon.svg`,
    navigateTo: UserRoutes.marketsSettings,
  };
};
