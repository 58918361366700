import { Feature, GeometryCollection } from 'geojson';
import { Properties } from '@turf/helpers';
import isEmpty from 'lodash/isEmpty';
import { intl } from 'intl';

import { I18nEnum, ThunkResult } from 'types';
import {
  constants,
  LogisticsMarkets,
  Market,
  MarketsList,
  State,
  selectors as marketSelectors,
  RequestMarketsAndStatesBodyType,
  NationalServiceAreaDifferenceType,
  ServiceAreaType,
  services,
  queries,
} from '.';
import { selectors as userSelectors } from 'modules/auth';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { actions as spinnerActions } from 'modules/spinner';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { selectQuickQuote } from 'modules/quickQuote/selectors';
import { filterObject } from 'utils';
import { selectWidget } from 'modules/widget/selectors';
import { invalidateMarketQuoteSettings } from 'modules/quoteSettings/queries';

export const _setMarkets = (markets: Market[], replaceSelectedMarket = true) => ({
  type: constants.SET_MARKETS,
  markets,
  replaceSelectedMarket,
});

export const _setMarketsList = (marketsList: MarketsList) => ({
  type: constants.SET_MARKETS_LIST,
  marketsList,
});

export const _setLogisticsMarkets = (logisticsMarkets: LogisticsMarkets) => ({
  type: constants.SET_LOGISTICS_MARKETS,
  logisticsMarkets,
});

export const _setSelectedMarket = (selectedMarket: Market) => ({
  type: constants.SET_SELECTED_MARKET,
  selectedMarket,
});

export const _setStates = (states: State[]) => ({
  type: constants.SET_STATES,
  states,
});

export const _setUsersStates = (usersStates: State[]) => ({
  type: constants.SET_USERS_STATES,
  usersStates,
});

export const _setCountryStatesFeatureCollection = (countryStatesFeatureCollection: {
  features: Feature<GeometryCollection, Properties>[];
}) => ({
  type: constants.SET_COUNTRY_STATES_FEATURE_COLLECTION,
  countryStatesFeatureCollection,
});

export const getUserMarkets = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const user = userSelectors.selectUser(getState());
  queries.invalidateMarketsAccounts();

  const { data }: { data: Market[] } = await services.getUserMarketsAPI(user.id);

  dispatch(_setMarkets(data));
};

export const getLogisticsMarkets = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const user = userSelectors.selectUser(getState());

  const { data }: { data: LogisticsMarkets } = await services.getLogisticsMarketsAPI(user.id);

  dispatch(_setLogisticsMarkets(data));
};

export const getStates =
  (shouldIncludeMarkets = false): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const states = marketSelectors.selectStates(state);

    if (
      !isEmpty(states) &&
      shouldIncludeMarkets &&
      states.some(_state => !!_state.includedMarkets?.length)
    ) {
      return;
    }

    const { data }: { data: State[] } = await services.getAllStatesAPI(
      user.id,
      shouldIncludeMarkets,
    );

    dispatch(_setStates(data));
  };

export const getUserStates = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const user = userSelectors.selectUser(state);

  const { data }: { data: State[] } = await services.getStatesByUserAPI(user.id);

  dispatch(_setUsersStates(data));
};

export const getNationalServiceAreaData = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch(spinnerActions.show());
  await Promise.all([dispatch(getStates()), dispatch(getUserStates())]);
  dispatch(spinnerActions.hide());
};

export const getMarketsInitialData = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch(spinnerActions.show());
  await Promise.all([dispatch(getStates()), dispatch(getLogisticsMarkets())]);
  dispatch(spinnerActions.hide());
};

export const updateUserMarket =
  ({
    marketSlug,
    body,
    onSave,
  }: {
    marketSlug: string;
    body: ServiceAreaType;
    onSave: () => void;
  }): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());
    dispatch(spinnerActions.show());

    try {
      const { data }: { data: Market } = await services.updateUserMarketAPI(
        user.id,
        marketSlug,
        body,
      );

      dispatch(_setSelectedMarket(data));
      dispatch(getUserMarkets());
      dispatch(showUpdateServiceAreaSuccessModal);
      onSave();
    } catch (_) {
      // TODO: add error modal
    }
    dispatch(spinnerActions.hide());
  };

export const updateUserStates = (
  statesChanges: NationalServiceAreaDifferenceType,
): ThunkResult<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    const state = getState();
    const user = userSelectors.selectUser(state);
    const userStates = marketSelectors.selectUserStates(state);

    const { data }: { data: State[] } = await services.updateUserStatesAPI(
      user.id,
      filterObject(statesChanges, (isActive, stateId) => {
        const isInitialSelected = userStates.some(userState => userState.id === +stateId);
        return isInitialSelected !== isActive;
      }),
    );
    dispatch(_setUsersStates(data));

    await dispatch(getUserMarkets());
    invalidateMarketQuoteSettings();

    dispatch(showUpdateServiceAreaSuccessModal);
    dispatch(spinnerActions.hide());
  };
};

const showUpdateServiceAreaSuccessModal = dispatch => {
  dispatch(
    modalActions.openModal(ModalTypes.successModal, {
      subtitle: intl.formatMessage({ id: I18nEnum.TheChangesHaveBeenSaved }),
      description: intl.formatMessage({
        id: I18nEnum.AllYourSettingsConfigurationHaveBeenAppliedToTheWidget,
      }),
    }),
  );
};

export const resetUserMarkets = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const user = userSelectors.selectUser(getState());
  dispatch(spinnerActions.show());

  const { data }: { data: Market[] } = await services.resetUserMarketsAPI(user.id);

  dispatch(_setMarkets(data));
  dispatch(modalActions.closeModal());
  dispatch(spinnerActions.hide());
};

export const resetNationalLicense =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());
    dispatch(spinnerActions.show());

    const { data }: { data: State[] } = await services.resetNationalLicenseAPI(user.id);

    dispatch(_setUsersStates(data));

    await dispatch(getUserMarkets());
    invalidateMarketQuoteSettings();

    dispatch(modalActions.closeModal());
    dispatch(spinnerActions.hide());
  };

export const requestMarketsAndStates =
  (body: RequestMarketsAndStatesBodyType): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());
    dispatch(spinnerActions.show());

    try {
      await services.requestMarketsAndStates(user.id, body);
      dispatch(messageActions.openMessage(MessageTypes.success, I18nEnum.YourRequestHasBeenSent));
      dispatch(modalActions.closeModal());
    } catch (err) {
      dispatch(
        messageActions.openMessage(MessageTypes.error, I18nEnum.UnfortunatelyTheRequestHasNot),
      );
    }

    dispatch(spinnerActions.hide());
  };

export const getMarketsAndStatesInitialState =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    const state = getState();
    const user = userSelectors.selectUser(state);
    const markets = marketSelectors.selectMarketsList(state);
    let marketsRequestPromise;

    try {
      if (isEmpty(markets)) {
        marketsRequestPromise = services.getAllMarketsAPI(user.id);
      }

      const [marketsResponse, { data: userMarkets }] = await Promise.all([
        marketsRequestPromise,
        services.getUserMarketsAPI(user.id),
        dispatch(getUserStates()),
        dispatch(getStates(true)),
      ]);

      const marketsList = marketsResponse?.data || markets;

      dispatch(_setMarketsList(marketsList));
      dispatch(_setMarkets(userMarkets));
    } finally {
      dispatch(spinnerActions.hide());
    }
  };

export const getQuickQuoteMarket = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { wildcard } = selectWidget(state);
  const { marketSlug } = selectQuickQuote(state);

  if (marketSlug && wildcard) {
    const { data: market }: { data: Market } = await services.getWidgetMarketBySlugAPI(
      wildcard,
      marketSlug,
    );

    dispatch(_setMarkets([market], false));
  }
};

export const getCountryStatesFeatureCollection =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const { data } = await services.getCountryStatesFeatureCollectionAPI(user.id);
    dispatch(_setCountryStatesFeatureCollection(data));
  };
