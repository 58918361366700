import { FeatureCollection, MultiPolygon } from 'geojson';

import { FinancingSetting } from 'modules/financing/types';
import { DBProduct, Line } from 'modules/product/types';
import { StructureSlopeEnum } from '../quickQuote';
import { SubscriptionCountry } from '../subscription';

export type LogisticsMarkets = {
  [key: string]: LogisticsMarket;
};

export interface MarketCenterpoint {
  dms: string;
  latitude: number;
  longitude: number;
}

export interface MarketServiceZone {
  centerpoint: MarketCenterpoint;
  id: string;
  name: string;
  radiusInMiles: number;
  roofQuoteProStates: string[];
}

export interface LogisticsMarket {
  allowedProductTypes: string[];
  displayName: string;
  id: string;
  pricePerSquare: number;
  requiresIceWaterShield: boolean;
  serviceZones: MarketServiceZone[];
  slug: string;
  country: SubscriptionCountry;
}

export interface CompanyMarket {
  id: number;
  centerpointLatitude: number;
  centerpointLongitude: number;
  isDisabled: boolean;
  isStandalone: boolean;
  includedStates: string[];
  multiplier: number;
  name: string;
  radius: number;
  slug: string;
  statesWithinRadius: string[];
  financingSetting: FinancingSetting | null;
  products?: DBProduct[];
  lines?: Line[];
  state?: State;
  beaconAccountId: string;
  quoteSettings?: {
    slope: StructureSlopeEnum;
  };
}

export interface MarketState {
  markets: Market[];
  logisticsMarkets: LogisticsMarket[];
  selectedMarket?: Market;
  marketsList: MarketsList;
  states: State[];
  userStates: CompanyState[];
  countryStatesFeatureCollection: FeatureCollection<MultiPolygon> | null;
}

export type MarketsList = { displayName: string; slug: string; includedStates: string[] }[];

export interface Market {
  market: CompanyMarket;
  logisticsMarket?: LogisticsMarket;
}

export interface State {
  id: number;
  name: string;
  includedMarkets?: LogisticsMarket[];
}

export interface CompanyState extends State {
  markets: CompanyMarket[];
}

export type RequestMarketsAndStatesBodyType = {
  updatedMarkets: {
    added: string[];
    removed: string[];
  };
  updatedStates: {
    added: number[];
    removed: number[];
  };
  isNationalLicenseActive: boolean;
};

export enum MarketsSettingsTabKeys {
  MyMarkets = 'MyMarkets',
  ServiceArea = 'ServiceArea',
  NationalCoverage = 'NationalCoverage',
  UpdateMarkets = 'UpdateMarkets',
}

export interface UpdatedMarketServiceZone extends MarketServiceZone {
  customCenterpoint: MarketCenterpoint;
  originalRadiusInMiles: number;
  allIncludedStates: string[];
}

export type SelectedItemsStateMap = {
  [key: string]: {
    isInitiallySelected: boolean;
    isAdded: boolean;
    isRemoved: boolean;
    isStandalone?: boolean;
  };
};

export type ServiceAreaType = {
  includedStates?: string[];
  statesWithinRadius?: string[];
  radius?: number;
  isDisabled?: boolean;
  centerpoint?: [number, number];
};

export type NationalServiceAreaDifferenceType = {
  [id: number]: boolean;
};
