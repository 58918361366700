import openSocket from 'socket.io-client';

import { getToken, getUserId } from 'modules/auth/storage';
import { Notification } from './types';

const notificationsAPI = window.__RUNTIME_CONFIG__.REACT_APP_NOTIFICATION_API_URL;

const createNotificationsConnection = () =>
  openSocket(notificationsAPI, {
    query: {
      credentials: JSON.stringify({
        params: { userId: getUserId() },
        headers: {
          'contractor-auth': getToken(),
        },
      }),
    },
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 3,
  });

export const listenNotifications = (
  userId: number,
  callback: (notification: Notification) => void,
) => {
  createNotificationsConnection().on(`notifications/${userId}`, callback);
};
