import { State } from 'types';

import { selectUser } from 'modules/auth/selectors';
import { selectMarkets } from 'modules/markets/selectors';
import { getFinancingObject } from 'modules/financing';

export const selectQuickQuote = (state: State) => state.quickQuote;

export const selectMap = (state: State) => state.mapbox;
export const selectMapInstance = (state: State) => selectMap(state).mapboxMap;

export const selectStructures = (state: State) => selectQuickQuote(state).structures;
export const selectInitialStructures = (state: State) => selectQuickQuote(state).initialStructures;
export const selectUserInfo = (state: State) => selectQuickQuote(state).userInfo;
export const selectLeadData = (state: State) => selectQuickQuote(state).leadData;
export const selectQuickQuoteMarketSlug = (state: State) => selectQuickQuote(state).marketSlug;
export const selectProductPriceSettings = (state: State) =>
  selectQuickQuote(state).productPriceSettings;

export const selectIsPricesUnlocked = (state: State) =>
  !!selectStructures(state).length && selectStructures(state).every(structure => !!structure.slope);

export const selectUserDetailsProvided = (state: State) => {
  const user = selectUser(state);
  return !!(user && user.id) || selectQuickQuote(state).userDetailsProvided;
};

export const selectIsUserInfoProvided = (state: State) => {
  const user = selectUser(state);
  const userInfo = selectUserInfo(state);

  return !!(user && user.id) || !!(userInfo && userInfo.email);
};

export const selectQuickQuoteIsReadyToRender = (state: State) => state.quickQuote.isReadyToRender;

export const selectQuickQuoteMarket = (state: State) => {
  const { marketSlug } = selectQuickQuote(state);
  const markets = selectMarkets(state);

  return markets.find(market => market.market.slug === marketSlug);
};
export const selectQuickQuoteMarketFinancing = (state: State) =>
  getFinancingObject(selectQuickQuoteMarket(state)?.market.financingSetting);
