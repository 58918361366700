import { ActionType } from 'types';
import * as constants from './constants';
import { NotificationsState } from './types';

const initialState: NotificationsState = {
  notifications: [],
};

const notificationsReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.notifications],
      };
    case constants.CLEAR_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
};

export default notificationsReducer;
