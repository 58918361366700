import {
  MultiPolygon,
  Polygon,
  Position,
  polygon as turfPolygon,
  multiPolygon as turfMultiPolygon,
  Properties,
  Feature,
  Point,
} from '@turf/helpers';
import { default as turfArea } from '@turf/area';
import { default as turfBooleanOverlap } from '@turf/boolean-overlap';
import { default as turfCentroid } from '@turf/centroid';
import { default as turfCircle } from '@turf/circle';
import { default as turfDistance } from '@turf/distance';
import { default as turfIntersect } from '@turf/intersect';
import { nanoid } from 'nanoid';
import { IntlShape } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { intl } from 'intl';
import cloneDeep from 'lodash/cloneDeep';

import {
  PolygonMeasurements,
  RoofleStructure,
  GeoJsonPolygon,
  StructureSlope,
  QuickQuoteUserInfo,
  StructureMetaDataResponse,
  StructureSlopeEnum,
  StructureMetaData,
  WasteFactorTypeEnum,
  StructureCoordinates,
  MapProviderType,
  MLPredictionResponse,
} from './types';
import { PERCENTAGE_TO_INCLUDE_STRUCTURE_IN_PARCEL, squareCoeficient } from './constants';

import { encryptQQ, getRoundedSquaresCount } from './_utils';
import { getPrices } from 'modules/global/prices';
import { selectAddress, selectAddressFeature } from 'modules/search/selectors';
import {
  selectQuickQuote,
  selectUserInfo,
  selectProductPriceSettings,
  selectStructures,
  selectQuickQuoteMarket,
} from './selectors';
import { getStatisticsIdFromStorage } from './storage';
import store from 'store';
import { getLeadCommunicationOptions, getSessionId, toFixed } from 'utils';
import {
  FormattedAdditionalInformation,
  QuickQuoteRequestBody,
  ReportType,
  StatisticsStructure,
} from 'modules/dashboard/types';
import { selectWidget } from 'modules/widget/selectors';
import {
  CustomSlopes,
  QuickQuoteProductPriceSettings,
  SquareFeetEnum,
  SquareFeetType,
} from 'modules/repQuotes/types';
import { selectCompanyQuoteSettings, selectIsGoogleMapsActive } from 'modules/company/selectors';
import { PriceInfo } from 'modules/financing/types';
import {
  formatQuoteSettings,
  getOtherCostKeyNameMap,
  isExtraCostAnswerId,
} from 'modules/global/prices/utils';
import { MULTIPLIERS } from 'modules/global/constants';
import { STATIC_DISCOUNT_KEYS, staticDiscountsMap } from 'modules/quoteSettings/constants';
import { getOptionLabel } from 'modules/repQuotes/utils';
import { areAdditionalCostsEnabledInModel } from 'modules/quoteSettings/components/utils';
import {
  getGoogleMapsClearScreenshotAndBounds,
  getGoogleMapsScreenshotOnlyPolygons,
} from 'modules/googleMaps/utils';
import {
  getMapboxClearScreenshotAndBounds,
  getMapboxScreenshotOnlyPolygons,
} from 'modules/mapbox/utils';
import { googleMapsInstance } from 'modules/googleMaps/classes/GoogleMapsInstance';
import { sendPredictionCompletedPostMessage } from 'modules/hooks/usePostMessage';
import { sendDataToML } from './services';

export function swapLatLngCoords(coords: Position[]): [number, number][] {
  return coords.map(coord => {
    return [coord[1], coord[0]];
  });
}

export function toRoofleStructures({
  structures,
}: {
  structures: RoofleStructure['geoJsonPolygon'][];
}): RoofleStructure[] {
  return (
    structures
      // convert raw polygons to structure objects
      .map(structure => toRoofleStructure({ structure }))
  );
}

export function toRoofleStructure({
  structure,
  name = '',
  overrides,
}: {
  structure: RoofleStructure['geoJsonPolygon'];
  name?: string;
  overrides?: Partial<RoofleStructure>;
}): RoofleStructure {
  if (!structure.properties) {
    structure.properties = {};
  }

  const id = structure.properties.id ? structure.properties.id : nanoid();
  const { slopeName, slopeToCalculate } = getSlope(structure);

  if (structure.properties.name) {
    name = structure.properties.name;
  }

  if (!structure.id) {
    structure.id = id;
  }

  structure.properties = { ...structure.properties, id, name, slope: slopeToCalculate };

  const centroid = turfCentroid(structure);
  const measurements = getPolygonMeasurements(structure, slopeToCalculate);

  return {
    id,
    measurements,
    name,
    slope: slopeName,
    centroid,
    geoJsonPolygon: structure,
    isIncluded: true,
    isError: false,
    nameError: null,
    structureMetaData: structure.properties?.structureMetaData,
    ...overrides,
  };
}

export function getPolygonMeasurements(
  feature: GeoJsonPolygon,
  slope: StructureSlope,
): PolygonMeasurements {
  const computedSquareMeters = turfArea(feature);
  const initialSquareFeet = computedSquareMeters * squareCoeficient;
  const initialWholeSquareFeet = Math.ceil(initialSquareFeet);

  const slopeMultiplier = getSlopeMultiplier({
    slope,
  });

  const squareFeet = initialSquareFeet * slopeMultiplier;
  const wholeSquareFeet = Math.ceil(squareFeet);
  const squareFeetToLocale = wholeSquareFeet.toLocaleString();
  const squaresCount = getRoundedSquaresCount(squareFeet);

  return {
    computedSquareMeters,
    initialSquareFeet,
    initialWholeSquareFeet,
    squareFeet,
    squareFeetToLocale,
    squaresCount,
    wholeSquareFeet,
  };
}

function getSlope(structure: GeoJsonPolygon): {
  slopeToCalculate: StructureSlope;
  slopeName: StructureSlope;
} {
  const isDefaultQuickQuote = isDefaultQQWidget();
  const companyPublicQuoteSettings = selectCompanyQuoteSettings(store.getState());
  const market = selectQuickQuoteMarket(store.getState());
  const marketQuoteSettings = market?.market.quoteSettings;
  const suggestedSlopeEnabled = !!companyPublicQuoteSettings?.suggestedSlopeEnabled;

  //set default slope from settings page if widget is QQ and suggested slope setting is turned off
  //and there is the setting for default slope for market
  if (
    isDefaultQuickQuote &&
    !suggestedSlopeEnabled &&
    marketQuoteSettings?.slope &&
    marketQuoteSettings.slope !== StructureSlopeEnum.custom
  ) {
    const slope = marketQuoteSettings.slope;
    return { slopeToCalculate: slope, slopeName: slope };
  }

  if (isDefaultQuickQuote && structure.properties?.structureMetaData?.averagePitch) {
    const pitch = getPithForDefaultQuickQuote(structure, suggestedSlopeEnabled);
    return { slopeToCalculate: pitch, slopeName: pitchToSlopeName(pitch) };
  }

  const slope =
    structure.properties?.slope ||
    structure.properties?.structureMetaData?.averagePitch ||
    marketQuoteSettings?.slope ||
    'medium';
  return { slopeToCalculate: slope, slopeName: slope };
}

function getPithForDefaultQuickQuote(structure: GeoJsonPolygon, suggestedSlopeEnabled: boolean) {
  if (structure.properties?.slope) {
    return structure.properties?.slope;
  }

  return suggestedSlopeEnabled ? structure.properties?.structureMetaData?.averagePitch : 'medium';
}

function getSlopeMultiplier({ slope }: { slope: StructureSlope }) {
  const isDefaultQuickQuote = isDefaultQQWidget();
  const companyPublicQuoteSettings = selectCompanyQuoteSettings(store.getState());
  const suggestedSlopeEnabled = !!companyPublicQuoteSettings?.suggestedSlopeEnabled;
  let slopeMultiplier = MULTIPLIERS.MEDIUM;

  // for the customer, use generic slopes
  if (isDefaultQuickQuote) {
    const slopeName = pitchToSlopeName(slope);
    slopeMultiplier = suggestedSlopeEnabled
      ? findSlopeMultiplier(slope)
      : MULTIPLIERS[slopeName.toUpperCase()];

    // for rep quotes/open quotes, use slope from structure because structure include correct slope. in this case it cane be generic slope or custom slope
  } else {
    slopeMultiplier = findSlopeMultiplier(slope);
  }
  return slopeMultiplier;
}

function findSlopeMultiplier(slope: StructureSlope): number {
  return MULTIPLIERS[slope.toUpperCase()] || CustomSlopes[slope].multiplier || MULTIPLIERS.MEDIUM;
}

// algorithm to process Mapbox polygons and return merged features inside the parcel
export async function handleMapboxRenderedStructures({
  structures,
}: {
  structures: mapboxgl.MapboxGeoJSONFeature[];
}) {
  const desiredTypes = filterDesiredMapboxTypes(structures);
  const asGeoJson = convertMapboxStructuresToGeojson(desiredTypes);
  const groupedById = groupMapboxFeaturesById(asGeoJson);
  // todo: maybe we can use the new merge feature here?
  const idsMerged = await getMergedFeaturesFromMapboxFragments(groupedById);
  const convertedAllPolygons = convertMultiPolygonsToPolygons(idsMerged);
  const merged = await mergeIntersectingPolygons(convertedAllPolygons);
  const withIds = assignMapboxStructureIds(merged);
  return withIds;
}

const unwantedRenderedTypes = ['parking'];

function isDesiredMapboxType(feature: GeoJSON.Feature) {
  return !unwantedRenderedTypes.includes(feature.properties?.type);
}

function filterDesiredMapboxTypes(mapboxStructures: mapboxgl.MapboxGeoJSONFeature[]) {
  return mapboxStructures.filter(isDesiredMapboxType);
}

function convertMapboxStructuresToGeojson(
  mapboxStructures: mapboxgl.MapboxGeoJSONFeature[],
): GeoJsonPolygon[] {
  const geojsonFeatures: GeoJSON.Feature[] = mapboxStructures.map(structure => {
    const { id, type, geometry, properties } = structure;
    return { id, type, geometry, properties };
  });

  return geojsonFeatures.filter(isPolygonOrMultiPolygon);
}

// Sometimes structures do not have IDs.
// From Mapbox: "some types of data are only included at certain zoom levels...
// it is possible that a building does not have a feature ID at zoom 15 but does have one at zoom 16."
function assignMapboxStructureIds(structures: GeoJsonPolygon[]): GeoJsonPolygon[] {
  return structures.map((structure, index) => {
    return {
      ...structure,
      id: structure.id || index,
    };
  });
}

// return all Mapbox structures with coordinates inside the parcel polygon
function filterMapboxStructuresWithinParcel({
  structures,
  parcel,
}: {
  structures: GeoJsonPolygon[];
  parcel: GeoJsonPolygon;
}) {
  return structures.filter(structure => {
    return isStructureWithinParcel({
      structure,
      parcel,
    });
  });
}

// return true if any Polygon or MultiPolygon is within the parcel polygon inclusion logic
export function isStructureWithinParcel({
  structure,
  parcel,
}: {
  structure: GeoJsonPolygon;
  parcel: GeoJsonPolygon;
}) {
  if (isPolygon(structure)) {
    return isWithinParcel({ parcel, structure });
  }

  if (isMultiPolygon(structure)) {
    return structure.geometry.coordinates.some(coords => {
      const _structure = turfPolygon(coords) as GeoJsonPolygon;
      return isWithinParcel({ parcel, structure: _structure });
    });
  }

  return false;
}

interface MapboxFeaturesById {
  [id: string]: GeoJsonPolygon[];
}

function convertMultiPolygonsToPolygons(features: GeoJsonPolygon[]) {
  const result: GeoJSON.Feature<Polygon, Properties>[] = [];

  features.forEach(feature => {
    if (isPolygon(feature)) {
      result.push(feature);
    }
    if (isMultiPolygon(feature)) {
      result.push(...toPolygonsArrayFromGeoJson(feature));
    }
  });

  return result;
}

// build an object to look up all polygons associated with their Mapbox building id
function groupMapboxFeaturesById(features: GeoJsonPolygon[]) {
  const featuresById: MapboxFeaturesById = {};

  features.forEach(feature => {
    if (!feature.id) {
      return;
    }

    // index an array of polygons that represent each Mapbox building id
    if (!featuresById[feature.id]) {
      featuresById[feature.id] = [feature];
    } else {
      featuresById[feature.id].push(feature);
    }
  });

  return featuresById;
}

// return structures with merged polygons for Mapbox features with the same building id
async function getMergedFeaturesFromMapboxFragments(structuresById: MapboxFeaturesById) {
  const mergedResults: GeoJsonPolygon[] = [];
  const { default: turfUnion } = await import('@turf/union');

  Object.values(structuresById).forEach((features, index) => {
    // store the feature when one polygon is present for the building id
    if (features.length === 1) {
      mergedResults.push(features[0]);
      return;
    }

    // when present, combine multiple Mapbox building id poylgons into a single polygon.
    // multiple polygons for a building id may represent fragments of the full building shape.
    // see: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#queryrenderedfeatures
    // "...feature geometries may be split or duplicated across tile boundaries..."
    if (features.length > 1) {
      const initialPolygons = toPolygonsArrayFromGeoJson(features[0]);
      let combinedPolygon = features[0];

      features.forEach(nextFeature => {
        const previousPolygons =
          index === 0 ? initialPolygons : toPolygonsArrayFromGeoJson(combinedPolygon);
        const nextPolygons = toPolygonsArrayFromGeoJson(nextFeature);
        const unionResult = turfUnion(...previousPolygons, ...nextPolygons);

        if (unionResult?.geometry !== null) {
          combinedPolygon = unionResult as GeoJsonPolygon;
        }
      });

      // push the combined polygon to the results set
      mergedResults.push(combinedPolygon);
    }
  });

  return mergedResults;
}

// merge polygons which overlap in any way
async function mergeIntersectingPolygons(
  polygons: GeoJSON.Feature<Polygon, Properties>[],
): Promise<GeoJsonPolygon[]> {
  const { default: turfUnion } = await import('@turf/union');

  const mergedPolygons = polygons.slice();

  let hasChanged: boolean;
  do {
    hasChanged = false;

    for (let i = 0; i < mergedPolygons.length; i++) {
      const currentPolygon = mergedPolygons[i];

      for (let j = i + 1; j < mergedPolygons.length; j++) {
        const nextPolygon = mergedPolygons[j];

        if (
          turfIntersect(currentPolygon, nextPolygon) ||
          turfBooleanOverlap(currentPolygon, nextPolygon)
        ) {
          const merged = turfUnion(currentPolygon, nextPolygon);

          // remove the nextPolygon and replace the currentPolygon with the merged one
          mergedPolygons.splice(j, 1);
          // @ts-ignore
          mergedPolygons[i] = merged;
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) break;
    }
  } while (hasChanged);

  return mergedPolygons;
}

export function hasMultipleMatchingCoordinates({
  source,
  comparison,
}: {
  source: GeoJsonPolygon;
  comparison: GeoJsonPolygon;
}) {
  if (source.id === comparison.id) {
    return false;
  }

  const sourceCoords = getAllGeoJsonCoords(source);

  // count how many coordinates match
  const coordsMatchCount = sourceCoords.reduce((accumulator, coords) => {
    const comparisonCoords = getAllGeoJsonCoords(comparison);

    const match = comparisonCoords.some(compareCoords => {
      return coords[0] === compareCoords[0] && coords[1] === compareCoords[1];
    });

    return match ? ++accumulator : accumulator;
  }, 0);

  // if more than 2 coordinates match, we consider it a bordering structure
  return coordsMatchCount > 1 ? true : false;
}

export function isWithinParcel({
  parcel,
  structure,
}: {
  parcel: GeoJsonPolygon;
  structure: GeoJsonPolygon;
}) {
  if (isPolygon(parcel)) {
    return isStructureEnoughWithinParcelPolygon({ structure, parcel });
  }

  if (isMultiPolygon(parcel)) {
    return parcel.geometry.coordinates.some(coords => {
      const _parcel = turfPolygon(coords) as GeoJsonPolygon;
      return isStructureEnoughWithinParcelPolygon({ structure, parcel: _parcel });
    });
  }

  return false;
}

function isStructureEnoughWithinParcelPolygon({
  structure,
  parcel,
}: {
  structure: GeoJsonPolygon;
  parcel: GeoJsonPolygon;
}) {
  const intersection: GeoJsonPolygon | null = turfIntersect(structure, parcel);

  // if the structure is fully outside the parcel, omit it
  if (!intersection) {
    return false;
  }

  // if the structure is a given percentage inside the parcel, include it
  const fooprintSize = turfArea(structure);
  const intersectionSize = turfArea(intersection);
  const percentageInParcel = (intersectionSize / fooprintSize) * 100;

  if (percentageInParcel > PERCENTAGE_TO_INCLUDE_STRUCTURE_IN_PARCEL) {
    return true;
  }

  // in any other case, omit the structure
  return false;
}

export function findClosestBuildingToFauxParcel({
  structures,
  parcel,
}: {
  structures: GeoJsonPolygon[];
  parcel: GeoJsonPolygon;
}) {
  structures.forEach(structure =>
    assignStructureDistancesFromParcelCentroid({ structure, parcel }),
  );

  const _structures = structures.slice().sort((a, b) => {
    if (a.properties && b.properties) {
      return a.properties.distance - b.properties.distance;
    }

    return 0;
  });

  return _structures[0];
}

export function assignStructureDistancesFromParcelCentroid({
  structure,
  parcel,
}: {
  structure: GeoJsonPolygon;
  parcel: GeoJsonPolygon;
}) {
  const centroid = turfCentroid(parcel);

  let distanceCoords: Position[][] = [];

  if (isPolygon(structure)) {
    distanceCoords = structure.geometry.coordinates;
  } else if (isMultiPolygon(structure)) {
    distanceCoords = structure.geometry.coordinates[0];
  }

  const distancePolygon = turfPolygon(distanceCoords);
  const distance = getPolygonDistanceFromPoint(centroid, distancePolygon);

  if (!structure.properties) {
    structure.properties = {};
  }

  structure.properties.distance = distance;
}

function getPolygonDistanceFromPoint(
  point: Feature<Point, Properties>,
  polygon: Feature<Polygon, Properties>,
) {
  const polygonCentroid = turfCentroid(polygon);
  const options = { units: 'miles' as const };
  return turfDistance(point, polygonCentroid, options);
}

export function toMultiPolygonFromGeoJson(feature: GeoJsonPolygon) {
  if (isPolygon(feature)) {
    return turfMultiPolygon([feature.geometry.coordinates]);
  }

  return feature;
}

function toPolygonsArrayFromGeoJson(feature: GeoJsonPolygon): GeoJSON.Feature<Polygon>[] {
  if (isPolygon(feature)) {
    return [feature];
  }

  if (isMultiPolygon(feature)) {
    const { id, properties } = feature;

    return feature.geometry.coordinates.map(coords => {
      const polygon: GeoJSON.Feature<Polygon> = {
        id,
        properties,
        type: 'Feature' as const,
        geometry: {
          type: 'Polygon' as const,
          coordinates: coords,
        },
      };

      return polygon;
    });
  }

  return [];
}

export function getAllGeoJsonCoords(feature: GeoJsonPolygon) {
  let coords: Position[] = [];

  if (isPolygon(feature)) {
    coords = feature.geometry.coordinates[0];
  }

  if (isMultiPolygon(feature)) {
    coords = feature.geometry.coordinates.flat(2);
  }

  return coords;
}

const isPolygon = (feature: GeoJSON.Feature): feature is GeoJSON.Feature<Polygon> => {
  return feature.geometry.type === 'Polygon';
};

const isMultiPolygon = (feature: GeoJSON.Feature): feature is GeoJSON.Feature<MultiPolygon> => {
  return feature.geometry.type === 'MultiPolygon';
};

const isPolygonOrMultiPolygon = (feature: GeoJSON.Feature): feature is GeoJsonPolygon => {
  return isPolygon(feature) || isMultiPolygon(feature);
};

const getStaticDiscountsTranslations = (intl: IntlShape): Record<string, string> => {
  return STATIC_DISCOUNT_KEYS.reduce((acc, key) => {
    acc[key] = intl.formatMessage({ id: staticDiscountsMap[key] });
    return acc;
  }, {});
};

const getAdditionalCostsTranslations = (
  intl: IntlShape,
  keys: string[],
): Record<string, string> => {
  return keys.reduce((acc, key) => {
    const isExtraCost = isExtraCostAnswerId(key);

    if (!isExtraCost) {
      acc[key] = intl.formatMessage({ id: getOptionLabel(key) });
    }

    return acc;
  }, {});
};

const getFormattedAdditionalInformation = (
  productPriceSettings: QuickQuoteProductPriceSettings | null,
  appliedAdditionalCosts?: PriceInfo['additionalCosts'],
): FormattedAdditionalInformation => {
  if (!productPriceSettings) {
    return {};
  }

  const { additionalCost, additionalCostSettings, discount } = productPriceSettings;
  const discountExists = !isEmpty(discount?.discounts);
  const staticDiscountsTranslations = getStaticDiscountsTranslations(intl);

  if (
    !additionalCost ||
    !additionalCostSettings ||
    !areAdditionalCostsEnabledInModel(additionalCostSettings)
  ) {
    return discountExists
      ? {
          discounts: discount?.discounts,
          translations: staticDiscountsTranslations,
        }
      : {};
  }

  const additionalCostKeys = Object.keys(appliedAdditionalCosts || {});
  const formattedQuoteSettings = formatQuoteSettings(additionalCostSettings);
  const translations = {
    ...staticDiscountsTranslations,
    ...getAdditionalCostsTranslations(intl, additionalCostKeys),
    ...getOtherCostKeyNameMap(formattedQuoteSettings.otherExtraCosts),
  };

  return {
    discounts: discount?.discounts,
    translations,
  };
};

export const addFormattedAdditionalInformationToQuickQuoteRequestBody = (
  result: QuickQuoteRequestBody,
  productPriceSettings: QuickQuoteProductPriceSettings | null,
): void => {
  const productWithAdditionalCosts = result.additionalInformation.products.find(
    ({ priceInfo }) => priceInfo?.additionalCosts,
  );
  const formattedAdditionalInformation = getFormattedAdditionalInformation(
    productPriceSettings,
    productWithAdditionalCosts?.priceInfo?.additionalCosts,
  );

  if (!isEmpty(formattedAdditionalInformation)) {
    result.formattedAdditionalInformation = formattedAdditionalInformation;
  }
};

export const buildQuickQuoteRequestBody = async ({
  _userInfo,
  productId,
  unavailableStates,
  orders,
  isMakeScreenshot = false,
  squareFeetSettings,
  addFormattedAdditionalInformation = false,
  MLPrediction,
}: {
  _userInfo?: QuickQuoteUserInfo;
  productId?: number;
  unavailableStates?: string[];
  orders?: ReportType[];
  isMakeScreenshot?: boolean;
  squareFeetSettings?: SquareFeetType;
  addFormattedAdditionalInformation?: boolean;
  MLPrediction?: MLPredictionResponse;
}): Promise<QuickQuoteRequestBody> => {
  const state = store.getState();
  const address = selectAddress(state);
  const addressFeature = selectAddressFeature(state);
  const quickQuote = selectQuickQuote(state);
  const productPriceSettings = selectProductPriceSettings(state);
  const userInfo = _userInfo || selectUserInfo(state);
  const { externalUrl, products = [] } = selectWidget(state);
  const companyQuoteSettings = selectCompanyQuoteSettings(state);
  const isDefaultQuickQuote = isDefaultQQWidget();
  const isGoogleMapsActive = selectIsGoogleMapsActive(state);
  const useCustomSquareFeet =
    productPriceSettings?.squareFeet &&
    productPriceSettings.squareFeet.type === SquareFeetEnum.Custom;
  const isSquareFeetNotDefault =
    productPriceSettings?.squareFeet &&
    productPriceSettings.squareFeet.type !== SquareFeetEnum.InstantQuote;

  if (!userInfo) {
    throw new Error('User info does not exists');
  }

  const { firstName, lastName, phone, email, communicationOptions } = userInfo;

  const { sessionId } = await getSessionId();
  const statisticsId = await getStatisticsIdFromStorage();

  const productsToSend = productId
    ? products?.filter(product => product.id === productId)
    : products;

  const everyProductIsCustom = productsToSend.every(product => product.customProduct);

  const structures = quickQuote.structures.map(structure => {
    const wasteFactor = cloneDeep(structure.wasteFactor);
    const mlWasteFactor =
      MLPrediction?.structures[structure.geoJsonPolygon.id as string]?.wasteFactor;
    if (wasteFactor && mlWasteFactor) {
      wasteFactor.suggested = mlWasteFactor;
    }
    if (wasteFactor?.usedWFType === WasteFactorTypeEnum.Suggested && everyProductIsCustom) {
      wasteFactor.usedWFType = WasteFactorTypeEnum.Default;
    }

    const data = {
      id: structure.id,
      name: structure.name,
      slope: structure.slope,
      isIncluded: !!structure.isIncluded,
      squareFeet: useCustomSquareFeet
        ? (productPriceSettings.squareFeet?.value[structure.geoJsonPolygon.id as string] as number)
        : structure.measurements.wholeSquareFeet,
      initialSquareFeet:
        quickQuote.initialStructures.find(initialStructure => structure.id === initialStructure.id)
          ?.measurements.wholeSquareFeet || 0,
      centroid: structure.centroid,
      geoJsonPolygon: structure.geoJsonPolygon,
      roofComplexity:
        structure.roofComplexity ||
        MLPrediction?.structures[structure.geoJsonPolygon.id as string]?.roofComplexity,
      wasteFactor,
      customTotalSquareFeet: structure.customTotalSquareFeet,
    };

    return data;
  });

  const deletedStructures = quickQuote.initialStructures
    .filter(
      initialStructure =>
        !quickQuote.structures.find(structure => structure.id === initialStructure.id),
    )
    .map(structure => ({
      name: structure.name,
      slope: structure.slope,
      isIncluded: false,
      squareFeet: structure.measurements.wholeSquareFeet,
      initialSquareFeet: structure.measurements.wholeSquareFeet,
      deleted: true,
    }));

  let totalSquareFeet = quickQuote.totalMeasurements
    ? quickQuote.totalMeasurements.totalSquareFeet
    : 0;

  if (isSquareFeetNotDefault && productPriceSettings.squareFeet) {
    totalSquareFeet = Math.ceil(
      Object.values(productPriceSettings.squareFeet.value).reduce(
        (sum, value) => +toFixed(sum + value, 3),
        0,
      ),
    );
  }

  const result: QuickQuoteRequestBody = {
    address,
    firstName,
    lastName,
    phone,
    email,
    numberOfStructures: quickQuote.structures.length,
    numberOfIncludedStructures: quickQuote.structures.filter(structure => structure.isIncluded)
      .length,
    totalSquareFeet,
    totalInitialSquareFeet: quickQuote.initialStructures.reduce(
      (accum, structure) => accum + structure.measurements.wholeSquareFeet,
      0,
    ),
    mainRoofTotalSquareFeet: quickQuote.structures[0].measurements.wholeSquareFeet,
    id: statisticsId || 0,
    sessionId: sessionId || '',
    market: quickQuote.marketSlug || '',
    externalUrl,
    isTotalSquareFeetModified: squareFeetSettings?.type !== SquareFeetEnum.InstantQuote,
    additionalInformation: {
      addressCenterpoint: {
        latitude: addressFeature?.center[1] as number,
        longitude: addressFeature?.center[0] as number,
      },
      squareFeetSettings: {
        type:
          squareFeetSettings?.type ||
          quickQuote.leadData?.additionalInformation.squareFeetSettings?.type ||
          SquareFeetEnum.InstantQuote,
        uniqId: squareFeetSettings?.uniqId,
      },
      parcel: quickQuote.parcel as GeoJsonPolygon,
      priceRangeEnabled: isDefaultQuickQuote
        ? !!companyQuoteSettings?.priceRangeEnabled
        : !!productPriceSettings?.priceRangeEnabled,
      products: productsToSend.map(product => {
        const prices = getPrices({
          squareFeetPrice: product.price,
          fixedPrice: product.fixedPrice,
          state: null,
          unavailableStates,
          orders,
          line: product,
        });

        const priceInfo = prices.generatePriceInfo();

        return {
          ...product,
          priceInfo,
        };
      }),
      structures: [...structures, ...deletedStructures],
      communicationOptions: isDefaultQuickQuote
        ? getLeadCommunicationOptions(communicationOptions)
        : null,
    },
    predictionEventId: quickQuote.mlPrediction.eventId,
  };

  if (isMakeScreenshot) {
    const { screenshot: mapScreenshot } = await (isGoogleMapsActive
      ? getGoogleMapsScreenshotOnlyPolygons()
      : getMapboxScreenshotOnlyPolygons());

    result.additionalInformation.mapScreenshot = mapScreenshot;
  }

  if (addFormattedAdditionalInformation) {
    addFormattedAdditionalInformationToQuickQuoteRequestBody(result, productPriceSettings);
  }

  return result;
};

export const encryptQuickQuoteAdditionalInformation = async (
  additionalInformation: unknown,
): Promise<string> => {
  return encryptQQ(JSON.stringify(additionalInformation));
};

export const assignStructureMetaData = ({
  structure,
  structureMetaData,
}: {
  structure: GeoJsonPolygon;
  structureMetaData: StructureMetaDataResponse;
}) => {
  const structureArea = turfArea(structure);

  const intersected = structureMetaData.find(feature => {
    return turfIntersect(structure, feature.geometry as MultiPolygon);
  });

  if (intersected) {
    const intersectArea = turfArea(intersected.geometry as MultiPolygon);
    const percentage = structureArea / intersectArea;

    // if over 2/3 overlap, assume it's the same structure
    if (percentage > 0.66) {
      const { MaxHeight, MeanHeight, MeanSlope, ModeSlope } = intersected.properties;

      if (!MeanSlope || !ModeSlope) {
        return structure;
      }

      const averageSlope = getComputedSlope(Number(MeanSlope), Number(ModeSlope));

      if (averageSlope === null) {
        return structure;
      }

      const averagePitch = getAveragePitch(averageSlope);
      const averagePitchName = pitchToSlopeName(averagePitch);

      if (!structure.properties) {
        structure.properties = {};
      }

      const meta: StructureMetaData = {
        averageSlope,
        averagePitch,
        averagePitchName,
        maxHeight: Number(MaxHeight),
        meanHeight: Number(MeanHeight),
      };

      structure.properties.structureMetaData = meta;
    }
  }

  return structure;
};

function getComputedSlope(meanSlope: number, modeSlope: number) {
  // if both slopes are greater than 69º, consider it bad data
  if (meanSlope > 69 && modeSlope > 69) {
    return null;
  }

  // if one slope is greater than 69º and the other is not,
  // consider it bad data and use the other slope
  if (meanSlope > 69 && modeSlope <= 69) {
    return modeSlope;
  }

  if (modeSlope > 69 && meanSlope <= 69) {
    return meanSlope;
  }

  // otherwise, use an average of the two slope values
  return (meanSlope + modeSlope) / 2;
}

function getAveragePitch(slope: number) {
  let averagePitch = convertRoofDegreesToSlope(slope);

  const pitchFragment = averagePitch.split('/')[0];

  if (Number(pitchFragment) > 18) {
    averagePitch = '18/12';
  }

  return averagePitch;
}

// borrowed from here https://www.blocklayer.com/Scripts/PitchAngle_V9.js
// todo: borrow new example? https://www.blocklayer.com/Scripts/PitchAngle_V10.js
function convertRoofDegreesToSlope(degrees: number) {
  const slope = degrees * 0.017453292519943295;
  const rise = Math.tan(slope) * 12;
  const pitch = `${Math.round(rise)}/12`;
  return pitch;
}

// FLAT: 1 // appx. 0/12-1/12
// SHALLOW: 1.055, // appx. 2/12-4/12
// MEDIUM: 1.16, // appx. 5/12-8/12
// STEEP: 1.305, // appx. 9/12+
export function pitchToSlopeName(pitch: string): StructureSlope {
  if (isPitchASlopeName(pitch)) {
    return pitch as StructureSlope;
  }

  const rise = Number(pitch.split('/')[0]);

  if (rise < 2) {
    return StructureSlopeEnum.flat;
  }

  if (rise >= 2 && rise < 5) {
    return StructureSlopeEnum.shallow;
  }

  if (rise >= 5 && rise < 9) {
    return StructureSlopeEnum.medium;
  }

  if (rise >= 9) {
    return StructureSlopeEnum.steep;
  }

  return StructureSlopeEnum.medium;
}

function isPitchASlopeName(pitch: string): pitch is StructureSlopeEnum {
  return (
    pitch === StructureSlopeEnum.flat ||
    pitch === StructureSlopeEnum.shallow ||
    pitch === StructureSlopeEnum.medium ||
    pitch === StructureSlopeEnum.steep
  );
}

export function isDefaultQQWidget() {
  return !window.location.pathname.includes('personal');
}

export function formatSlopesNamesForEmail(structures: StatisticsStructure[]) {
  structures.forEach(structure => {
    structure.slope = pitchToSlopeName(structure.slope);
  });
}

export async function dataUrlToImageFile(dataUrl: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], 'image.jpeg', { type: 'image/jpeg' });
}

export function formatStructuresForML(
  structures: RoofleStructure[],
): Record<string, GeoJsonPolygon> {
  return structures.reduce((acc, structure) => {
    if (structure.geoJsonPolygon.id) {
      acc[structure.geoJsonPolygon.id] = structure.geoJsonPolygon.geometry.coordinates[0];
    }
    return acc;
  }, {});
}

export const getMLPrediction = async (): Promise<MLPredictionResponse | undefined> => {
  const state = store.getState();
  const isGoogleMapsActive = selectIsGoogleMapsActive(state);
  const structures = selectStructures(state);

  const { bounds, screenshot: image } = await (isGoogleMapsActive
    ? getGoogleMapsClearScreenshotAndBounds()
    : getMapboxClearScreenshotAndBounds(structures));

  if (!bounds || !image) {
    return;
  }

  sendPredictionCompletedPostMessage(false);

  const { wildcard } = selectWidget(state);
  if (!wildcard) return;

  const imageFile = await dataUrlToImageFile(image);
  const formattedStructures = formatStructuresForML(structures);
  const mapProvider: MapProviderType = isGoogleMapsActive
    ? MapProviderType.GoogleMaps
    : MapProviderType.Mapbox;

  const formData = new FormData();
  formData.append('image', imageFile, 'property.jpeg');
  formData.append('bounds', JSON.stringify(bounds));
  formData.append('structures', JSON.stringify(formattedStructures));
  formData.append('mapProvider', JSON.stringify(mapProvider));

  const { data } = await sendDataToML(wildcard, formData);

  sendPredictionCompletedPostMessage(true);

  return data;
};

export const setDefaultWasteFactorToStructure = (structures: RoofleStructure[]) => {
  const companyQuoteSettings = selectCompanyQuoteSettings(store.getState());
  const isDefaultQuickQuote = isDefaultQQWidget();

  if (companyQuoteSettings && companyQuoteSettings.wasteFactorEnabled) {
    structures = structures.map(structure => {
      if (!('wasteFactor' in structure)) {
        structure.wasteFactor = {
          default: +companyQuoteSettings.wasteFactorValue,
          usedWFType: WasteFactorTypeEnum.Default,
        };
      }

      if (structure.wasteFactor?.default !== undefined && isDefaultQuickQuote) {
        structure.wasteFactor.default = +companyQuoteSettings.wasteFactorValue;
      }
      return structure;
    });
  }

  return structures;
};

export const setDefaultCustomSquareFeetToStructure = (
  structures: RoofleStructure[],
): RoofleStructure[] => {
  const initialStructures = selectStructures(store.getState());
  const needToAddCustomSqFt = initialStructures.some(
    structure => !!structure.customTotalSquareFeet,
  );

  if (!needToAddCustomSqFt) {
    return structures;
  }

  return structures.map(structure => {
    const initialStructure = initialStructures.find(item => item.id === structure.id);

    return {
      ...structure,
      customTotalSquareFeet: initialStructure
        ? initialStructure.customTotalSquareFeet
        : structure.measurements.wholeSquareFeet,
    };
  });
};

export const resetMapInstances = async () => {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  mapboxQuickQuoteInstance.reset();
  googleMapsInstance.reset();
};

export const getStructuresCoordinates = (structures: RoofleStructure[]): StructureCoordinates[] => {
  return structures.map(structure => ({
    [structure.id]: structure.geoJsonPolygon.geometry.coordinates,
  }));
};

export const getCompressedScreenshot = (canvas: HTMLCanvasElement) => {
  return canvas.toDataURL('image/jpeg', 0.5);
};

// if no parcel, create a ~55ft radius to detect nearby structures
export const buildFakeParcelFromExisting = ({ parcel }: { parcel: GeoJsonPolygon }) => {
  const centroid = turfCentroid(parcel);
  const [lng, lat] = centroid.geometry.coordinates;
  const fakeParcel = turfCircle([lng, lat], 0.01, {
    units: 'miles',
    properties: {
      isFaux: true,
    },
  });

  return fakeParcel;
};
