import { ActionType } from 'types';
import * as constants from './constants';
import { ModalConfig, ModalState, ModalTypes } from './types';

const initialState: ModalState = {
  opened: false,
  type: '',
  params: {},
  stack: [],
};

const END_OF_ACTIONS_MODALS = [ModalTypes.successModal];

const modalReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.OPEN: {
      const stack: ModalConfig[] = [];

      if (
        state.opened &&
        state.type &&
        !action.params?.fromScratch &&
        !END_OF_ACTIONS_MODALS.includes(action.modalType)
      ) {
        stack.unshift({ type: state.type, params: state.params });
      }

      return { ...state, type: action.modalType, opened: true, params: action.params || {}, stack };
    }
    case constants.CLOSE: {
      const [lastOpenedModal, ...rest] = state.stack;

      const updatedState: ModalState =
        !action.clearStack && lastOpenedModal
          ? { opened: true, ...lastOpenedModal, stack: rest }
          : initialState;

      return { ...updatedState };
    }
    case constants.SET_PARAMS:
      return { ...state, params: { ...state.params, ...action.params } };
    default:
      return state;
  }
};

export default modalReducer;
