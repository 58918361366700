import { RoofCondition, RoofType } from 'modules/global/types';
import { AddAdditionalCostsFormKey } from 'modules/quoteSettings/types';
import { I18nEnum } from 'types';
import {
  AdditionalInformationType,
  SquareFeetEnum,
  DiscountModel,
  DiscountUnitOfMeasurement,
  AdditionalCostVariant,
} from './types';

const name = 'rep-quotes/';

export const SET_USER_WIDGET_SETTINGS = `${name}SET_USER_WIDGET_SETTINGS`;
export const SET_SELECTED_COLOR_LINE = `${name}SET_SELECTED_COLOR_LINE`;
export const SET_LEAD_SESSION_ID = `${name}SET_LEAD_SESSION_ID`;

export const totalSqFt = 'squareFeet' as const;
export const additionalCost = 'additionalCost' as const;
export const additionalCostSettings = 'additionalCostSettings' as const;
export const additionalCostEnabled = 'additionalCostEnabled' as const;
export const discount = 'discount' as const;
export const discountEnabled = 'discountEnabled' as const;
export const wasteFactor = 'wasteFactor' as const;
export const priceRange = 'priceRangeEnabled' as const;

export const structuresFieldsToCompare = [
  'measurements.wholeSquareFeet',
  'measurements.computedSquareMeters',
  'name',
  'isIncluded',
  'slope',
];

export const ADDITIONAL_INFORMATION_FORM_FIELDS = {
  [AdditionalInformationType.HomeownerDetails]: {
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    email: 'email',
  },
  [AdditionalInformationType.Market]: {
    market: 'market',
  },
  [AdditionalInformationType.TotalSqFt]: {
    squareFeet: 'squareFeet',
  },
  [AdditionalInformationType.AdditionalCost]: {
    ...AddAdditionalCostsFormKey,
    additionalCost: 'additionalCost',
    useAdditionalCost: 'useAdditionalCost',
  },
  [AdditionalInformationType.Discount]: {
    useDiscount: 'useDiscount',
    discounts: 'discounts',
    unit: 'unit',
    type: 'type',
    value: 'value',
  },
};

export const addedValuesLabelMap = {
  [AddAdditionalCostsFormKey.StoryCost]: I18nEnum.HighRoof,
  [AddAdditionalCostsFormKey.LayerCost]: I18nEnum.ExtraLayerRemoval,
  [AddAdditionalCostsFormKey.ChimneyFlashingCost]: I18nEnum.ChimneyFlashing,
  [AddAdditionalCostsFormKey.SkylightFlashingCost]: I18nEnum.SkylightFlashing,
  [AddAdditionalCostsFormKey.NewDeckingCost]: I18nEnum.NewDecking,
  [AddAdditionalCostsFormKey.DifficultAccessCost]: I18nEnum.DifficultAccess,
  [RoofCondition.AlgaeStains]: I18nEnum.AlgaeStains,
  [RoofCondition.HailDamage]: I18nEnum.HailDamage,
  [RoofCondition.MissingShingles]: I18nEnum.MissingShingles,
  [RoofCondition.RoofLeaks]: I18nEnum.RoofLeaks,
  [RoofCondition.Sagging]: I18nEnum.Sagging,
  [RoofCondition.StructuralDamage]: I18nEnum.StructuralDamage,
  [RoofCondition.WindDamage]: I18nEnum.WindDamage,
};

export const roofTypeLabelMap: Record<RoofType, I18nEnum> = {
  [RoofType.AsphaltShingle]: I18nEnum.AsphaltShingleRemoval,
  [RoofType.Metal]: I18nEnum.MetalRoofRemoval,
  [RoofType.Synthetic]: I18nEnum.SyntheticRoofRemoval,
  [RoofType.Tile]: I18nEnum.TileRoofRemoval,
  [RoofType.Wood]: I18nEnum.WoodShakeRemoval,
};

export const squareFeetLabelsMap = {
  [SquareFeetEnum.InstantQuote]: I18nEnum.InstantQuote,
  [SquareFeetEnum.Custom]: I18nEnum.Custom,
  [SquareFeetEnum.GAFMeasurements]: I18nEnum.GAFQuickMeasure,
  [SquareFeetEnum.EagleViewBidPerfect]: I18nEnum.EagleViewBidPerfectReport,
  [SquareFeetEnum.EagleViewPremiumCommercial]: I18nEnum.EagleViewPremiumCommercialReport,
  [SquareFeetEnum.EagleViewPremiumResidential]: I18nEnum.EagleViewPremiumResidentialReport,
};

export const DEFAULT_DISCOUNT_VALUES: Partial<DiscountModel> = {
  unit: DiscountUnitOfMeasurement.Percentage,
  value: '0',
};

export const additionalCostVariantLabels = {
  [AdditionalCostVariant.Current]: I18nEnum.Current,
  [AdditionalCostVariant.New]: I18nEnum.New,
};

export const REP_QUOTES_BLOCKER_KEY = 'repQuotesBlocker';

export const ML_PREDICTION_KEY = `${name}ML_PREDICTION_KEY`;
