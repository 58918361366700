import { useState, useCallback } from 'react';
import {
  useFloating,
  useInteractions,
  useClick,
  useDismiss,
  FloatingPortal,
  autoUpdate,
  offset,
  shift,
} from '@floating-ui/react';
import styled from 'styled-components';

import PopupContent from './PopupContent';

const panelStyle = {
  zIndex: 1000,
  width: '420px',
  padding: '0 0 24px',
};

const NotificationsPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    middleware: [offset(10), shift({ mainAxis: false })],
  });

  const dismiss = useDismiss(context);
  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const closePopup = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <TriggerImage
        src="/images/notifications/bell-icon.svg"
        ref={refs.setReference}
        {...getReferenceProps()}
      />
      <FloatingPortal>
        {isOpen && (
          <Panel
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              ...panelStyle,
            }}
            {...getFloatingProps()}>
            <PopupContent closePopup={closePopup} />
          </Panel>
        )}
      </FloatingPortal>
    </>
  );
};

const Panel = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 6px;
  padding: 8px 0;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 15;
`;

const TriggerImage = styled.img`
  cursor: pointer;
`;

export default NotificationsPopup;
