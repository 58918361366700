import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors, ModalTypes } from 'modules/modal';
import ErrorBoundary from 'components/ErrorBoundary';

const _Modals = lazy(() => import('./_Modals'));
const SlideOutModals = lazy(() => import('./SlideOutModals'));
const Modals = () => {
  const { opened, type } = useSelector(selectors.selectModal);

  const [isSlideOutModalOpened, setIsSlideOutModalOpened] = useState(false);

  const isSlideOutModal = useMemo(
    () =>
      [
        ModalTypes.addressNotFount,
        ModalTypes.almostDone,
        ModalTypes.potentialClient,
        ModalTypes.thankYou,
        ModalTypes.thankYouContactUs,
        ModalTypes.invitationSent,
        ModalTypes.howInstantLoanWorks,
        ModalTypes.widgetProductDetails,
      ].includes(type as ModalTypes),
    [type],
  );

  const isRegularModalOpened = useMemo(() => opened && !isSlideOutModal, [opened, isSlideOutModal]);
  const _isSlideOutModalOpened = useMemo(
    () => opened && isSlideOutModal,
    [opened, isSlideOutModal],
  );

  useEffect(() => {
    if (_isSlideOutModalOpened) {
      setIsSlideOutModalOpened(true);
    }
  }, [_isSlideOutModalOpened]);

  return (
    <ErrorBoundary>
      <Suspense>
        {isRegularModalOpened && <_Modals />}
        {isSlideOutModalOpened && <SlideOutModals />}
      </Suspense>
    </ErrorBoundary>
  );
};

export default Modals;
