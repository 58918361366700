import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BlockerFunction, Blocker, UNSAFE_DataRouterStateContext } from 'react-router-dom';
import { RouterState } from '@remix-run/router';

import { I18nEnum } from 'types';
import { actions as modalActions, ModalTypes, selectors as modalSelectors } from 'modules/modal';
import { router } from 'modules/App';
import { useBroadcastChannelProvider } from './useBroadcastChannel';

export const IDLE_BLOCKER: Blocker = {
  state: 'unblocked',
  proceed: undefined,
  reset: undefined,
  location: undefined,
};

export const ROUTER_BLOCKER_BROADCAST_CHANNEL = 'ROUTER_BLOCKER_BROADCAST_CHANNEL';

export const useModalBlocker = (
  when = true,
  {
    primaryAction: primaryActionProp,
    secondaryAction: secondaryActionProp,
    shouldRetry = true,
    blockerKey,
    disabled: _disabled,
    primaryBtnText = I18nEnum.SaveChanges,
    isChangesValid = true,
  }: {
    primaryAction?: (proceed: () => void, reset?: () => void) => void;
    secondaryAction?: (proceed: () => void) => void;
    shouldRetry?: boolean;
    /** should be same per page */
    blockerKey: string;
    /** there should be only one active blocker per page */
    disabled?: boolean;
    primaryBtnText?: I18nEnum;
    isChangesValid?: boolean;
  },
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const state: RouterState | null = useContext(UNSAFE_DataRouterStateContext);

  const isModalOpened = useSelector(modalSelectors.selectOpened);
  const modalType = useSelector(modalSelectors.selectType);

  const channel = useBroadcastChannelProvider(ROUTER_BLOCKER_BROADCAST_CHANNEL);

  const blockerFunction = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (currentLocation.pathname === nextLocation.pathname) {
        return false;
      }

      return when;
    },
    [when],
  );

  const blocker: Blocker =
    state && state.blockers.has(blockerKey) && !disabled
      ? (state.blockers.get(blockerKey) as Blocker)
      : IDLE_BLOCKER;

  const createActions = useCallback(
    (proceedCallback: () => void, resetCallback?: () => void) => {
      const primaryAction = async () => {
        if (primaryActionProp) {
          await primaryActionProp(proceedCallback, resetCallback);
        }

        if (!shouldRetry) return;

        proceedCallback();
      };

      const secondaryAction = secondaryActionProp
        ? () => secondaryActionProp(proceedCallback)
        : () => proceedCallback();

      return {
        primaryAction: isChangesValid ? primaryAction : secondaryAction,
        secondaryAction,
      };
    },
    [primaryActionProp, secondaryActionProp, shouldRetry, isChangesValid],
  );

  const openWarningModal = useCallback(
    ({ primaryAction, secondaryAction }) => {
      dispatch(
        modalActions.openModal(ModalTypes.warningModal, {
          onClose: blocker.reset,
          primaryAction,
          args: [],
          subtitle: I18nEnum.YourChangesHaveNotBeen,
          subtitleCss: 'max-width: 100%;',
          primaryBtnText: isChangesValid ? primaryBtnText : I18nEnum.LeaveWithoutSaving,
          secondaryBtnText: I18nEnum.LeaveWithoutSaving,
          secondaryAction,
          description: intl.formatMessage({
            id: isChangesValid
              ? I18nEnum.YouAreAboutToLeaveThisPageWithoutSavingDoYouWantToSave
              : I18nEnum.YouAreAboutToLeaveThisPageWithoutSaving,
          }),
          hideSecondaryBtn: !isChangesValid,
        }),
      );
    },
    [dispatch, intl, blocker, isChangesValid, primaryBtnText],
  );

  const handleSignOutAction = useCallback(
    event => {
      if (!event.data.action || disabled) {
        return;
      }

      if (!when) {
        channel.postMessage({ proceed: true });
        return;
      }

      const actions = createActions(
        () => {
          setDisabled(true);

          dispatch(modalActions.closeModal());
          channel.postMessage({ proceed: true });
        },
        () => {
          if (blocker.state !== 'blocked') {
            return;
          }
          blocker.reset();
        },
      );

      openWarningModal(actions);
    },
    [when, disabled, openWarningModal, dispatch, createActions, blocker],
  );

  useEffect(() => {
    if (disabled) return;

    router.getBlocker(blockerKey, blockerFunction);

    return () => {
      router.deleteBlocker(blockerKey);
    };
  }, [blockerKey, blockerFunction, disabled, router]);

  useEffect(() => {
    if (blocker.state !== 'blocked' || disabled) {
      return;
    }

    const actions = createActions(blocker.proceed, blocker.reset);

    if (isModalOpened) {
      dispatch(modalActions.setModalParams(actions));
      return;
    }

    openWarningModal(actions);
  }, [dispatch, blocker, isModalOpened, createActions]);

  useEffect(
    () => () => {
      if (!(when && isModalOpened && modalType === ModalTypes.warningModal)) return;

      dispatch(modalActions.closeModal());
    },
    [dispatch, when, isModalOpened, modalType],
  );

  useEffect(() => {
    channel.onmessage = handleSignOutAction;

    return () => {
      channel.onmessage = () => {};
    };
  }, [handleSignOutAction]);

  useEffect(() => {
    if (!disabled) {
      channel.postMessage({ alive: true });
    }
  }, [disabled]);

  useEffect(() => {
    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    setDisabled(_disabled ?? false);
  }, [_disabled]);
};
