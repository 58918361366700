import { useDispatch } from 'react-redux';
import {
  useQuery,
  UseQueryResult,
  QueryFunction,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  QueryKey,
} from '@tanstack/react-query';

import * as spinnerActions from 'modules/spinner/actions';
import { QueryError } from 'types';

export const TEN_MINUTES = 1000 * 60 * 10;

interface QueryCustomOptions {
  hideSpinner?: boolean;
}

export const useAppQuery = <
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<{ data: TQueryFnData }, TQueryKey>,
  options: Omit<
    UseQueryOptions<TQueryFnData, QueryError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  > &
    QueryCustomOptions,
): UseQueryResult<TData, QueryError> => {
  const dispatch = useDispatch();
  const { hideSpinner, ...restOptions } = options;

  const queryWithSpinner = async context => {
    !hideSpinner && dispatch(spinnerActions.show());

    try {
      const { data }: { data: TQueryFnData } = await queryFn(context);
      return data;
    } catch (error) {
      throw error;
    } finally {
      !hideSpinner && dispatch(spinnerActions.hide());
    }
  };

  return useQuery<TQueryFnData, QueryError, TData, TQueryKey>(queryKey, queryWithSpinner, {
    staleTime: TEN_MINUTES,
    refetchOnWindowFocus: false,
    ...restOptions,
  });
};

export const useAppMutation = <TVariables = void, TData = unknown, TContext = unknown>(
  options: UseMutationOptions<TData, QueryError, TVariables, TContext>,
): UseMutationResult<TData, QueryError, TVariables, TContext> => {
  const dispatch = useDispatch();

  const mutation = useMutation(options);
  return {
    ...mutation,
    mutate: async (variables, _options) => {
      dispatch(spinnerActions.show());

      try {
        await mutation.mutateAsync(variables, _options);
      } catch (error) {
        throw error;
      } finally {
        dispatch(spinnerActions.hide());
      }
    },
  };
};
