import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { I18nEnum } from 'types';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { MessageTypes, actions as messageActions } from 'modules/message';
import { actions as modalActions } from 'modules/modal';
import { services, constants, AddContractorBody, UpdateContractorBody } from 'modules/admin';
import { AllSubscriptionUpdates, SubscriptionRequestType } from 'modules/subscription/types';
import { selectors as userSelectors } from 'modules/auth';
import { queryClient } from 'modules/reactQuery';
import { AdminDashboardContext, DashboardLayoutContext } from './components/AdminDashboardContexts';

const invalidateAdminDashboardQueries = () => {
  constants.adminDashboardKeys.map(key => queryClient.invalidateQueries({ queryKey: [key] }));
};

export const invalidateRecurlyAccounts = () => {
  queryClient.invalidateQueries({ queryKey: [constants.RECURLY_ACCOUNTS_KEY] });
};

const invalidateContractorDependentQueries = (contractorId: number) => {
  constants.contractorDependentKeys.map(key =>
    queryClient.invalidateQueries({ queryKey: [key, contractorId] }),
  );
};

export const useAddContractor = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<AddContractorBody>({
    mutationFn: variables => services.addContractorAPI(user.id, variables),
    onSuccess: () => {
      invalidateAdminDashboardQueries();
      invalidateRecurlyAccounts();
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.NewRecordHasBeenAdded,
          }),
        ),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: I18nEnum.UnfortunatelyRecordHasNotBeenCreated,
          }),
        ),
      );
    },
  });
};

export const useUpdateContractor = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);
  const { openedTabs, setOpenedTabs } = useContext(DashboardLayoutContext);

  return useAppMutation<{ contractorId: number; body: UpdateContractorBody }>({
    mutationFn: ({ contractorId, body }) =>
      services.updateContractorAPI(user.id, contractorId, body),
    onSuccess: (_, { contractorId, body }) => {
      invalidateAdminDashboardQueries();
      invalidateRecurlyAccounts();
      invalidateContractorDependentQueries(contractorId);
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.TheChangesHaveBeenSaved,
          }),
        ),
      );

      const tabInd = openedTabs.findIndex(tab => tab.id === contractorId);
      const newTabName = `${body.firstName} ${body.lastName}`;
      if (tabInd !== -1 && openedTabs[tabInd].name !== newTabName) {
        openedTabs.splice(tabInd, 1, {
          id: contractorId,
          name: newTabName,
        });
        setOpenedTabs(openedTabs);
      }
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved,
          }),
        ),
      );
    },
  });
};

export const useDeleteContractor = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);
  const { setQuery, setSearchValue } = useContext(AdminDashboardContext);
  const { closeTab } = useContext(DashboardLayoutContext);

  return useAppMutation<{ contractorId: number }>({
    mutationFn: ({ contractorId }) => services.deleteContractorAPI(user.id, contractorId),
    onSuccess: (_, { contractorId }) => {
      setSearchValue('');
      setQuery(constants.initialQuery);
      invalidateAdminDashboardQueries();
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.RecordHasBeenDeleted,
          }),
        ),
      );
      closeTab(contractorId);
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: I18nEnum.UnfortunatelyTheRecordHasNotBeenDeleted,
          }),
        ),
      );
    },
  });
};

export const useUpdateSubscription = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{
    contractorId: number;
    subscriptionId: number;
    data: AllSubscriptionUpdates;
  }>({
    mutationFn: async ({ contractorId, subscriptionId, data }) => {
      await services.updateSubscriptionAPI({
        userId: user.id,
        contractorId,
        subscriptionId,
        data: data.subscription,
      });
      await services.updateSubscriptionMarketsAPI({
        userId: user.id,
        data: data.markets,
        contractorId,
      });
    },
    onSuccess: (_, { contractorId }) => {
      invalidateAdminDashboardQueries();
      invalidateContractorDependentQueries(contractorId);
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.SubscriptionHasBeenUpdated,
          }),
        ),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: I18nEnum.UnfortunatelyTheSubscriptionHasNotBeenUpdated,
          }),
        ),
      );
    },
  });
};

export const useToggleSubscription = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ contractorId: number; subscriptionPaused: boolean }>({
    mutationFn: ({ contractorId }) => services.toggleSubscriptionAPI(user.id, contractorId),
    onSuccess: (_, { contractorId, subscriptionPaused }) => {
      invalidateAdminDashboardQueries();
      invalidateContractorDependentQueries(contractorId);
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: subscriptionPaused
              ? I18nEnum.SubscriptionHasBeenProceeded
              : I18nEnum.SubscriptionHasBeenPaused,
          }),
        ),
      );
    },
    onError: (_, { subscriptionPaused }) => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: subscriptionPaused
              ? I18nEnum.UnfortunatelySubscriptionHasNotBeenProceeded
              : I18nEnum.UnfortunatelySubscriptionHasNotBeenPaused,
          }),
        ),
      );
    },
  });
};

export const useResetSubscriptionStatusChangingRequest = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{
    contractorId: number;
    subscriptionId: number;
    body: { requestType: SubscriptionRequestType };
  }>({
    mutationFn: variables =>
      services.resetSubscriptionStatusChangingRequestApi({
        userId: user.id,
        ...variables,
      }),
    onSuccess: (_, { contractorId }) => {
      invalidateAdminDashboardQueries();
      invalidateContractorDependentQueries(contractorId);
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.RequestHasBeenResetedSuccessfully,
          }),
        ),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: I18nEnum.UnfortunatelyTheRequestHasNotBeenReseted,
          }),
        ),
      );
    },
  });
};

export const useSendPassword = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ contractorId: number }>({
    mutationFn: ({ contractorId }) => services.sendPasswordAPI(user.id, contractorId),
    onSuccess: () => {
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.PasswordHasBeenResent,
          }),
        ),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: I18nEnum.UnfortunatelyThePasswordHasNotBeenResent,
          }),
        ),
      );
    },
  });
};
