import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import modal from 'modules/modal/reducer';
import message from 'modules/message/reducer';
import spinner from 'modules/spinner/reducer';
import user from 'modules/auth/reducer';
import { QuickQuoteReducer } from 'modules/quickQuote/reducer';
import { MapboxReducer } from 'modules/mapbox/reducer';
import search from 'modules/search/reducer';
import company from 'modules/company/reducer';
import widgetSettings from 'modules/widget/reducer';
import { productReducer, widgetProductReducer } from 'modules/product/reducer';
import market from 'modules/markets/reducer';
import snippets from 'modules/snippet/reducer';
import financing from 'modules/financing/reducer';
import quickQuoteConfig from 'modules/quickQuoteConfig/reducer';
import repQuotes from 'modules/repQuotes/reducer';
import googleMaps from 'modules/googleMaps/reducer';
import subscription from 'modules/subscription/reducer';
import notifications from 'modules/notifications/reducer';

// Middlewares
import { configureQuickQuoteConfig } from 'modules/quickQuote/middlewares';

export const rootReducer = combineReducers({
  modal,
  message,
  search,
  spinner,
  user,
  quickQuote: QuickQuoteReducer,
  mapbox: MapboxReducer,
  company,
  widgetSettings,
  product: productReducer,
  widgetProduct: widgetProductReducer,
  market,
  snippets,
  financing,
  quickQuoteConfig,
  repQuotes,
  googleMaps,
  subscription,
  notifications,
});

const middleware = [thunk, configureQuickQuoteConfig];

let enhancer = applyMiddleware(...middleware);
if (
  !(
    window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT === 'production' ||
    window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT === 'stage'
  )
) {
  enhancer = composeWithDevTools(enhancer);
}

export default createStore(rootReducer, enhancer);
